import React, { useState, useEffect, useContext, useRef } from "react";
import {
  Grid,
  Button,
  Typography,
  makeStyles,
  CircularProgress,
} from "@material-ui/core";
import { CloudUpload as CloudUploadIcon } from "@material-ui/icons";
import { LuDownload } from "react-icons/lu";
import { COLLECTIONS, firestore, storage } from "../../firebase/config";
import {
  collection,
  query,
  orderBy,
  where,
  limit,
  getDocs,
  updateDoc,
} from "firebase/firestore";
import {
  uploadBytesResumable,
  ref,
  getDownloadURL,
  getMetadata,
} from "firebase/storage";
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import moment from "moment";
import PrimaryBtn, { IconButton, SecondaryBtn } from "../Global/Buttons";
import { UserContext } from "../../context/UserContext";
import { MdOutlineFileUpload } from "react-icons/md";
import { ReactComponent as FileIcon } from "../../Assets/file.svg";
import useResumeUpload from "../../utils/useResumeUpload";
import { useTranslation } from "react-i18next";
const useStyles = makeStyles((theme) => ({
  wrapper: {
    border: "2px dashed #D1D1D1",
    padding: "32px",
    height: "580px",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "8px",
  },

  whiteButton: {
    backgroundColor: "transparent",
    textTransform: "unset",
    color: "#003D50",
    padding: "12px 24px",
    disableAnimation: true,
    fontSize: "16px",
    fontWeight: "700",
    width: "250px",
    marginTop: "16px",
    border: "1px solid #003D50",
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px", // Font size for mobile (screens smaller than or equal to small breakpoint)
      padding: "8px 16px",
    },
  },
}));

const ResumeSection = ({
  fileName,
  resumeFile,
  fileType,
  handleDownload,
  handleFileChange,
  fileRef,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  // const docs = [{ uri: resumeUrl }];
  const type = fileType?.split("/")[1] === "pdf" ? "pdf" : "doc";
  return (
    <div className="flex justify-between item">
      <div className="flex gap-1">
        <FileIcon />
        <div className="flex gap-1">
          <h2 className="text-sm font-bold">
            {fileName ? `${fileName}` : t("no_resume_uploaded")}
          </h2>
          {!resumeFile && (
            <div className="cursor-pointer" onClick={handleDownload}>
              <LuDownload />
            </div>
          )}
        </div>
      </div>
      <input
        type="file"
        ref={fileRef}
        accept=".pdf,.doc,.docx"
        onChange={handleFileChange}
        style={{ display: "none" }}
      />
      <IconButton
        text={fileName ? t("upload_new") : t("upload_resume")}
        icon={<MdOutlineFileUpload />}
        onClick={() => fileRef.current.click()}
      />
    </div>
  );
};

export default ResumeSection;
