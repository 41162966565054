const industries = [
  "Accounting and Finance",
  "Administration and Office Support",
  "Advertising, Arts and Media",
  "Agriculture, Fishing and Forestry",
  "Artificial Intelligence (AI)",
  "Banking and Financial Services",
  "Call Centre and Customer Service",
  "Civil Engineering",
  "Cloud Computing",
  "Community Services and Development",
  "Construction",
  "Consulting and Strategy",
  "Cybersecurity",
  "Data Science and Analytics",
  "DevOps",
  "Education and Training",
  "Electrical Engineering",
  "Engineering",
  "Environmental Engineering",
  "Government and Defence",
  "Healthcare and Medical",
  "Hospitality and Tourism",
  "Human Resources and Recruitment",
  "Information and Communication Technology (ICT)",
  "Information Technology (IT) Operations",
  "Insurance and Superannuation",
  "Legal",
  "Machine Learning (ML)",
  "Manufacturing, Transport and Logistics",
  "Marketing and Communications",
  "Materials Engineering",
  "Mechanical Engineering",
  "Mining, Resources and Energy",
  "Mobile Development",
  "Network and Systems Administration",
  "Nuclear Engineering",
  "Petroleum Engineering",
  "Product Management",
  "Quality Assurance (QA) and Testing",
  "Real Estate and Property",
  "Retail and Consumer Products",
  "Sales",
  "Science and Technology",
  "Software Development",
  "Sports and Recreation",
  "Structural Engineering",
  "Trades and Services",
  "User Experience (UX) Design",
  "Web Development",
  "Other",
];

export default industries;
