import React, { useContext, useEffect, useRef, useState } from "react";
import { FaArrowRight } from "react-icons/fa6";
import { IconButton, SecondaryIconButton } from "../../Global/Buttons";
import { FaArrowLeft } from "react-icons/fa6";
import { Grid } from "@material-ui/core";
import TextInput from "../../Global/TextInput";
import RichText from "../../Global/RichText";
import ExperienceTab from "../ExperienceTab";
import EducationTab from "../EducationTab";
import { useTranslation } from "react-i18next";

export default function Review({
  handleSave,
  handleSubmit,
  register,
  errors,
  watch,
  setValue,
  employmentRefs,
  educationRefs,
  trigger,
  loading,
  handleBackClick,
}) {
  const { t } = useTranslation();
  useEffect(() => {
    const scrollToError = () => {
      if (
        errors.title ||
        errors.firstName ||
        errors.lastName ||
        errors.aboutMe
      ) {
        return;
      }
      if (errors.employment) {
        const firstErrorIndex = Object.keys(errors.employment)[0];
        employmentRefs?.current[firstErrorIndex]?.scrollIntoView({
          behavior: "smooth",
        });
      } else if (errors.education) {
        const firstErrorIndex = Object.keys(errors.education)[0];
        educationRefs?.current[firstErrorIndex]?.scrollIntoView({
          behavior: "smooth",
        });
      }
    };
    scrollToError();
  }, [errors.employment, errors.education]);

  return (
    <div>
      <div className="text-3xl my-12 font-semibold w-3/5 mx-auto text-center">
        {t("resume_info_pulled")}{" "}
        <span className="text-primary-400">
          {t("review_changes_before_proceeding")}
        </span>
      </div>
      <div className="w-full flex justify-center gap-4 items-center">
        <SecondaryIconButton
          text={t("go_back_resume")}
          icon={<FaArrowLeft className="text-primary-400" />}
          onClick={handleBackClick}
        />
        <IconButton
          icon={<FaArrowRight className="text-white" />}
          text={t("next_step_get_started")}
          iconFirst={false}
          loading={loading}
          onClick={handleSubmit(handleSave)}
        />
      </div>
      <div className="w-4/5 mx-auto flex flex-col items-center gap-12 py-12">
        <form className="flex justify-center">
          <Grid container item xs={12} lg={9} spacing={4}>
            <Grid item xs={12}>
              <TextInput
                label={"Title"}
                placeholder={t("full_stack_developer")}
                name="title"
                regProp={{ ...register("title") }}
                error={errors.title?.message}
                required={true}
              />
            </Grid>
            <Grid item lg={6} xs={12}>
              <TextInput
                label={"First Name"}
                placeholder={"John"}
                name="firstName"
                regProp={{ ...register("firstName") }}
                error={errors.firstName?.message}
                required={true}
              />
            </Grid>
            <Grid item lg={6} xs={12}>
              <TextInput
                label={"Last Name"}
                placeholder={"Doe"}
                name="lastName"
                regProp={{ ...register("lastName") }}
                error={errors.lastName?.message}
                required={true}
              />
            </Grid>
            <Grid item xs={12}>
              <span className="text-sm font-medium text-slate-700 mb-2 flex">
                {t("about_me")}
              </span>
              <RichText
                placeholder={t("about_me_content")}
                value={watch("aboutMe")}
                handleChange={(e) => setValue("aboutMe", e)}
              />

              <p className="text-sm text-red-500">{errors.aboutMe?.message}</p>
            </Grid>

            <Grid item xs={12} style={{ marginTop: "32px" }}>
              {/* {watch("employment")?.length > 0 && ( */}
              <div className="border border-borderGrey-400 rounded-md p-2">
                <h1 className="text-lg font-bold">{t("experience")}</h1>
                <ExperienceTab
                  userInfo={watch()}
                  onboarding={true}
                  errors={errors}
                  onSave={(e) => {
                    setValue("employment", e.employment);
                    trigger("employment");
                  }}
                  employmentRefs={employmentRefs}
                />
              </div>
              {/* )} */}
            </Grid>
            <Grid item xs={12}>
              {/* {watch("education")?.length > 0 && ( */}
              <div className="border border-borderGrey-400 rounded-md p-2">
                <h1 className="text-lg font-bold">{t("education")}</h1>
                <EducationTab
                  userInfo={watch()}
                  onboarding={true}
                  errors={errors}
                  onSave={(e) => {
                    setValue("education", e.education);
                    trigger("education");
                  }}
                  educationRefs={educationRefs}
                />
              </div>
              {/* )} */}
            </Grid>
          </Grid>
        </form>
      </div>
    </div>
  );
}
