import * as yup from "yup";

export const profileSchema = yup.object().shape({
  title: yup.string().required("Title is required").max(36),
  firstName: yup.string().required("First name is required").max(16),
  lastName: yup.string().required("Last name is required").max(16),
  dateOfBirth: yup
    .date()
    .required("Date of birth is required")
    .max(new Date(), "Date of birth cannot be in the future"),
  // country: yup
  //   .string()
  //   .required("Country is required")
  //   .max(50, "Country name cannot exceed 50 characters"),
  // city: yup
  //   .string()
  //   .required("City is required")
  //   .max(50, "City name cannot exceed 50 characters"),
  timezone: yup.string().required("Timezone is required"),
  location: yup.string().required("Location is required"),
  // .matches(/^[a-zA-Z/_-]+$/, "Invalid timezone format")
  currency: yup
    .string()
    .required("Currency is required")
    .max(3, "Currency code should be 3 characters long"),
  minSalaryExpectancy: yup
    .number()
    .required("Minimum salary expectancy is required")
    .positive("Minimum salary expectancy must be a positive number")
    .typeError("Minimum salary expectancy is required"),
  aboutMe: yup
    .string()
    .max(500, "About Me cannot exceed 500 characters")
    .required("About section is required"),
  linkedInUrl: yup.string().url("Invalid LinkedIn URL").notRequired(),
  portfolioUrl: yup.string().url("Invalid portfolio URL").notRequired(),
});

export const experienceSchema = yup.object().shape({
  jobTitle: yup.string().required("Job title is required"),
  company: yup.string().required("Company is required"),
  // industry: yup.string(),
  jobType: yup.string().nullable(),
  location: yup.string().nullable(),

  // country: yup.string().required("Country is required"),
  // city: yup.string().required("City is required"),
  // startDate: yup
  //   .date()
  //   .required("Start date is required")
  //   .typeError("Start date must be a valid date"),

  startDate: yup
    .number()
    .default(() => Date.now())
    .typeError("Start date must be a valid date")
    .min(1, "Start date must be a valid date"),

  isCurrentlyWorking: yup.boolean().default(false),
  endDate: yup
    // .date()
    .number()
    .default(() => Date.now())
    .when("isCurrentlyWorking", {
      is: false,
      then: () =>
        // yup
        //   .date()
        //   .required("End date is required")
        //   .typeError("End date must be a valid date"),
        yup
          // .date()
          .number()
          .default(() => Date.now())
          .min(1, "End date must be a valid date")
          .required("End date is required")
          .typeError("End date must be a valid date")
          .test(
            "is-greater",
            "End date must be greater than start date",
            function (value) {
              const { startDate } = this.parent;
              return value > startDate;
            }
          ),

      otherwise: (schema) => schema.nullable().notRequired(),
    }),
  duties: yup.string(),
  chipSkills: yup.array().of(yup.string()),
});

export const educationSchema = yup.object().shape({
  educationLevel: yup.string().nullable(),
  degree: yup.string().required("Degree is required"),
  institute: yup.string().required("Institute is required"),
  major: yup.string().nullable(),
  grade: yup.string().nullable(),
  // startDate: yup
  //   .date()
  //   // .required("Start date is required")
  //   .nullable()
  //   .default(() => new Date(), "Default to current date"),
  startDate: yup
    .number()
    .default(() => Date.now())
    .typeError("Start date must be a valid date")
    .min(1, "Start date must be a valid date"),

  // endDate: yup
  //   .date()
  //   .required("End date is required")
  //   .nullable()
  //   .default(() => new Date(), "Default to current date"),

  isCurrentlyWorking: yup.boolean().default(false),
  endDate: yup
    // .date()
    .number()
    .default(() => Date.now())
    .when("isCurrentlyWorking", {
      is: false,
      then: () =>
        yup
          // .date()
          .number()
          .default(() => Date.now())
          .min(1, "End date must be a valid date")
          .required("End date is required")
          .typeError("End date must be a valid date")
          .test(
            "is-greater",
            "End date must be greater than start date",
            function (value) {
              const { startDate } = this.parent;
              return value > startDate;
            }
          ),
      otherwise: (schema) => schema.nullable().notRequired(),
    }),

  duties: yup.string().nullable(),
});

export const companySchema = yup.object().shape({
  companyName: yup.string().required("Company name is required"),
  tagline: yup.string().required("Tagline is required"),
  location: yup.string().required("Location is required"),
  website: yup.string().test("is-url", "Invalid URL", (value) => {
    const urlPattern = /^(https?:\/\/)?([\w\d-]+\.)+[\w\d]{2,}(\/.*)?$/;
    return value ? urlPattern.test(value) : false;
  }),
  industry: yup.string().required("Industry is required"),
  size: yup.string(),

  // timezone: yup.string().required("Timezone is required"),
  // currency: yup.string().required("Currency is required"),

  // country: yup.string().required("Country is required"),
  // city: yup.string().required("City is required"),
  // yearCreated: yup
  //   .number()
  //   .required("Year created is required")
  //   .positive("Year must be positive")
  //   .integer("Year must be an integer"),
  // about: yup.string().required("About is required"),
});

export const jobSchema = yup.object().shape({
  jobTitle: yup.string().required("Job title is required"),
  // skills: yup.array().of(yup.string()).min(1, "At least one skill is required"),
  tags: yup.array().of(yup.string()).min(1, "At least one skill is required"),
  hiringRemote: yup.boolean().required().default(true),

  locations: yup.array().when("hiringRemote", {
    is: false,
    then: () =>
      yup.array().of(yup.string()).min(1, "At least one location is required"),
    otherwise: () => yup.array().of(yup.string()).min(0),
  }),
  language: yup.string().required("Language is required"),
  experience: yup.string().required("Experience is required"),
  maxSalary: yup
    .number()
    .typeError("Max salary must be a number")
    .required("Max salary is required")
    .positive("Max salary must be positive")
    .moreThan(
      yup.ref("minSalary"),
      "Max salary must be greater than min salary"
    ),
  minSalary: yup
    .number("")
    .typeError("Min salary must be a number")
    .required("Min salary is required")
    .positive("Min salary must be positive")
    .lessThan(yup.ref("maxSalary"), "Min salary must be less than max salary"),
  jobType: yup.string().required("Job type is required"),
  jobDescription: yup.string().required("Job description is required"),
  currency: yup.string().required("Currency is required"),
  // location: yup.string().required("Location is required"),
  jobNature: yup.string().required("Remote policy is required"),
  timezone: yup.string().required("Timezone is required"),

  // salaryFrequency: yup.string().required("Salary frequency is required"),
});

export const cultureSchema = yup.object().shape({
  description: yup.string().required("Description is required"),
});

export const testimonialSchema = yup.object().shape({
  testimonial: yup.object().shape({
    name: yup.string().required("Name is required"),
    title: yup.string().required("Title is required"),
    description: yup.string().required("Testimonial description is required"),
  }),
});

export const passwordSchema = yup.object().shape({
  oldPassword: yup.string().required("Old password is required"),
  newPassword: yup
    .string()
    .min(8, "New password must be at least 8 characters")
    .required("New password is required"),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref("newPassword"), null], "Passwords must match")
    .required("Confirm new password is required"),
});

export const languageSchema = yup.object().shape({
  languages: yup.array().of(
    yup.object().shape({
      name: yup.string().required("Language is a required field"),
      level: yup.string().required("Proficiency is required"),
    })
  ),
});

export const signUpSchema = yup.object().shape({
  email: yup
    .string()
    .email()
    .required("Email is required")
    .typeError("Invalid email entered"),
  password: yup
    .string()
    .min(8, "Password must be at least 8 characters")
    .required("Password is required"),
});
