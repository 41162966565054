import React, { useState, useContext } from "react";
import {
  Grid,
  makeStyles,
  Typography,
  Button,
  TextField,
} from "@material-ui/core";
import { PlusIcon } from "@heroicons/react/24/solid";
import EditItemIcon from "../../Assets/editgreen.svg";
import { MdDelete } from "react-icons/md";
import { LanguageContext } from "../../Languages/LanguageContext";
import "react-datepicker/dist/react-datepicker.css";
import { Timestamp } from "@firebase/firestore";
import { educationSchema } from "../../schemas/profile";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import SelectOption from "../Global/SelectOption";
import TextInput from "../Global/TextInput";
import DialogModal from "../Global/Dialog";
import { DeleteDialog } from "./ExperienceTab";
import { ReactComponent as ExpIcon } from "../../Assets/experienceIcon.svg";
import DateSelector from "./DateSelector";
import { FaEdit } from "react-icons/fa";
import { ReactComponent as EmptyIcon } from "../../Assets/empty_edu.svg";
import PrimaryBtn, {
  IconButton,
  SecondaryBtn,
  SecondaryIconButton,
} from "../Global/Buttons";
import { GoPlus } from "react-icons/go";
import SearchSkill from "./SearchSkills";
import { parse } from "date-fns";
import { IoIosWarning } from "react-icons/io";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    border: "1px solid #D1D1D1",
    borderRadius: "8px",
    padding: "32px",
    marginTop: "16px",
  },
  container: {
    padding: "32px",
  },
  label: {
    fontWeight: 600,
    fontSize: "14px",
    lineHeight: "19px",
    color: "#151515",
    letterSpacing: "-0.02em",
    marginLeft: "32px",
  },
  heading: {
    fontWeight: 700,
    fontSize: "28px",
    lineHeight: "120%",
    color: "#000",
    letterSpacing: "-0.02em",
  },
  calender: {
    background: "white",
    marginTop: "10px",
    border: "1px solid #ECECEC",
    borderRadius: "10px",
    width: "100%",
    padding: "8px 12px",
  },
  whiteButton: {
    backgroundColor: "transparent",
    textTransform: "unset",
    color: "#003D50",
    padding: "12px 24px",
    disableAnimation: true,
    fontSize: "16px",
    fontWeight: "700",
    width: "250px",
    marginTop: "16px",
    border: "1px solid #003D50",
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px", // Font size for mobile (screens smaller than or equal to small breakpoint)
      padding: "8px 16px",
    },
  },
  formControl: {
    background: "white",
    marginTop: "10px",
    border: "1px solid #ECECEC",
    padding: "8px 16px",
    width: "98%",
    "& .MuiSelect-root": {
      backgroundColor: "white ",
      borderBottom: "none",
    },
    "& .MuiInput-underline:before": {
      borderBottom: "none",
    },
    "& .MuiInput-underline:after": {
      borderBottom: "none",
    },
    "&:hover .MuiInput-underline:before": {
      borderBottom: "none",
    },
    "&:hover .MuiInput-underline:after": {
      borderBottom: "none",
    },
  },
  textField: {
    background: "white",
    marginTop: "10px",
    border: "1px solid #D1D1D1",
    padding: "8px 16px",
    width: "100%",
    "& .MuiInputBase-input": {
      padding: "8px 16px",
      color: theme.palette.text.primary,
      fontWeight: 400,
      borderBottom: "none",
      outline: "none",
      backgroundColor: "none",
    },
  },
  skillChip: {
    margin: theme.spacing(0.5),
    fontSize: "14px",
    padding: "4px 8px",
    borderRadius: "5px",
    transition: ".3s",
    fontWeight: 400,
    marginRight: "10px",
    border: `1px solid #003D50`,
    color: "#003D50",
  },

  experienceItem: {
    padding: "16px",
    border: "1px solid #D1D1D1",
    borderRadius: "8px",
    marginTop: "32px",
  },
  title: {
    fontWeight: 600,
    fontSize: "32px",
    lineHeight: "120%",
    color: "#003D50",
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px", // Font size for mobile (screens smaller than or equal to small breakpoint)
      lineHeight: "24px",
    },
  },
  companyName: {
    fontWeight: 700,
    fontSize: "20px",
    lineHeight: "120%",
    marginTop: "4px",
    color: "#000",
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px", // Font size for mobile (screens smaller than or equal to small breakpoint)
      lineHeight: "24px",
    },
  },
  duration: {
    fontWeight: 500,
    fontSize: "16px",
    lineHeight: "120%",
    marginTop: "12px",
    color: "#868686",
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px", // Font size for mobile (screens smaller than or equal to small breakpoint)
      lineHeight: "24px",
    },
  },
  description: {
    fontWeight: 500,
    fontSize: "16px",
    lineHeight: "120%",
    marginTop: "16px",
    color: "#000",
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px", // Font size for mobile (screens smaller than or equal to small breakpoint)
      lineHeight: "24px",
    },
  },
  border: {
    border: "1px solid red",
  },
}));

const EducationTab = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [deleteIndex, setDeleteIndex] = useState(-1);
  const [EducationItems, setEducationItems] = useState(() => {
    return props.userInfo.education ? props.userInfo.education : [];
  });
  const [educationOpen, setEducationOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isEducationEditing, setIsEducationEditing] = useState(false);
  const [educationEditIndex, setEducationEditIndex] = useState(null);
  const [EducationItem, setEducationItem] = useState({
    educationLevel: "",
    degree: "",
    institute: "",
    major: "",
    grade: "",
    startDate: new Date().getTime(),
    endDate: new Date().getTime(),
  });
  const [initialValues, setInitialValues] = useState({});
  const [selectedSkills, setSelectedSkills] = useState([]);
  const [initialSkills, setInitialSkills] = useState([]);

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(educationSchema),
    defaultValues: {
      educationLevel: "",
      degree: "",
      institute: "",
      major: "",
      grade: "",
      startDate: new Date().getTime(),
      endDate: new Date().getTime(),
      duties: "",
      chipSkills: [],
      isCurrentlyWorking: false,
    },
  });

  const handleSelect = (skills) => {
    setSelectedSkills(skills);
  };
  const handleRemove = (skillToRemove) => {
    setSelectedSkills(
      selectedSkills.filter((skill) => skill !== skillToRemove)
    );
  };

  const handleEducationClose = () => {
    setEducationOpen(false);
  };

  const handleEducationClickOpen = () => {
    setEducationOpen(true);
    setEducationItem({
      educationLevel: "",
      degree: "",
      institute: "",
      major: "",
      grade: "",
      startDate: new Date().getTime(),
      endDate: new Date().getTime(),
    });
  };
  const handleClose = () => {
    setSelectedSkills([]);

    setEducationOpen(false);
    setIsEducationEditing(false);
    reset();
  };
  const handleEducationSave = async (data) => {
    try {
      setLoading(true);

      const tempItems = [...EducationItems];
      data.chipSkills = [...selectedSkills];
      tempItems.push(data);

      if (props.onboarding) {
        const candidate = {
          education: [...tempItems],
        };
        const updatedUserInfo = { ...props.userInfo, ...candidate };
        props.onSave(updatedUserInfo);
        setEducationItems(tempItems);
        setEducationOpen(false);
        return;
      }

      const educationItemsWithStrings = tempItems.map((item) => {
        return {
          ...item,
          startDate: Timestamp.fromDate(new Date(item.startDate)),
          endDate: Timestamp.fromDate(new Date(item.endDate)),
        };
      });

      const candidate = {
        education: educationItemsWithStrings,
      };
      const updatedUserInfo = { ...props.userInfo, ...candidate };
      await props.onSave(updatedUserInfo);
      setEducationItems(tempItems);
      setEducationOpen(false);
    } catch (err) {
      console.log("🚀 ~ handleSave ~ err:", err);
    } finally {
      setLoading(false);
    }
  };

  const handleEducationChange = (name, value) => {
    if (name === "startDate") {
      setValue("startDate", value);
    } else if (name === "endDate") {
      setValue("endDate", value);
    } else {
      setValue([name], value);
    }
  };
  const handleEducationEdit = (index) => {
    const tempData = EducationItems[index];
    setInitialValues(tempData);
    setValue("educationLevel", tempData.educationLevel);
    setValue("degree", tempData.degree);
    setValue("institute", tempData.institute);
    setValue("major", tempData.major);
    setValue("grade", tempData.grade);
    setValue(
      "startDate",
      tempData.startDate ? new Date(tempData.startDate).getTime() : null
    );
    setValue(
      "endDate",
      tempData.endDate ? new Date(tempData.endDate).getTime() : null
    );
    setValue("duties", tempData.duties);
    setValue(
      "isCurrentlyWorking",
      tempData.isCurrentlyWorking ? tempData.isCurrentlyWorking : false
    );
    setSelectedSkills(tempData.chipSkills ?? []);
    setInitialSkills(tempData?.chipSkills ?? []);

    setEducationEditIndex(index);
    setIsEducationEditing(true);
    setEducationOpen(true);
  };

  const handleDeleteConfirm = () => {
    const temp = [...EducationItems];
    temp.splice(deleteIndex, 1);

    if (props.onboarding) {
      const candidate = {
        education: [...temp],
      };
      const updatedUserInfo = { ...props.userInfo, ...candidate };
      props.onSave(updatedUserInfo);
      setEducationItems(temp);
      setDeleteIndex(-1);
      return;
    }

    const educationItemsWithStrings = temp.map((item) => ({
      ...item,
      startDate: Timestamp.fromDate(new Date(item.startDate)),
      endDate: Timestamp.fromDate(new Date(item.endDate)),
    }));
    const candidate = {
      education: educationItemsWithStrings,
    };
    const updatedUserInfo = { ...props.userInfo, ...candidate };
    props.onSave(updatedUserInfo);
    setEducationItems(temp);
    setDeleteIndex(-1);
  };

  const handleEducationDelete = (indexToRemove) => {
    setDeleteIndex(indexToRemove);
  };

  const handleEducationEditSave = async (data) => {
    try {
      setLoading(true);

      const updatedItems = [...EducationItems];
      data.chipSkills = [...selectedSkills];

      updatedItems[educationEditIndex] = { ...data };

      if (props.onboarding) {
        const candidate = {
          education: [...updatedItems],
        };
        const updatedUserInfo = { ...props.userInfo, ...candidate };
        props.onSave(updatedUserInfo);
        setEducationItems(updatedItems);
        setIsEducationEditing(false);
        setEducationOpen(false);
        return;
      }

      const educationItemsWithStrings = updatedItems.map((item) => ({
        ...item,
        startDate: Timestamp.fromDate(new Date(item.startDate)),
        endDate: Timestamp.fromDate(new Date(item.endDate)),
      }));
      const candidate = {
        education: educationItemsWithStrings,
      };
      const updatedUserInfo = { ...props.userInfo, ...candidate };
      await props.onSave(updatedUserInfo);
      setEducationItems(updatedItems);
      setIsEducationEditing(false);
      setEducationOpen(false);
    } catch (err) {
      console.log("🚀 ~ handleSave ~ err:", err);
    } finally {
      setLoading(false);
    }
  };
  const isFormChanged = () => {
    const currentValues = watch();
    const result = Object.keys(currentValues).some((key) => {
      if (key === "startDate" || key === "endDate") {
        return (
          new Date(initialValues[key]).getTime() !==
          new Date(currentValues[key]).getTime()
        );
      } else {
        return initialValues[key] != currentValues[key];
      }
    });

    const resultSkills =
      selectedSkills.length !== initialSkills.length ||
      selectedSkills.some((skill, index) => skill !== initialSkills[index]);

    return resultSkills || result;
  };

  const hasErrorsAtIndex = (index) => {
    return (
      props?.errors?.education?.[index] &&
      Object.keys(props?.errors.education[index]).length > 0
    );
  };

  return (
    <>
      <Grid>
        {!props.onboarding && (
          <Grid
            container
            item
            alignItems="center"
            justifyContent="space-between"
          >
            <Grid item lg={6} xs={6}>
              <Typography className={classes.heading}>
                {t("education_history")}
              </Typography>
            </Grid>
            <Grid
              container
              item
              lg={6}
              xs={6}
              justifyContent="flex-end"
              alignItems="center"
            >
              <Grid item>
                <SecondaryIconButton
                  icon={<GoPlus className="text-primary-400" />}
                  text={t("add_education")}
                  onClick={handleEducationClickOpen}
                />
              </Grid>
            </Grid>
          </Grid>
        )}

        {EducationItems?.length > 0 ? (
          <div>
            {EducationItems.map((EducationItem, index) => (
              <Grid
                container
                justifyContent="space-between"
                className={`${hasErrorsAtIndex(index) ? classes.border : ""} ${
                  classes.experienceItem
                }`}
                key={index}
                ref={(el) => (props.educationRefs.current[index] = el)}
              >
                <Grid item xs={12}>
                  {props?.errors?.education?.[index] &&
                    Object.keys(props.errors.education[index]).map(
                      (field) =>
                        props.errors.education[index][field]?.message && (
                          <div
                            key={field}
                            className="rounded-md border border-red-400 flex items-center py-1 px-2 gap-1 mb-2 w-fit"
                          >
                            <IoIosWarning className="text-red-400" />
                            {props.errors.education[index][field].message}
                          </div>
                        )
                    )}
                </Grid>
                <Grid item xs={10}>
                  <EducationCard EducationItem={EducationItem} />
                </Grid>
                <Grid
                  container
                  item
                  xs={2}
                  justifyContent="flex-end"
                  alignItems="flex-start"
                >
                  <div className="flex items-center gap-2 text-md">
                    <FaEdit
                      fontSize={"20"}
                      color="#003D50"
                      onClick={() => handleEducationEdit(index)}
                      style={{
                        cursor: "pointer",
                        width: "30px",
                      }}
                    />

                    <MdDelete
                      color="red"
                      size={22}
                      style={{
                        cursor: "pointer",
                        width: "30px",
                      }}
                      onClick={() => handleEducationDelete(index)}
                    />
                  </div>
                </Grid>
              </Grid>
            ))}
          </div>
        ) : (
          <div className="h-96 flex justify-center items-center flex-col">
            <div>
              <EmptyIcon />
            </div>
            <div className="text-gray-400 text-2xl font-semibold my-4">
              {t("no_education")}
            </div>

            <IconButton
              icon={<GoPlus className="text-white" />}
              text={t("add_education")}
              onClick={handleEducationClickOpen}
            />
          </div>
        )}
      </Grid>
      <DeleteDialog
        open={deleteIndex !== -1}
        handleClose={() => setDeleteIndex(-1)}
        handleDeleteConfirm={handleDeleteConfirm}
      />

      <EducationDialog
        educationOpen={educationOpen}
        handleClose={handleClose}
        isEducationEditing={isEducationEditing}
        handleEducationEditSave={handleEducationEditSave}
        handleEducationSave={handleEducationSave}
        EducationItem={EducationItem}
        handleEducationChange={handleEducationChange}
        handleEducationClose={handleEducationClose}
        register={register}
        errors={errors}
        handleSubmit={handleSubmit}
        watch={watch}
        setValue={setValue}
        loading={loading}
        isFormChanged={isFormChanged}
        onboardingError={props.errors}
        selectedSkills={selectedSkills}
        handleRemove={handleRemove}
        handleSelect={handleSelect}
      />
    </>
  );
};

export function EducationCard({ EducationItem }) {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <div className="flex gap-8 items-start">
      <div>
        <ExpIcon />
      </div>
      <div>
        <Typography className={classes.title}>
          {/* {EducationItem.educationLevel} in  */}
          {EducationItem?.major
            ? `${EducationItem.degree} - ${EducationItem.major}`
            : EducationItem.degree}
        </Typography>
        <Typography className={classes.companyName}>
          {EducationItem.institute}
        </Typography>

        <Typography className={classes.description}>
          {new Date(EducationItem.startDate).toLocaleDateString("en-GB")} -{" "}
          {EducationItem.isCurrentlyWorking ? (
            <>{t("now")}</>
          ) : (
            new Date(EducationItem.endDate).toLocaleDateString("en-GB")
          )}
        </Typography>
      </div>
    </div>
  );
}

function EducationDialog({
  educationOpen,
  handleClose,
  isEducationEditing,
  handleEducationEditSave,
  handleEducationSave,
  handleEducationChange,
  handleEducationClose,
  register,
  errors,
  handleSubmit,
  watch,
  setValue,
  loading = { loading },
  isFormChanged,
  onboardingError,
  selectedSkills,
  handleSelect,
  handleRemove,
}) {
  const classes = useStyles();
  const { locale, messages } = useContext(LanguageContext);
  const { t } = useTranslation();

  const displayEmploymentErrors = (errors) => {
    if (!errors || !errors.education) return null;

    return errors.education.map((error, index) => (
      <div key={index}>
        {Object.keys(error).map((key) => (
          <div
            key={error}
            className="rounded-md border text-xs border-red-400 flex items-center py-1 px-2 gap-1 mb-2 w-fit"
          >
            <IoIosWarning className="text-red-400" />

            {error[key]?.message}
          </div>
        ))}
      </div>
    ));
  };

  return (
    <DialogModal
      handleClose={handleClose}
      open={educationOpen}
      title={t("add_education")}
    >
      {!!onboardingError && displayEmploymentErrors(onboardingError)}
      <form
        id="education-dialog"
        onSubmit={
          isEducationEditing
            ? handleSubmit(handleEducationEditSave)
            : handleSubmit(handleEducationSave)
        }
      >
        <Grid container spacing={2}>
          <Grid item lg={6} xs={12}>
            <TextInput
              label={t("institute")}
              placeholder={"MIT"}
              name="institute"
              regProp={{ ...register("institute") }}
              error={errors.institute?.message}
              required={true}
            />
          </Grid>
          {/* <Grid item lg={6} xs={12}>
            <SelectOption
              label={messages[locale].educationLevel}
              id={messages[locale].educationLevel}
              regProp={{ ...register("educationLevel") }}
              placeholder={"Select education level"}
              options={[
                "Bachelors",
                "Masters",
                "Doctorate",
                "Diploma",
                "Training",
              ]}
              error={errors.educationLevel?.message}
            />
          </Grid> */}
          <Grid item lg={6} xs={12}>
            <TextInput
              label={t("degree")}
              placeholder={t("degree")}
              name="degree"
              regProp={{ ...register("degree") }}
              error={errors.degree?.message}
              required={true}
            />
          </Grid>

          <Grid item lg={6} xs={12}>
            <TextInput
              label={t("major")}
              placeholder={"Data Science"}
              name="major"
              regProp={{ ...register("major") }}
              error={errors.major?.message}
              required={false}
            />
          </Grid>

          <Grid item lg={6} xs={12}>
            <TextInput
              label={t("grade")}
              placeholder={t("grade")}
              name="grade"
              regProp={{ ...register("grade") }}
              error={errors.grade?.message}
            />
          </Grid>

          <Grid item lg={6} xs={12}>
            <span className="text-sm font-medium text-slate-700 flex">
              {t("startDate")}
            </span>
            <DateSelector
              selectedDate={
                watch("startDate")
                  ? // ? parse(watch("startDate"), "dd/MM/yyyy", new Date())
                    new Date(watch("startDate"))
                  : null
              }
              placeholder={"Select Start Date"}
              onChange={(date) =>
                handleEducationChange("startDate", new Date(date).getTime())
              }
              maxDate={new Date()}
            />
            <p className="text-sm text-red-500">{errors?.startDate?.message}</p>
          </Grid>
          <Grid item lg={6} xs={12}>
            <span className="text-sm font-medium text-slate-700 flex">
              {t("endDate")}
              <p className="text-sm text-red-500 ml-1">*</p>
            </span>
            <DateSelector
              disabled={watch("isCurrentlyWorking")}
              selectedDate={
                watch("endDate")
                  ? // ? parse(watch("endDate"), "dd/MM/yyyy", new Date())
                    new Date(watch("endDate"))
                  : null
              }
              placeholder={"Select End Date"}
              onChange={(date) =>
                handleEducationChange("endDate", new Date(date).getTime())
              }
            />

            <p className="text-sm text-red-500">{errors?.endDate?.message}</p>
          </Grid>
          <Grid item xs={12}>
            <label className="cursor-pointer flex items-center">
              <input
                type="checkbox"
                checked={watch("isCurrentlyWorking")}
                className="checked:accent-secondary-400"
                onChange={() => {
                  setValue("isCurrentlyWorking", !watch("isCurrentlyWorking"));
                }}
              />
              <p className="ml-2">{t("currently_studying")}</p>
            </label>
          </Grid>

          <Grid item xs={12}>
            <SearchSkill
              selectedSkills={selectedSkills?.map((s) => {
                return { value: s, label: s };
              })}
              handleSelect={handleSelect}
              handleRemove={handleRemove}
            />
          </Grid>
          <Grid item xs={12}>
            <span className="text-sm font-medium text-slate-700 mb-2 flex">
              {/* {messages[locale].dutiesandResponsibilities} */}
              {t("description")}
            </span>
            <TextField
              id="duties"
              placeholder={t("react_js_development")}
              type="text"
              name="duties"
              {...register("duties")}
              style={{ width: "100%" }}
              multiline
              minRows={7}
              className={classes.textField}
              InputProps={{
                borderRadius: "25px",
                disableOutline: true,
                disableUnderline: true,
              }}
            />
          </Grid>
        </Grid>

        <div className="flex items-center gap-4 my-4">
          <SecondaryBtn text={t("cancel")} onClick={handleEducationClose} />
          <PrimaryBtn
            text={isEducationEditing ? t("update") : t("save")}
            loading={loading}
            type="submit"
            disabled={isEducationEditing ? !isFormChanged() : false}
          />
        </div>
      </form>
    </DialogModal>
  );
}
export default EducationTab;
