import React, { useContext, useEffect, useState } from "react";
import { Navigate, useLocation, useNavigate } from "react-router";
import { UserContext } from "./context/UserContext";
import VerifyEmail from "./components/Global/VerifyEmail";
import EarlyBirdHeader from "./pages/EarlyBirdHeader";
import useCompanyStore from "./store/company";

export const ProtectedRoute = ({
  companyRoute = false,
  userRoute = false,
  isCompany,
  children,
}) => {
  const { user, isLogged } = useContext(UserContext);
  const location = useLocation();
  const navigate = useNavigate();

  // useEffect(() => {
  //   // Ensure jobId is present in the URL query parameters
  //   const searchParams = new URLSearchParams(location.search);
  //   const jobId = searchParams.get("jobId");
  //   if (jobId) {
  //     searchParams.set("jobId", jobId);
  //     navigate(`${location.pathname}?${searchParams.toString()}`, {
  //       replace: true,
  //     });
  //   }
  // }, [location, navigate]);

  if (!isLogged)
    return (
      <Navigate
        to={{
          pathname: `/login`,
        }}
        state={{ from: location.pathname }}
      />
    );

  if (companyRoute && !isCompany) {
    return (
      <Navigate
        to={{
          pathname: `/404`,
        }}
      />
    );
  }
  if (userRoute && isCompany) {
    return (
      <Navigate
        to={{
          pathname: `/404`,
        }}
      />
    );
  }
  return (
    <>
      <EarlyBirdHeader />

      {user?.emailVerified ? (
        children
      ) : (
        <>
          <VerifyEmail />
          {children}
        </>
      )}
    </>
  );
};
