import { getAuth } from "firebase/auth";
import {
  addDoc,
  collection,
  getDocs,
  getFirestore,
  onSnapshot,
  query,
  where,
} from "firebase/firestore";
import { getFunctions, httpsCallable } from "firebase/functions";
import { firestore } from "../firebase/config";
import { stripePriceIds } from "../utils/constants";

export const getCheckoutUrl = async (app, priceId, applicationId) => {
  const auth = getAuth(app);
  const userId = auth.currentUser?.uid;
  if (!userId) throw new Error("User is not authenticated");

  // const earlyBirdPriceRef = collection(firestore, "prices");
  // const snapshot = await getDocs(
  //   query(earlyBirdPriceRef, where("nickname", "==", "Early Bird"))
  // );
  // if (snapshot.empty) {
  //   throw new Error("Early bird price not found");
  // }
  // const earlyBirdPriceId = snapshot.docs[0].id;
  // console.log(
  //   "🚀 ~ getEarlyBirdCheckoutUrl ~ earlyBirdPriceId:",
  //   earlyBirdPriceId
  // );

  const checkoutSessionRef = collection(
    firestore,
    "customers",
    userId,
    "checkout_sessions"
  );
  let body = {
    success_url: `${window.location.origin}/success?session_id={CHECKOUT_SESSION_ID}`,
    cancel_url: window.location.origin,
    trial_period_days: 30,
  };

  if (stripePriceIds[2] === priceId) {
    body = {
      ...body,
      mode: "payment",
    };
  }

  if (applicationId) {
    body = {
      ...body,
      mode: "payment",
      metadata: {
        applicationId,
      },
      line_items: [
        {
          price: priceId,
          quantity: JSON.parse(applicationId).length,
        },
      ],
    };
  } else {
    body = {
      ...body,
      price: priceId,
    };
  }
  const docRef = await addDoc(checkoutSessionRef, body);

  return new Promise((resolve, reject) => {
    const unsubscribe = onSnapshot(docRef, (snap) => {
      const data = snap.data();
      if (!data) {
        unsubscribe();
        return reject(new Error("Snapshot data is undefined"));
      }

      const { error, url } = data;
      if (error) {
        unsubscribe();
        return reject(new Error(`An error occurred: ${error.message}`));
      }
      if (url) {
        console.log("Stripe Checkout URL:", url);
        unsubscribe();
        return resolve(url);
      }
    });
  });
};

export const getPortalUrl = async (app) => {
  const auth = getAuth(app);
  const user = auth.currentUser;

  if (!user) {
    throw new Error("User is not authenticated");
  }

  try {
    const functions = getFunctions(app, "us-central1");
    const functionRef = httpsCallable(
      functions,
      "ext-firestore-stripe-payments-createPortalLink"
    );
    const { data } = await functionRef({
      customerId: user.uid,
      returnUrl: window.location.origin,
    });

    if (!data?.url) {
      throw new Error("No URL returned from function");
    }

    console.log("Reroute to Stripe portal:", data.url);
    return data.url;
  } catch (error) {
    console.error("Error getting portal URL:", error);
    throw error; // Re-throw the error to propagate it to the caller
  }
};
