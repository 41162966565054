import React from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import "./styles/phone.css";

export default function Phone({ setPhone, phone }) {
  return (
    <PhoneInput
      country={"es"}
      value={phone}
      onChange={setPhone}
      placeholder={"Phone number"}
      enableSearch={true}
      disableDropdown={false}
      containerClass="phone-input-container"
      dropdownClass="phone-input-dropdown"
      inputClass="phone-input"
    />
  );
}
