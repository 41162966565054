import React, { useContext } from "react";
import { Typography, Box, makeStyles, Grid } from "@material-ui/core";
import { LanguageContext } from "../../Languages/LanguageContext.js";
import LinkedIn from "../../Assets/linkedin.svg";
import { Link } from "react-router-dom";
// import { ReactComponent as Logo } from "../../Assets/fulltextlogo.svg";
import { ReactComponent as Logo } from "../../Assets/remotologo.svg";

const useStyles = makeStyles((theme) => ({
  canvas: {
    background: "#FEF2F4",
    justifyContent: "center",
    paddingBottom: "50px",
  },

  text: {
    textSize: "16px",
    fontWeight: "400px",
    color: "#003D50",
    letterSpacing: "-0.02em",
    textDecoration: "none",
  },
  link: {
    textDecoration: "none",
    color: "#0E0E2C",
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "24px",
    letterSpacing: "-0.02em",
  },
  select: {
    "& .MuiSelect-selectMenu": {
      paddingLeft: "8px",
    },
  },
}));

function Footer() {
  const classes = useStyles();
  const { locale, messages } = useContext(LanguageContext);

  return (
    <Box className={classes.canvas}>
      <Grid container justifyContent="center" alignItems="flex-start">
        <Grid container item lg={10} xs={10}>
          <Grid
            item
            container
            justifyContent="center"
            style={{ marginTop: "5rem" }}
          >
            <Grid item xs={12} lg={5} className="px-2 w-full">
              <div className="rounded-md w-full p-5 text-lg text-white flex justify-between cursor-pointer items-center">
                <Logo />
              </div>
            </Grid>
            <Grid item xs={12} lg={5} className="rounded-lg px-2 w-full">
              <div className="rounded-md w-full text-lg flex justify-start lg:justify-end items-center">
                <div className="flex flex-col gap-1">
                  <h1 className="text-2xl font-semibold">About Remoto</h1>

                  <Link className="text-sm lg:text-md" to="/privacy-policy">
                    {messages[locale].privacy}
                  </Link>

                  <Link className="text-sm lg:text-md" to="/legal-advice">
                    {messages[locale].legalAdvice}
                  </Link>

                  <Link className="text-sm lg:text-md" to="/terms-conditions">
                    {messages[locale].termsAndConditions}
                  </Link>

                  <Link className="text-sm lg:text-md" to="/cookies-policy">
                    Cookies
                  </Link>

                  <a
                    className="text-sm lg:text-md"
                    href="https://youtu.be/ihcGIWB06sA?feature=shared"
                    target="_blank"
                  >
                    Release notes
                  </a>
                </div>
              </div>
            </Grid>
          </Grid>

          <Grid
            item
            container
            justifyContent="center"
            style={{ marginTop: "5rem" }}
          >
            <Grid item xs={12} lg={5} className="px-2 w-full">
              <div className="rounded-md w-full p-5 text-sm lg:text-md flex justify-between cursor-pointer items-center">
                {messages[locale].rightsReserved}
              </div>
            </Grid>
            <Grid item xs={12} lg={5} className="rounded-lg px-2 w-full">
              <div className="rounded-md w-full text-lg flex justify-start lg:justify-end items-center">
                <a
                  href="https://www.linkedin.com/company/remoto-inc/"
                  style={{ color: "white" }}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={LinkedIn} alt="LinkedIn"></img>
                </a>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
    // <Box className={classes.wrapper}>
    //   <Grid
    //     container
    //     xs={10}
    //     lg={10}
    //     justifyContent="space-between"
    //     alignItems="center"
    //   >
    //     <Grid item xs={10} lg={6}>
    //       <Grid container style={{ marginBottom: "8px" }}>
    //         <Link to="/privacy-policy" className={classes.link}>
    //           <Typography
    //             className={classes.text}
    //             color="#0E0E2C"
    //             style={{ marginRight: "20px" }}
    //           >
    //             {messages[locale].privacy}
    //           </Typography>
    //         </Link>

    //         <Link to="/legal-advice" className={classes.link}>
    //           <Typography
    //             className={classes.text}
    //             color="#0E0E2C"
    //             style={{ marginRight: "20px" }}
    //           >
    //             {messages[locale].legalAdvice}
    //           </Typography>
    //         </Link>

    //         <Link to="/terms-conditions" className={classes.link}>
    //           <Typography
    //             className={classes.text}
    //             color="#0E0E2C"
    //             style={{ marginRight: "20px" }}
    //           >
    //             {messages[locale].termsAndConditions}
    //           </Typography>
    //         </Link>

    //         <Link to="/cookies-policy" className={classes.link}>
    //           <Typography className={classes.text} color="#0E0E2C">
    //             Cookies
    //           </Typography>
    //         </Link>
    //       </Grid>

    //       <Typography className={classes.text}>
    //         {messages[locale].rightsReserved}
    //       </Typography>
    //     </Grid>

    //     <Grid container item xs={2} lg={6} justifyContent="flex-end">
    //       <a
    //         href="https://www.linkedin.com/company/remoto-inc/"
    //         style={{ color: "white" }}
    //         target="_blank"
    //         rel="noopener noreferrer"
    //       >
    //         <img src={LinkedIn} alt="LinkedIn"></img>
    //       </a>
    //     </Grid>
    //   </Grid>
    // </Box>
  );
}

export default Footer;
