import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Typography, useTheme } from "@material-ui/core";
import { useForm } from "react-hook-form";
import { companySchema } from "../../schemas/profile";
import { EditOverview } from "./CompanyOverview";
import { useContext, useState, useEffect } from "react";
import { UserContext } from "../../context/UserContext";
import {
  collection,
  query,
  orderBy,
  where,
  limit,
  getDocs,
  serverTimestamp,
  updateDoc,
  doc,
} from "firebase/firestore";
import { COLLECTIONS, firestore } from "../../firebase/config";
import { FaArrowRight } from "react-icons/fa6";
import { IconButton, SecondaryIconButton } from "../Global/Buttons";
import { FaArrowLeft } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import useCompanyStore from "../../store/company";

export default function Onboarding() {
  const { user } = useContext(UserContext);
  const [initialValues, setInitialValues] = useState({});
  const { t } = useTranslation();
  const [companyData, setCompantData] = useState(null);
  const { company, setCompany } = useCompanyStore();
  const [step, setStep] = useState(2);
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
  } = useForm({
    resolver: yupResolver(companySchema),
    defaultValues: {
      companyName: "",
      website: "",
      tagline: "",
      industry: "",
      size: "",
      location: "",
      // city: "",
      // country: "",
      // timezone: "",
      // currency: "",
    },
  });

  useEffect(() => {
    setInitialValues(watch());
  }, []);

  useEffect(() => {
    const fetchCompany = async () => {
      const dbRefCompany = collection(firestore, COLLECTIONS.COMPANIES);
      const qC = query(
        dbRefCompany,
        orderBy("postedOn"),
        where("email", "==", user.email),
        limit(1)
      );
      const reqC = await getDocs(qC);

      if (reqC.docs.length > 0) {
        const data = reqC.docs[0].data();
        setCompantData({ id: reqC.docs[0].id, data });
        if (data?.isOnboarded) {
          navigate("/jobs");
        }

        setValue("companyName", data.companyName);
        setValue("website", data.website);
        setValue("tagline", data.tagline);
        // setValue("location", data.city);
        // setValue("country", data.country);
        setValue("location", data.location ? data.location : "");
        // setValue("timezone", data.timezone);
        // setValue("currency", data.currency);
        setValue("industry", data.industry);
        setValue("size", data.size);
      }
    };
    fetchCompany();
  }, [user]);

  const handleSave = (data) => {
    if (!isFormChanged()) {
      setStep(3);
      return;
    }
    const docRef = doc(firestore, COLLECTIONS.COMPANIES, companyData?.id);
    updateDoc(docRef, {
      ...data,
      isOnboarded: true,
    })
      .then(() => {
        console.log("updated");
        setStep(3);
        setCompany({
          ...company,
          ...data,
          isOnboarded: true,
        });
      })
      .catch((err) => {});
  };

  const isFormChanged = () => {
    const currentValues = watch();
    return Object.keys(initialValues).some((key) => {
      return initialValues[key] != currentValues[key];
    });
  };
  return (
    <div className="min-h-screen ">
      <div className="w-4/5 mx-auto flex flex-col items-center gap-12 pt-16">
        <Progress step={step} />
        {step === 2 ? (
          <>
            <h1 className="text-[44px] font-medium text-center">
              <span className="text-primary-400 font-semibold">
                {t("tell_us_more_about_yourself")}
              </span>{" "}
              {t("make_your_profile")}
            </h1>
            <div>
              <EditOverview
                handleClose={() => console.log("closed")}
                register={register}
                handleSave={handleSave}
                errors={errors}
                handleSubmit={handleSubmit}
                onboarding={true}
                setValue={setValue}
                watch={watch}
              />
            </div>
          </>
        ) : step === 3 ? (
          <>
            <h1 className="text-[44px] font-medium text-center">
              <span className="text-primary-400 font-semibold">
                {t("you_are_all_set")}
              </span>{" "}
              {t("post_first_job")}
            </h1>
            <div className="w-full flex justify-center gap-4 items-center">
              <SecondaryIconButton
                text={t("go_back_information")}
                icon={<FaArrowLeft className="text-primary-400" />}
                onClick={() => setStep(2)}
              />
              <IconButton
                icon={<FaArrowRight className="text-white" />}
                text={t("start_hiring")}
                iconFirst={false}
                onClick={() => navigate("/jobs")}
              />
            </div>
          </>
        ) : null}
      </div>
    </div>
  );
}
function Progress({ step }) {
  return (
    <div className="w-full max-w-lg mx-auto px-4 sm:px-0">
      <ul aria-label="Steps" className="flex items-center">
        {[1, 2, 3].map((item, idx) => (
          <>
            <li
              aria-current={step == idx + 1 ? "step" : false}
              className="relative flex-1 last:flex-none flex items-center"
            >
              <div
                className={`w-8 h-8 rounded-full border-2 flex-none flex items-center justify-center ${
                  step > idx + 1
                    ? "bg-primary-400 border-primary-400"
                    : "" || step == idx + 1
                    ? "border-primary-400"
                    : ""
                }`}
              >
                <span
                  className={`flex justify-center items-center rounded-full ${
                    step === item ? "text-primary-400" : "text-[#e5e7eb]"
                  } ${step > item ? "hidden" : ""}`}
                >
                  {item}
                </span>
                {step > idx + 1 ? (
                  <svg
                    className="w-3.5 h-3.5 text-white lg:w-4 lg:h-4 "
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 16 12"
                  >
                    <path
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M1 5.917 5.724 10.5 15 1.5"
                    />
                  </svg>
                ) : (
                  ""
                )}
              </div>
              <hr
                className={`w-full border ${
                  idx + 1 == 3
                    ? "hidden"
                    : "" || step > idx + 1
                    ? "border-primary-400"
                    : ""
                }`}
              />
              <h1
                className={`absolute top-full ${
                  item === 1 ? "-left-3" : "-left-[22px]"
                } w-max`}
                style={{ width: "max-content" }}
              >
                {item === 1
                  ? "Account"
                  : item === 2
                  ? "Information"
                  : "Start Hiring"}
              </h1>
            </li>
          </>
        ))}
      </ul>
    </div>
  );
}
