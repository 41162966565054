import React from "react";
import { currencyValues } from "../../utils/constants";
export default function TextInput({
  label,
  placeholder,
  type,
  name,
  error,
  regProp,
  isDisabled,
  required,
}) {
  return (
    <label className="block">
      <span className="text-sm font-medium text-slate-700 mb-2 flex">
        {label}
        {required && <p className="text-sm text-red-500 ml-1">*</p>}
      </span>
      <input
        className="placeholder:text-slate-400 block bg-white w-full border border-borderGrey-400 rounded-md py-2 pl-3 pr-3
           shadow-sm focus:outline-none focus:border-sky-500
            focus:ring-sky-500 focus:ring-1 sm:text-sm disabled:opacity-50 disabled:bg-customGrey-100"
        placeholder={placeholder}
        type={type || "text"}
        name={name}
        {...regProp}
        disabled={isDisabled}
      />
      <p className="text-sm text-red-500">{error}</p>
    </label>
  );
}

export function CurrencyInput({
  label,
  placeholder,
  type,
  name,
  error,
  regProp,
  isDisabled,
  required,
  currency,
  handleSelect,
}) {
  return (
    <label className="block">
      <span className="text-sm font-medium text-slate-700 mb-2 flex">
        {label}
        {required && <p className="text-sm text-red-500 ml-1">*</p>}
      </span>
      <div className="flex items-center border text-sm border-gray-300 rounded-md overflow-hidden">
        <input
          className="p-2 w-full border-none focus:ring-0 focus:outline-none"
          placeholder={placeholder}
          type={type || "text"}
          name={name}
          {...regProp}
          disabled={isDisabled}
        />
        <select
          value={currency}
          onChange={handleSelect}
          name="currency"
          className="p-2 bg-gray-100 border-none focus:ring-0 focus:outline-none"
        >
          {currencyValues.map((c) => (
            <option value={c}>{c}</option>
          ))}
        </select>
      </div>

      <p className="text-sm text-red-500">{error}</p>
    </label>
  );
}
