import React from "react";
import "react-quill/dist/quill.snow.css";
import ReactQuill from "react-quill";
import "../../style.css";
const RichText = ({ placeholder, value, handleChange }) => {
  return (
    <div>
      <ReactQuill
        theme={"snow"}
        onChange={handleChange}
        value={value}
        modules={RichText.modules}
        formats={RichText.formats}
        bounds={".app"}
        placeholder={placeholder}
        className="quill-container"
      />
    </div>
  );
};

RichText.modules = {
  toolbar: [
    [
      { header: "1" },
      { header: "2" },
      // { font: [] }
    ],
    // [{ size: [] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" },
    ],
    // ["link", "image", "video"],
    ["clean"],
  ],
  clipboard: {
    // toggle to add extra line breaks when pasting HTML:
    matchVisual: false,
  },
};

RichText.formats = [
  "header",
  // "font",
  // "size",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "indent",
  // "link",
  // "image",
  // "video",
];

export default RichText;
