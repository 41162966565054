import React, { useState, useContext } from "react";
import { LanguageContext } from "../../Languages/LanguageContext";
import { Grid, makeStyles } from "@material-ui/core";
import LanguageChange from "../../Languages/LanguageChange";
import RemotocoLogo from "../../Assets/remotologo.svg";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ProfileIcon from "../../Assets/profile.svg";
import LogoutIcon from "../../Assets/logout.svg";
import { firestore, COLLECTIONS, auth } from "../../firebase/config";
import { IoMdSettings } from "react-icons/io";
import { signOut } from "firebase/auth";
import ProfileAvatar from "../../Assets/profileavatar.svg";
import {
  collection,
  query,
  orderBy,
  where,
  limit,
  getDocs,
} from "firebase/firestore";
import Avatar from "@material-ui/core/Avatar";
import { UserContext } from "../../context/UserContext";
import { useNavigate } from "react-router-dom";
import { NavbarDropdown } from "./CompanyHeader";
import useCandidateStore from "../../store/candidate";
import CanidateNotifications from "../Notification/CandidateNotification";
const useStyles = makeStyles((theme) => ({
  companyIcon: {
    maxWidth: "100%",
    height: "auto",

    [theme.breakpoints.down("sm")]: {
      width: "70px",
    },
  },
  logo: {
    color: "#826AA6",
    cursor: "pointer",
  },

  canvas: {
    borderBottom: "1px solid #D1D1D1",
    background: "#FFFFFF",
    height: "96px",
  },
  emptyChip: {
    padding: "8px 16px ",
    fontSize: "16px",
    borderRadius: "25px",
    transition: ".3s",
    fontWeight: 700,
    marginRight: "15px",
    border: `1px solid #003D50`,
    color: "#003D50",

    letterSpacing: "-0.02em",
    textDecoration: "none",
  },

  filledChip: {
    padding: "8px 16px ",
    fontSize: "16px",
    borderRadius: "25px",
    transition: ".3s",
    fontWeight: 700,
    marginRight: "15px",
    border: `1px solid white`,
    color: "white",
    background: "#003D50",

    letterSpacing: "-0.02em",
    textDecoration: "none",
  },

  profileInitials: {
    width: "75px",
    height: "75px",
  },
  menu: {
    borderRadius: "20px",
    marginTop: theme.spacing(1),
  },
  icon: {
    minWidth: "30px",
  },
  meuItemText: {
    color: "#555555",
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "24px",
    letterSpacing: "-0.02em",
  },
  formControl: {
    borderRadius: "25px",
    border: `1px solid #003D50`,
    padding: "0px 8px",
    marginRight: "15px",

    "& .MuiSelect-root": {
      borderBottom: "none",
      color: "#003D50",
      fontSize: "16px",
    },
    "& .MuiInput-underline:before": {
      borderBottom: "none",
    },
    "& .MuiInput-underline:after": {
      borderBottom: "none",
    },
    "&:hover .MuiInput-underline:before": {
      borderBottom: "none",
    },
    "&:hover .MuiInput-underline:after": {
      borderBottom: "none",
    },
  },
}));

function ProfileHeader() {
  const [anchorEl, setAnchorEl] = useState(null);
  // const [user, setUser] = useState(null);
  const { candidate, setCandidate } = useCandidateStore();
  const { user, setUser } = useContext(UserContext);
  const [imageUrl, setImageUrl] = useState(null);
  const { locale, messages } = useContext(LanguageContext);
  const navigate = useNavigate();

  const handleUserInit = async (user) => {
    const dbRef = collection(firestore, COLLECTIONS.CANDIDATES);
    const q = query(
      dbRef,
      orderBy("postedOn"),
      where("email", "==", user.email),
      limit(1)
    );
    const req = await getDocs(q);
    const userDocData = req.docs[0].data();

    if (userDocData.imageUrl !== "") {
      setImageUrl(userDocData.imageUrl);
    }
  };
  const handleLogoClick = () => {
    if (user) {
      window.location.href = `/jobs`;
    } else {
      window.location.href = `/${locale}`;
    }
  };

  const classes = useStyles();

  React.useEffect(() => {
    if (user) {
      handleUserInit(user);
    }
  }, [user]);

  return (
    <>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        className={classes.canvas}
      >
        <Grid container item lg={11} xs={12}>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid
              item
              xs={4}
              className={classes.logo}
              onClick={handleLogoClick}
            >
              <img
                src={RemotocoLogo}
                className={classes.companyIcon}
                alt="remoto logo"
              />
            </Grid>
            <Grid
              container
              item
              xs={8}
              justifyContent="flex-end"
              alignItems="center"
            >
              <Grid item>
                <LanguageChange />
              </Grid>
              <Grid>
                <CanidateNotifications />
              </Grid>
              {/* <Grid>
                <IoMdSettings
                  className="text-primary-400 cursor-pointer text-2xl mx-2"
                  onClick={() => navigate("/settings")}
                />
              </Grid> */}
              <NavbarDropdown
                data={candidate}
                clearStore={() => setCandidate(null)}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
export default ProfileHeader;
