import React, { useContext, useState } from "react";
import CompanyHeader from "../components/Header/CompanyHeader";
import PrimaryBtn, { SecondaryBtn } from "../components/Global/Buttons";
import Toggler from "../components/Global/Toggler";
import DialogModal from "../components/Global/Dialog";
import { Grid } from "@material-ui/core";
import { useForm } from "react-hook-form";
import TextInput from "../components/Global/TextInput";
import { passwordSchema } from "../schemas/profile";
import { yupResolver } from "@hookform/resolvers/yup";
import { auth, COLLECTIONS, firestore, storage } from "../firebase/config";
import {
  deleteUser,
  EmailAuthProvider,
  GoogleAuthProvider,
  reauthenticateWithCredential,
  reauthenticateWithPopup,
  signOut,
  updatePassword,
} from "firebase/auth";
import { DeleteDialog } from "../components/Candidate/ExperienceTab";
import { useNavigate } from "react-router-dom";
import Phone from "../components/Global/Phone";
import useCompanyStore from "../store/company";
import useCandidateStore from "../store/candidate";
import {
  collection,
  deleteDoc,
  doc,
  getDocs,
  query,
  setDoc,
  where,
} from "firebase/firestore";
import ProfileHeader from "../components/Header/ProfileHeader";
import CandidateSidebar from "../components/Sidebars/CandidateSidebar";
import Progress from "../components/Candidate/Progress";
import { errorNotfiy, successNotfiy } from "../components/Global/Toasts";
import { UserContext } from "../context/UserContext";
import { deleteObject, ref } from "firebase/storage";
import { useTranslation } from "react-i18next";
export default function Settings() {
  const [passModal, setPassModal] = useState(false);
  const [delModal, setDelModal] = useState(false);
  const [phoneModal, setPhoneModal] = useState(false);
  const { company, setCompany } = useCompanyStore();
  const { candidate, setCandidate } = useCandidateStore();
  const { setUser } = useContext(UserContext);
  const { t } = useTranslation();
  const isCompany = company ? true : false;
  const [passwordValidateModal, setPasswordValidateModal] = useState(false);
  const [password, setPassword] = useState("");
  const navigate = useNavigate();

  const handlePhoneNumber = async (phone) => {
    const model = isCompany ? COLLECTIONS.COMPANIES : COLLECTIONS.CANDIDATES;
    const id = isCompany ? company?.id : candidate?.id;
    try {
      await setDoc(
        doc(firestore, model, id),
        {
          phoneNumber: phone,
        },
        { merge: true }
      );
      if (isCompany) {
        setCompany({ ...company, phoneNumber: phone });
      } else {
        setCandidate({ ...candidate, phoneNumber: phone });
      }
      setPhoneModal(false);
      console.log("Phone number added/updated successfully");
    } catch (error) {
      console.error("Error adding/updating phone number:", error);
    }
  };

  const handlePasswordDelete = async () => {
    const user = auth.currentUser;
    if (!user) return;
    // Re-authenticate with email/password
    try {
      const credential = EmailAuthProvider.credential(user.email, password);
      await reauthenticateWithCredential(user, credential);
      console.log("Re-authentication successful");
      handleDeleteAccount(user);
    } catch (err) {
      errorNotfiy("Password does not match");
      throw new Error(err);
    }
  };

  const revalidatePassword = async () => {
    const user = auth.currentUser;
    if (!user) return;
    try {
      const providerId = user.providerData[0]?.providerId;
      if (providerId === "google.com") {
        // User signed in with Google
        console.log("User signed in with Google, re-authenticating...");

        // Re-authenticate with Google
        const provider = new GoogleAuthProvider();
        await reauthenticateWithPopup(user, provider);
        handleDeleteAccount(user);
      } else if (providerId === "password") {
        // User signed in with Email/Password
        console.log("User signed in with Email/Password, re-authenticating...");
        setPasswordValidateModal(true);
        // Prompt the user for their email and password
        // const password = prompt("Please enter your password:");
      } else {
        console.error("Unsupported provider:", providerId);
        return;
      }
    } catch (err) {
      console.log("🚀 ~ revalidatePassword ~ err:", err);
    }
  };

  const handleDeleteAccount = async (user) => {
    try {
      if (isCompany) {
        // deleting jobs
        const jobsQuery = query(
          collection(firestore, COLLECTIONS.JOBS),
          where("companyEmail", "==", company.email)
        );
        const jobsSnapshot = await getDocs(jobsQuery);

        const jobDeletionPromises = jobsSnapshot.docs.map(async (jobDoc) => {
          const jobId = jobDoc.id;
          // deleting applications
          const applicationQuery = query(
            collection(firestore, COLLECTIONS.APPLICATIONS),
            where("jobId", "==", jobId)
          );

          const applicationsSnapshot = await getDocs(applicationQuery);

          const deleteApplicationPromises = applicationsSnapshot.docs.map(
            async (applicationDoc) => {
              return deleteDoc(applicationDoc.ref);
            }
          );

          await Promise.all(deleteApplicationPromises);
          return deleteDoc(jobDoc.ref);
        });

        await Promise.all(jobDeletionPromises);
        // deleting user from candidate collection

        const companyQuery = query(
          collection(firestore, COLLECTIONS.COMPANIES),
          where("uid", "==", user?.uid)
        );
        const companySnapshot = await getDocs(companyQuery);

        const deleteCompany = companySnapshot.docs.map(async (candidateDoc) => {
          return deleteDoc(candidateDoc.ref);
        });
        await Promise.all(deleteCompany);

        // deleting user from auth firebase
        await deleteUser(user);

        // deleting from storage
        if (company?.imageUrl) {
          const profileRef = ref(storage, company?.imageUrl);

          await deleteObject(profileRef)
            .then(() => {
              console.log("Profile Pic deleted from storage");
            })
            .catch((error) => {
              console.error("Error deleting file: ", error);
            });
        }

        if (company?.coverUrl) {
          const coverRef = ref(storage, company?.coverUrl);

          await deleteObject(coverRef)
            .then(() => {
              console.log("Cover Pic deleted from storage");
            })
            .catch((error) => {
              console.error("Error deleting file: ", error);
            });
        }

        if (company?.media?.length > 0) {
          const deletionMediaPromises = company?.media?.map((imageUrl) => {
            const mediaRef = ref(storage, imageUrl);
            return deleteObject(mediaRef);
          });
          await Promise.all(deletionMediaPromises);
        }

        if (company?.videos?.length > 0) {
          const deletionVideoPromises = company?.videos?.map((imageUrl) => {
            const videoRef = ref(storage, imageUrl);
            return deleteObject(videoRef);
          });
          await Promise.all(deletionVideoPromises);
        }

        //logout user
        signOut(auth)
          .then(() => {
            setUser(null);
            setCompany(null);
          })
          .catch((error) => {
            throw new Error(error);
          });
      } else {
        // deleting applications
        const applicationQuery = query(
          collection(firestore, COLLECTIONS.APPLICATIONS),
          where("candidateEmail", "==", candidate.email)
        );

        const req = await getDocs(applicationQuery);

        const deletePromises = req.docs.map(async (doc) => {
          return deleteDoc(doc.ref);
        });

        await Promise.all(deletePromises);

        // deleting user from candidate collection
        console.log("🚀 ~ handleDeleteAccount ~ user?.uid:", user?.uid);

        const candidateQuery = query(
          collection(firestore, COLLECTIONS.CANDIDATES),
          where("uid", "==", user?.uid)
        );
        const candidateSnapshot = await getDocs(candidateQuery);

        const deleteCandidatePromises = candidateSnapshot.docs.map(
          async (candidateDoc) => {
            return deleteDoc(candidateDoc.ref);
          }
        );
        await Promise.all(deleteCandidatePromises);

        // deleting user from auth firebase
        await deleteUser(user);

        // deleting from storage
        if (candidate?.imageUrl) {
          const profileRef = ref(storage, candidate?.imageUrl);

          await deleteObject(profileRef)
            .then(() => {
              console.log("Profile Pic deleted from storage");
            })
            .catch((error) => {
              console.error("Error deleting file: ", error);
            });
        }

        if (candidate?.resumeUrl) {
          const resumeRef = ref(storage, candidate?.resumeUrl);

          await deleteObject(resumeRef)
            .then(() => {
              console.log("Resume deleted from storage");
            })
            .catch((error) => {
              console.error("Error deleting file: ", error);
            });
        }

        signOut(auth)
          .then(() => {
            setUser(null);
            setCandidate(null);
          })
          .catch((error) => {
            throw new Error(error);
          });
      }
      successNotfiy("User account deleted successfully");
      setTimeout(() => {
        navigate("/login");
      }, 1000);

      console.log("User account deleted successfully");
    } catch (error) {
      errorNotfiy("Something went wrong while deleting account");
      console.error("Error deleting user account:", error);
    }
  };

  return (
    <div>
      {isCompany ? <CompanyHeader /> : <ProfileHeader />}
      <Grid container>
        {!isCompany && (
          <Grid item xs={1}>
            <CandidateSidebar component="Jobs" />
          </Grid>
        )}
        <Grid item xs={isCompany ? 12 : 11}>
          {!isCompany && (
            <div className="px-6 pt-6">
              {!candidate?.isComplete && <Progress userInfo={candidate} />}
            </div>
          )}
          <div
            className={`${isCompany ? "w-4/5 my-10" : "w-11/12 my-6"} mx-auto `}
          >
            <h1 className="text-3xl font-bold">{t("Settings")}</h1>
            {/* basic */}
            <div className="border border-borderGrey-400 rounded-md p-6 mt-4">
              <h1 className="text-2xl font-bold mb-3">
                {t("basic_information")}
              </h1>
              <div className="flex items-center w-full">
                <div className="w-2/5">
                  <h4 className="text-sm font-medium text-slate-700 mb-2">
                    {t("name")}
                  </h4>
                  <h2 className="text-lg font-medium text-slate-700 mb-2">
                    {company
                      ? company?.companyName
                      : candidate?.firstName + " " + candidate?.lastName}
                  </h2>
                </div>
                <div className="w-3/5">
                  <h4 className="text-sm font-medium text-slate-700 mb-2">
                    {t("email")}
                  </h4>
                  <h2 className="text-lg font-medium text-slate-700 mb-2">
                    {company ? company?.email : candidate?.email}
                  </h2>
                </div>
              </div>
            </div>
            {/* Phone/Password */}
            <div className="border border-borderGrey-400 rounded-md p-6 mt-4">
              <div>
                <h1 className="text-2xl font-bold mb-3">{t("phoneNumber")}</h1>
                <p className="font-medium text-lg">
                  {company?.phoneNumber
                    ? company.phoneNumber
                    : candidate?.phoneNumber
                    ? candidate.phoneNumber
                    : "Add a phone number to your Remoto account."}
                </p>
                <div className="mt-4">
                  <PrimaryBtn
                    text={
                      company?.phoneNumber || candidate?.phoneNumber
                        ? t("update_phone_number")
                        : t("add_phone_number")
                    }
                    onClick={() => setPhoneModal(true)}
                  />
                </div>
              </div>
              <div className="mt-10">
                <h1 className="text-2xl font-bold mb-3">{t("password")}</h1>
                <p className="font-medium text-lg">
                  {t("change_password_prompt")}
                </p>
                <div className="mt-4">
                  <PrimaryBtn
                    text={t("change_password")}
                    onClick={() => setPassModal(true)}
                  />
                </div>
              </div>
              {/* <div className="mt-10">
            <h1 className="text-2xl font-bold mb-3">
              Two-Factor Authentication
            </h1>
            <p className="font-medium text-lg">
              Changing the two factor authentication may decrease the security
              of your account
            </p>
            <div className="mt-4">
              <Toggler />
            </div>
          </div> */}
            </div>
            {/* Delete */}
            <div className="border border-borderGrey-400 rounded-md p-6 mt-4">
              <h1 className="text-2xl font-bold mb-3">{t("delete_account")}</h1>
              <p className="font-medium text-lg">
                {t("delete_account_warning")}{" "}
                <strong>{t("not_reverse")}</strong>{" "}
              </p>
              <button
                onClick={() => setDelModal(true)}
                type={"button"}
                className={`${"bg-red-400"} ${"border border-red-400"} text-white font-semibold rounded-md px-3 py-2
        hover:opacity-90 disabled:bg-borderGrey-400 disabled:border-borderGrey-400 mt-4`}
              >
                {t("delete_account")}
              </button>
            </div>
            {passModal && (
              <DialogModal
                open={passModal}
                handleClose={() => setPassModal(false)}
                title={t("change_password")}
                size={"md"}
              >
                <PasswordForm handleClose={() => setPassModal(false)} />
              </DialogModal>
            )}
            {phoneModal && (
              <DialogModal
                open={phoneModal}
                handleClose={() => setPhoneModal(false)}
                title={t("phone_number")}
                size={"md"}
              >
                <PhoneForm
                  handleClose={() => setPhoneModal(false)}
                  handlePhoneNumber={handlePhoneNumber}
                  value={
                    company?.phoneNumber
                      ? company?.phoneNumber
                      : candidate?.phoneNumber
                      ? candidate?.phoneNumber
                      : ""
                  }
                />
              </DialogModal>
            )}
            {passwordValidateModal && (
              <DialogModal
                open={passwordValidateModal}
                handleClose={() => setPasswordValidateModal(false)}
                title={t("Validate Account")}
                size={"sm"}
              >
                <label className="block">
                  <span className="text-sm font-medium text-slate-700 mb-2 flex">
                    {"Password"}
                    <p className="text-sm text-red-500 ml-1">*</p>
                  </span>
                  <input
                    className="placeholder:text-slate-400 block bg-white w-full border border-borderGrey-400 rounded-md py-2 pl-3 pr-3
           shadow-sm focus:outline-none focus:border-sky-500
            focus:ring-sky-500 focus:ring-1 sm:text-sm disabled:opacity-50 disabled:bg-customGrey-100"
                    placeholder={"**********"}
                    type={"password"}
                    name={"password"}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </label>
                <div className="my-4 flex items-center gap-4">
                  <SecondaryBtn
                    text={t("cancel")}
                    onClick={() => setPasswordValidateModal(false)}
                  />
                  <PrimaryBtn
                    text={t("Delete Account")}
                    onClick={handlePasswordDelete}
                    type="button"
                  />
                </div>
              </DialogModal>
            )}
            <DeleteDialog
              open={delModal}
              handleClose={() => setDelModal(false)}
              handleDeleteConfirm={revalidatePassword}
              title={t("delete_account_confirmation")}
            />
          </div>
        </Grid>
      </Grid>
    </div>
  );
}

function PhoneForm({ handleClose, handlePhoneNumber, value }) {
  const [phone, setPhone] = useState(value);
  const { t } = useTranslation();
  return (
    <div className="w-full">
      <Phone phone={phone} setPhone={setPhone} />
      <div className="my-4 flex items-center gap-4">
        <SecondaryBtn text={t("cancel")} onClick={handleClose} />
        <PrimaryBtn
          text={t("update_phone_number")}
          // loading={loading}
          onClick={() => handlePhoneNumber(phone)}
          type="button"
          // disabled={!(isFormChanged() || !!resumeFile)}
        />
      </div>
    </div>
  );
}
function PasswordForm({ handleClose }) {
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
  } = useForm({
    resolver: yupResolver(passwordSchema),
  });

  const onSubmit = async (data) => {
    const user = auth.currentUser;
    if (user) {
      const credential = EmailAuthProvider.credential(
        user.email,
        data.oldPassword
      );

      try {
        await reauthenticateWithCredential(user, credential);
        await updatePassword(user, data.newPassword);
        successNotfiy(t("passwordChangedSuccessfully"));
        handleClose();
      } catch (error) {
        if (error.code === "auth/wrong-password") {
          setError("oldPassword", {
            type: "manual",
            message: t("old_password_incorrect"),
          });
        } else {
          console.error("Error updating password:", error);
        }
      }
    }
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="flex flex-col gap-4">
        <TextInput
          label={t("old_password")}
          placeholder={"**********"}
          name="oldPassword"
          type={"password"}
          regProp={{ ...register("oldPassword") }}
          error={errors.oldPassword?.message}
          required={true}
        />
        <TextInput
          label={t("new_password")}
          placeholder={"**********"}
          name="newPassword"
          type={"password"}
          regProp={{ ...register("newPassword") }}
          error={errors.newPassword?.message}
          required={true}
        />
        <TextInput
          label={t("confirm_new_password")}
          placeholder={"**********"}
          name="confirmPassword"
          type={"password"}
          regProp={{ ...register("confirmPassword") }}
          error={errors.confirmPassword?.message}
          required={true}
        />
        <div className="my-4 flex items-center gap-4">
          <SecondaryBtn text={t("cancel")} onClick={handleClose} />
          <PrimaryBtn
            text={t("update_password")}
            // loading={loading}
            type="submit"
            // disabled={!(isFormChanged() || !!resumeFile)}
          />
        </div>
      </div>
    </form>
  );
}
