import React from "react";
import ResumeTab from "../ResumeTab";
import { CircularProgress } from "@material-ui/core";
import { FaArrowRight } from "react-icons/fa6";
import { useTranslation } from "react-i18next";

export default function Resume({
  userInfo,
  setParentFile,
  handleUpload,
  resumeFile,
  loading,
}) {
  const { t } = useTranslation();
  return (
    <>
      <div className="text-3xl my-12 font-semibold w-3/5 mx-auto text-center">
        {t("resume_first_step")}{" "}
        <span className="text-primary-400">{t("upload_below")}</span>
      </div>
      <div className="w-4/5 mx-auto flex flex-col items-center gap-12 ">
        {userInfo && (
          <ResumeTab
            userInfo={userInfo}
            onboarding={true}
            setParentFile={setParentFile}
          />
        )}
      </div>
      <div className="my-6 w-full flex justify-center">
        <button
          onClick={handleUpload}
          type={"button"}
          disabled={!!!resumeFile || loading}
          className={`bg-secondary-400 text-white font-semibold rounded-md px-3 py-2 flex gap-2 items-center 
        hover:opacity-90 disabled:bg-borderGrey-400 disabled:border-borderGrey-400`}
        >
          {loading ? (
            <CircularProgress size={18} color="inherit" />
          ) : (
            <>
              {t("next_step_profile")} {<FaArrowRight className="text-white" />}
            </>
          )}
        </button>
      </div>
    </>
  );
}
