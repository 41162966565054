import React, { useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  TextField,
  Button,
} from "@material-ui/core";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { EmailAuthProvider, reauthenticateWithCredential } from "firebase/auth";
const DeleteAccountConfirmationDialog = ({
  open,
  onClose,
  deleteAccount,
  currentUser,
}) => {
  const [password, setPassword] = useState("");

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleConfirm = async () => {
    // Here, you can add your logic to validate the password and delete the account.
    // For this example, we'll just call onDeleteAccount when the password is confirmed.
    const credential = EmailAuthProvider.credential(
      currentUser.email,
      password
    );
    console.log(credential);
    reauthenticateWithCredential(currentUser, credential)
      .then(() => {
        // User re-authenticated.
        console.log("User re-authenticated");
        deleteAccount();
        onClose();
      })
      .catch((error) => {
        // An error ocurred
        // ...
        console.log(error);
        toast.error("Wrong Password", {
          position: toast.POSITION.CENTER,
        });
      });
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <ToastContainer />
      <DialogTitle>Confirm Deletion</DialogTitle>
      <DialogContent>
        <TextField
          label="Enter your password"
          type="password"
          fullWidth
          value={password}
          onChange={handlePasswordChange}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleConfirm} color="primary">
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteAccountConfirmationDialog;
