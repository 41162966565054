import React, { useState } from "react";
import DialogModal from "../Global/Dialog";
import { Grid } from "@material-ui/core";
import TextInput from "../Global/TextInput";
import { languageSchema } from "../../schemas/profile";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import PrimaryBtn, { SecondaryBtn } from "../Global/Buttons";
import { SelectDropdown } from "../Global/SelectOption";
import { languages, proficiencyOptions } from "../../utils/constants";
import { FaCirclePlus } from "react-icons/fa6";
import { COLLECTIONS, firestore } from "../../firebase/config";
import useCandidateStore from "../../store/candidate";
import { doc, setDoc } from "firebase/firestore";
import { useTranslation } from "react-i18next";
export default function LanguageSelection({ open, handleClose, lngs }) {
  const { t } = useTranslation();
  const { candidate, setCandidate } = useCandidateStore();
  const [loading, setLoading] = useState(false);
  const {
    handleSubmit,
    watch,
    formState: { errors },
    trigger,
    setValue,
    reset,
  } = useForm({
    resolver: yupResolver(languageSchema),
    defaultValues: {
      languages: lngs
        ? lngs
        : [
            {
              name: "",
              level: "",
            },
          ],
    },
  });

  console.log(watch());

  const handleAddClick = () => {
    const temp = watch("languages");
    temp.push({
      name: "",
      level: "",
    });
    setValue("languages", temp);
  };

  const handleRemove = (index) => {
    console.log("🚀 ~ handleRemove ~ index:", index);
    const langs = watch("languages");
    if (index < langs.length) {
      const temp = [...langs];
      temp.splice(index, 1);
      setValue("languages", temp);
    }
  };

  const handleSave = async () => {
    setLoading(true);
    const isValid = await trigger(); // returns a boolean
    if (isValid) {
      try {
        await setDoc(
          doc(firestore, COLLECTIONS.CANDIDATES, candidate?.id),
          {
            languages: watch("languages"),
          },
          { merge: true }
        );

        setCandidate({ ...candidate, languages: watch("languages") });
        handleClose();
        console.log("Language added/updated successfully");
      } catch (error) {
        console.error("Error adding/updating phone number:", error);
      }
    } else {
      console.log("Form is invalid, show error messages.");
    }
    setLoading(false);
  };

  return (
    <DialogModal
      handleClose={handleClose}
      title={t("add_edit_languages")}
      open={open}
      size={"md"}
    >
      {watch("languages").map((lng, index) => (
        <Grid
          container
          spacing={2}
          style={{
            marginTop: "20px",
            border: "1px solid #D1D1D1",
            borderRadius: "8px",
            marginBottom: "12px",
            position: "relative",
          }}
          key={lng.value}
        >
          <button
            className="ml-2 focus:outline-none absolute -top-2.5 -right-2.5 bg-white text-red-600 text-xs flex items-center justify-center border border-gray-200 rounded-full w-5 h-5"
            onClick={() => handleRemove(index)}
          >
            &#10005;
          </button>
          <Grid item lg={6} xs={12}>
            <span className="text-sm font-medium text-slate-700 mb-2 flex">
              {t("language_name")}
              <p className="text-sm text-red-500 ml-1">*</p>
            </span>

            <SelectDropdown
              options={languages}
              handleChange={(e) => setValue(`languages.${index}.name`, e.value)}
              value={lng.name}
              placeholder={t("english")}
            />

            {errors.languages?.[index]?.name?.message && (
              <p className="text-sm text-red-500">
                {errors.languages[index].name.message}
              </p>
            )}
          </Grid>
          <Grid item lg={6} xs={12}>
            <span className="text-sm font-medium text-slate-700 mb-2 flex">
              {t("proficiency")}
              <p className="text-sm text-red-500 ml-1">*</p>
            </span>
            <SelectDropdown
              options={proficiencyOptions}
              handleChange={(e) =>
                setValue(`languages.${index}.level`, e.value)
              }
              value={lng.level}
              placeholder={t("native")}
            />
            {errors.languages?.[index]?.level?.message && (
              <p className="text-sm text-red-500">
                {errors.languages[index].level.message}
              </p>
            )}
          </Grid>
        </Grid>
      ))}
      <div
        className="flex items-center gap-2 font-semibold cursor-pointer my-6 w-fit"
        onClick={handleAddClick}
      >
        <FaCirclePlus className="text-secondary-400" />
        <p className="text-sm text-secondary-400 font-normal">
          {t("add_new_language")}
        </p>
      </div>

      <div className="my-4 flex items-center gap-4">
        <SecondaryBtn text={t("cancel")} onClick={handleClose} />
        <PrimaryBtn
          text={t("save")}
          onClick={handleSave}
          loading={loading}
          disabled={loading}
        />
      </div>
    </DialogModal>
  );
}
