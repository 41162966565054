import React, { useEffect, useState } from "react";

export default function useDropdownHandler(dropDownRef) {
  const [isExpanded, setIsExpanded] = useState(null);

  const handleClickOutside = (event) => {
    if (dropDownRef.current && !dropDownRef.current?.contains(event.target)) {
      setIsExpanded(false);
    }
  };
  useEffect(() => {
    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  return {
    isExpanded,
    setIsExpanded,
  };
}
