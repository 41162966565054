import React, { useState, useRef, useEffect } from "react";
import skillList from "../Data/SkillList";
import { CiSearch } from "react-icons/ci";
import Select, { components } from "react-select";
import { useTranslation } from "react-i18next";

function SearchSkill({
  required = false,
  handleSelect,
  handleRemove,
  selectedSkills,
}) {
  const [skills, setSkills] = useState([]);
  const { t } = useTranslation();
  useEffect(() => {
    const filteredSkills = skillList.map((skill) => {
      return {
        value: skill.name,
        label: skill.name,
      };
    });

    setSkills(filteredSkills);
  }, []);

  const handleSkillRemove = (skillToRemove) => {
    handleRemove(skillToRemove);
  };

  const handleSkillSelect = (skills) => {
    const skillsArr = skills.map((s) => s.value);
    handleSelect(skillsArr);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      const inputValue = event.target.value.trim();
      if (inputValue) {
        const temp = [...selectedSkills];
        const newItem = { value: inputValue, label: inputValue };
        temp.push(newItem);
        handleSkillSelect(temp);
        event.target.value = ""; // Clear input after adding item
        event.preventDefault();
      }
    }
  };

  return (
    <div>
      <label className="block">
        <span className="text-sm font-medium text-slate-700 mb-2 flex">
          {t("skills")}
          {required && <p className="text-sm text-red-500 ml-1">*</p>}
        </span>
      </label>
      <Select
        value={selectedSkills}
        onChange={handleSkillSelect}
        options={skills}
        isMulti
        placeholder={t("search_skills")}
        controlShouldRenderValue={true}
        closeMenuOnSelect={false}
        hideSelectedOptions={false}
        components={{ Control: CustomControl }}
        onKeyDown={handleKeyDown}
        styles={{
          menu: (styles) => ({
            ...styles,
            zIndex: 999,
          }),
          multiValue: (styles, { data }) => ({
            ...styles,
            minWidth: "60px",
            background: "#EA672F1A",
            fontWeight: 600,
            borderRadius: "4px",
            border: "1px solid #EA672F",
            // padding: "4px 12px",
          }),
          multiValueLabel: (styles, { data }) => ({
            ...styles,
            color: "#EA672F",
          }),
          multiValueRemove: (styles, { data }) => ({
            ...styles,
            color: "#EA672F",
          }),
          option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isSelected
              ? "#003D501A"
              : provided.backgroundColor,
            color: state.isSelected ? "#003D50" : provided.color,
          }),
        }}
      />

      {/* <div className="flex flex-wrap mt-2 gap-4">
        {selectedSkills.map((skill) => (
          <div
            key={skill.value}
            className="bg-[#EA672F1A] text-secondary-400 text-sm font-semibold rounded-md px-3 py-2 border border-secondary-400 m-1 flex items-center relative"
          >
            {skill.value}
            <button
              className="ml-2 focus:outline-none absolute -top-2.5 -right-2.5 bg-white text-red-600 text-xs flex items-center justify-center border border-gray-200 rounded-full w-5 h-5"
              onClick={() => handleSkillRemove(skill.value)}
            >
              &#10005;
            </button>
          </div>
        ))}
      </div> */}
    </div>
  );
}

const CustomControl = ({ children, ...props }) => {
  return (
    <components.Control {...props}>
      <CiSearch className="h-5 w-5 text-gray-500 mx-3" />

      {children}
    </components.Control>
  );
};

export default SearchSkill;
