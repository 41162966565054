import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAnalytics } from "firebase/analytics";
import { getStorage } from "firebase/storage";
import { getAuth } from "firebase/auth";
import { getPerformance } from "firebase/performance";

// production;
const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID,
};

export const COLLECTIONS = {
  CANDIDATES: process.env.REACT_APP_CANDIDATES,
  COMPANIES: process.env.REACT_APP_COMPANIES,
  JOBS: process.env.REACT_APP_JOBS,
  APPLICATIONS: process.env.REACT_APP_APPLICATIONS,
  SAVEDJOBS: process.env.REACT_APP_SAVEDJOBS,
  REPORTEDJOBS: process.env.REACT_APP_REPORTEDJOBS,
  NOTIFICATIONS: process.env.REACT_APP_NOTIFICATIONS,
};
// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const firestore = getFirestore(app);
export const analytics = getAnalytics(app);
export const storage = getStorage(app);
export const auth = getAuth(app);

export const perf = getPerformance(app);
