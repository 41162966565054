import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FaCheckCircle } from "react-icons/fa";
import { FaCircleExclamation } from "react-icons/fa6";
import { BsExclamationTriangleFill } from "react-icons/bs";

export const successNotfiy = (text) => {
  toast.success(text, {
    className:
      "border-2 border-green-400 rounded-lg !bg-green-50 !overflow-visible !w-max",
    bodyClassName: "text-lg font-semibold text-black bg-green-50",
    progressClassName: "bg-green-50",
    toastClassName: "bg-green-50",
    toastId: "success",
    position: "top-center",
    autoClose: 3000,
    hideProgressBar: true,
    newestOnTop: false,
    closeOnClick: true,
    rtl: false,
    pauseOnFocusLoss: false,
    draggable: false,
    pauseOnHover: false,
    theme: "light",
    icon: <FaCheckCircle className="text-green-400" />,
    closeButton: (
      <button className="ml-2 focus:outline-none absolute -top-2.5 -right-2.5 bg-white text-red-600 text-xs flex items-center justify-center border border-gray-200 rounded-full w-5 h-5">
        &#10005;
      </button>
    ),
  });
};
export const errorNotfiy = (text) => {
  toast.error(text, {
    className:
      "border-2 border-red-400 rounded-lg !bg-red-50 !overflow-visible !w-max",
    bodyClassName: "text-lg font-semibold text-black bg-red-50",
    progressClassName: "bg-red-50",
    toastClassName: "bg-red-50",
    toastId: "error",
    position: "bottom-center",
    autoClose: 3000,
    hideProgressBar: true,
    newestOnTop: false,
    closeOnClick: true,
    rtl: false,
    pauseOnFocusLoss: false,
    draggable: false,
    pauseOnHover: false,
    theme: "light",
    icon: <FaCircleExclamation className="text-red-400" />,
    closeButton: (
      <button className="ml-2 focus:outline-none absolute -top-2.5 -right-2.5 bg-white text-red-600 text-xs flex items-center justify-center border border-gray-200 rounded-full w-5 h-5">
        &#10005;
      </button>
    ),
  });
};

export const warningNotify = (text) => {
  toast.error(text, {
    className:
      "border-2 border-secondary-400 rounded-lg !bg-secondary-50 !overflow-visible !w-max",
    bodyClassName: "text-lg font-semibold text-black bg-secondary-50",
    progressClassName: "bg-secondary-50",
    toastClassName: "bg-secondary-50",
    toastId: "warning",
    position: "bottom-center",
    autoClose: 3000,
    hideProgressBar: true,
    newestOnTop: false,
    closeOnClick: true,
    rtl: false,
    pauseOnFocusLoss: false,
    draggable: false,
    pauseOnHover: false,
    theme: "light",
    icon: <BsExclamationTriangleFill className="text-secondary-400" />,
    closeButton: (
      <button className="ml-2 focus:outline-none absolute -top-2.5 -right-2.5 bg-white text-red-600 text-xs flex items-center justify-center border border-gray-200 rounded-full w-5 h-5">
        &#10005;
      </button>
    ),
  });
};
export default function Toasts() {
  return <ToastContainer className={"text-sm"} />;
}
