import React, { useState, useEffect, lazy, Suspense } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import EditProfile from "./components/Candidate/EditProfile";
import ProfilePage from "./components/Candidate/ProfilePage";
import { ThemeProvider } from "@material-ui/core";
import theme from "./theme/theme";
import { UserProvider } from "./context/UserContext";
import {
  collection,
  getDocs,
  limit,
  orderBy,
  query,
  where,
} from "firebase/firestore";
import { COLLECTIONS, auth, firestore } from "./firebase/config";
import AuthRoute from "./AuthRoute";
import { ProtectedRoute } from "./Protected";
import Onboarding from "./components/Company/Onboarding";
import EmailVerify from "./pages/VerifyEmail";
import CandidateOnboarding from "./components/Candidate/CandidateOnboarding";
import useCandidateStore from "./store/candidate";
import useCompanyStore from "./store/company";
import Loader from "./components/Loader";
import Settings from "./pages/Settings";
import EarlyBirdPage from "./components/Company/EarlyBirdPage";
import SuccessPage from "./pages/success";
import * as Sentry from "@sentry/react";
import { initializeGA } from "./analytics";
import useAppliedJobs from "./store/appliedJobs";

const CompanyProfileUserView = lazy(() =>
  import("./components/Candidate/CompanyProfileUserView")
);
const LandingPage = lazy(() => import("./pages/LandingPage"));
const TermsandConditions = lazy(() => import("./pages/TermsAndConditions"));
const PrivacyPolicy = lazy(() => import("./pages/PrivacyPolicy"));
const LegalAdvice = lazy(() => import("./pages/LegalAdvice"));
const CookiePolicy = lazy(() => import("./pages/CookiePolicy"));
const RegisterPageRemotee = lazy(() => import("./pages/RegisterPageRemotee"));
const RegisterPageRemoter = lazy(() => import("./pages/RegisterPageRemoter"));
const LoginPage = lazy(() => import("./pages/LoginPage"));
const ForgotPasswordPage = lazy(() => import("./pages/ForgotPassword"));
const ResetPasswordPage = lazy(() => import("./pages/ResetPassword"));
const JobDetail = lazy(() => import("./pages/JobDetail"));
const CandidateDetail = lazy(() =>
  import("./components/Candidate/CandidateDetails")
);
const JobsPage = lazy(() => import("./pages/JobsPage"));
const PostJob = lazy(() => import("./pages/PostJob"));
const CompanyEditProfile = lazy(() =>
  import("./components/Company/CompanyEditProfile")
);
const CompanyProfile = lazy(() =>
  import("./components/Company/CompanyProfile")
);
const Profile = lazy(() => import("./pages/Profile"));
const Partners = lazy(() => import("./components/Partners/PartnersHome"));
const LexidyPage = lazy(() => import("./components/Partners/LexidyPage"));
const KodoPeoplePage = lazy(() =>
  import("./components/Partners/KodoPeoplePage")
);
const SafetyWingPage = lazy(() =>
  import("./components/Partners/SafetyWingPage")
);
const ResumeViewer = lazy(() => import("./pages/ResumeViewer"));
// const Notifications = lazy(() => import("./components/Company/Notifications"));
const CompanyDetails = lazy(() => import("./pages/CompanyDetails"));
const AppliedJobs = lazy(() => import("./pages/AppliedJobs"));

function App() {
  const [load, upadateLoad] = useState(true);
  const [isCompany, setIsCompany] = useState(false);
  const [loading, setLoading] = useState(true);
  const { fetchCandidate } = useCandidateStore();
  const { setCompany } = useCompanyStore();
  const setAppliedJobs = useAppliedJobs((store) => store.setAppliedJobs);

  useEffect(() => {
    const timer = setTimeout(() => {
      upadateLoad(false);
    }, 1200);

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    initializeGA();
  }, []);

  const checkCompany = async (user) => {
    const dbRefCompany = collection(firestore, COLLECTIONS.COMPANIES);
    const qC = query(
      dbRefCompany,
      orderBy("postedOn"),
      where("email", "==", user.email),
      limit(1)
    );
    const reqC = await getDocs(qC);

    if (reqC.docs.length > 0) {
      setIsCompany(true);
      setCompany({ id: reqC.docs[0].id, ...reqC?.docs[0]?.data() });
    } else {
      await fetchCandidate(user);
      setIsCompany(false);
    }
  };

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      if (user) {
        await checkCompany(user);
        fetchAppliedJobs(user);
      }
      // fetch applied jobs

      setLoading(false);
    });

    return unsubscribe;
  }, [auth]);

  const fetchAppliedJobs = async (user) => {
    const applicationSnapshot = await getDocs(
      query(
        collection(firestore, COLLECTIONS.APPLICATIONS),
        where("candidateEmail", "==", user.email)
      )
    );
    const jobIds = applicationSnapshot.docs.map((doc) => doc.data().jobId);
    setAppliedJobs(jobIds);
  };
  if (loading) {
    return <Loader />;
  }
  return (
    <div className="font-satoshi">
      <Sentry.ErrorBoundary fallback={<p>An error has occurred</p>}>
        <Suspense fallback={load}>
          <ThemeProvider theme={theme}>
            <UserProvider>
              <Router>
                <div id={load ? "preloader" : "preloader-none"} />

                <div className="App" id={load ? "no-scroll" : "scroll"}>
                  <Routes>
                    <Route
                      path="/success"
                      element={
                        // <ProtectedRoute companyRoute={true} isCompany={isCompany}>
                        <SuccessPage />
                        // </ProtectedRoute>
                      }
                    />

                    <Route path={`/`} element={<LandingPage />} />

                    <Route
                      path="/remotee/register"
                      element={
                        <AuthRoute>
                          <RegisterPageRemotee />
                        </AuthRoute>
                      }
                    />

                    <Route
                      path="/remoter/register"
                      element={
                        <AuthRoute>
                          <RegisterPageRemoter />
                        </AuthRoute>
                      }
                    />

                    <Route
                      path="/login"
                      element={
                        <AuthRoute>
                          <LoginPage />
                        </AuthRoute>
                      }
                    />

                    <Route
                      path="/forgot-password"
                      element={
                        <AuthRoute>
                          <ForgotPasswordPage />
                        </AuthRoute>
                      }
                    />

                    <Route
                      path="/password/edit"
                      element={
                        <AuthRoute>
                          <ResetPasswordPage />
                        </AuthRoute>
                      }
                    />

                    <Route path="/email/verify" element={<EmailVerify />} />

                    <Route
                      path="/earlybird"
                      element={
                        // <ProtectedRoute>
                        <EarlyBirdPage />
                        // </ProtectedRoute>
                      }
                    />

                    <Route
                      path="/jobs"
                      element={
                        // <ProtectedRoute>
                        <JobsPage />
                        // </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/applied-jobs"
                      element={
                        <ProtectedRoute userRoute={true} isCompany={isCompany}>
                          <AppliedJobs />
                        </ProtectedRoute>
                      }
                    />

                    <Route
                      path="/jobs/:jobID"
                      element={
                        // <ProtectedRoute>
                        <JobDetail />
                        // </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/applications"
                      element={
                        <ProtectedRoute
                          companyRoute={true}
                          isCompany={isCompany}
                        >
                          <CandidateDetail />
                        </ProtectedRoute>
                      }
                    />

                    <Route
                      path="/onboarding"
                      element={
                        <ProtectedRoute>
                          {isCompany ? <Onboarding /> : <CandidateOnboarding />}
                        </ProtectedRoute>
                      }
                    />

                    <Route
                      path="/settings"
                      element={
                        <ProtectedRoute>
                          <Settings />
                        </ProtectedRoute>
                      }
                    />

                    <Route
                      path="/profile"
                      element={
                        <ProtectedRoute>
                          <Profile />
                        </ProtectedRoute>
                      }
                    />

                    <Route
                      path="/post-job"
                      element={
                        <ProtectedRoute
                          companyRoute={true}
                          isCompany={isCompany}
                        >
                          <PostJob />
                        </ProtectedRoute>
                      }
                    />

                    <Route
                      path="/jobs/:jobID/edit"
                      element={
                        <ProtectedRoute
                          companyRoute={true}
                          isCompany={isCompany}
                        >
                          <PostJob />
                        </ProtectedRoute>
                      }
                    />

                    {/* <Route path="/post-job" element={<PostJob />} /> */}
                    {/* <Route path={`/jobs/:jobID/edit`} element={<PostJob />} /> */}

                    {/* <Route path="/applied-jobs" element={<AppliedJobs />} /> */}
                    {/* <Route path={`/jobs/:jobID`} element={<JobDetail />} /> */}
                    {/* <Route path={`/applications`} element={<CandidateDetail />} /> */}
                    {/* <Route path="/profile" element={<Profile />} /> */}

                    {/* <Route path={`/jobs`} element={<JobsPage />} /> */}

                    {/* <Route
                    path={`/remotee/register`}
                    element={<RegisterPageRemotee />}
                  /> */}
                    {/* <Route
                    path={`/remoter/register`}
                    element={<RegisterPageRemoter />}
                  /> */}
                    {/* <Route path={`/login`} element={<LoginPage />} /> */}
                    {/* <Route
                    path="/forgot-password"
                    element={<ForgotPasswordPage />}
                  /> */}
                    {/* <Route
                    path="/password/edit"
                    element={<ResetPasswordPage />}
                  /> */}
                    <Route
                      path="/terms-conditions"
                      element={<TermsandConditions />}
                    />
                    <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                    <Route path="/legal-advice" element={<LegalAdvice />} />
                    <Route path="/cookies-policy" element={<CookiePolicy />} />

                    <Route
                      path="/edit-profile"
                      element={<CompanyEditProfile />}
                    />
                    <Route
                      path="/company-profile"
                      element={<CompanyProfile />}
                    />
                    <Route
                      path="/company/:companyID"
                      element={<CompanyProfileUserView />}
                    />

                    <Route path="/partners" element={<Partners />} />
                    <Route path="/partners/lexidy" element={<LexidyPage />} />
                    <Route
                      path="/partners/kodopeople"
                      element={<KodoPeoplePage />}
                    />
                    <Route
                      path="/partners/safetywing"
                      element={<SafetyWingPage />}
                    />

                    {/* <Route path="/notifications" element={<Notifications />} /> */}
                    <Route
                      path={`/resume-viewer/:candidateID`}
                      element={<ResumeViewer />}
                    />
                    <Route
                      path={`/companies/:companyID`}
                      element={<CompanyDetails />}
                    />
                    <Route path="*" element={<Navigate to={`/`} />} />
                  </Routes>
                </div>
              </Router>
            </UserProvider>
          </ThemeProvider>
        </Suspense>
      </Sentry.ErrorBoundary>
    </div>
  );
}

export default App;
