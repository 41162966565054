import React from "react";
import "./styles.css";

export default function Loader({ height = "min-h-screen" }) {
  return (
    <div className={`${height} flex justify-center items-center`}>
      <div class="loader-container">
        <div class="circle circle-1"></div>
        <div class="circle circle-2"></div>
      </div>
    </div>
  );
}
