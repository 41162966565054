import React from "react";
import ReactSelect from "react-select";

export default function SelectOption({
  label,
  id,
  regProp,
  options,
  error,
  placeholder,
  required,
}) {
  return (
    <>
      <label className="block text-sm font-medium text-slate-700 mb-2">
        <span className="flex">
          {label}
          {required && <p className="text-sm text-red-500 ml-1">*</p>}
        </span>
      </label>

      <select
        id={id}
        className="shadow-sm placeholder:text-slate-400 first-letter:uppercase bg-white border border-borderGrey-400 text-gray-900 text-sm rounded-md focus:outline-none focus:border-sky-500
        focus:ring-sky-500 focus:ring-1 sm:text-sm disabled:opacity-50 disabled:bg-customGrey-100  block w-full p-2"
        {...regProp}
      >
        <option value="" disabled selected hidden>
          {placeholder}
        </option>
        {options &&
          options.map((option, i) => (
            <option value={option} className="px-2 py-3">
              {option}
            </option>
          ))}
      </select>
      <p className="text-sm text-red-500">{error}</p>
    </>
  );
}

export const SelectDropdown = ({
  options,
  placeholder,
  value,
  handleChange,
}) => {
  return (
    <ReactSelect
      placeholder={placeholder}
      value={options.find((o) => o.value === value) ?? null}
      onChange={handleChange}
      menuPortalTarget={document.body}
      options={options}
      hideSelectedOptions={true}
      styles={{
        menu: (styles) => ({
          ...styles,
          zIndex: 999,
        }),
        menuPortal: (base) => ({ ...base, zIndex: 9999 }),

        control: (styles) => ({
          ...styles,
          boxShadow: "none",
          fontSize: "14px",
        }),
        option: (provided, state) => ({
          ...provided,
          backgroundColor: state.isSelected
            ? "#003D501A"
            : provided.backgroundColor,
          color: state.isSelected ? "#003D50" : provided.color,
        }),
      }}
    />
  );
};
