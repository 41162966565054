import { yupResolver } from "@hookform/resolvers/yup";
import React, { useState, useContext } from "react";
import { useForm } from "react-hook-form";
import { FaEdit } from "react-icons/fa";
import { Link } from "react-router-dom";
import { companySchema } from "../../schemas/profile";
import TextInput from "../Global/TextInput";
import SelectOption from "../Global/SelectOption";
import { utcConstants } from "../../constants";
import { Grid } from "@material-ui/core";
import { LanguageContext } from "../../Languages/LanguageContext";
import DialogModal from "../Global/Dialog";
import countries from "../Data/Countries";
import FilledButton, { OutlinedButton } from "../Candidate/ProfileButtons";
import PrimaryBtn, { IconButton, SecondaryBtn } from "../Global/Buttons";
import { FaArrowRight } from "react-icons/fa6";
import { ReactComponent as EditIcon } from "../../Assets/pencil-square.svg";
import Location from "../Global/Location";
import industries from "../Data/Industries";
import { useTranslation } from "react-i18next";

export default function CompanyOverview(props) {
  const [editModal, setEditModal] = useState(() => props.isIncomplete ?? false);
  const { t } = useTranslation();

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
  } = useForm({
    resolver: yupResolver(companySchema),
    defaultValues: {
      companyName: "",
      tagline: "",
      website: "",
      location: "",
      industry: "",
      size: "",
      // city: "",
      // country: "",
      // timezone: "",
      // currency: "",
    },
  });
  const handleEdit = () => {
    setValue("companyName", props.companyName);
    setValue("tagline", props.tagline);
    setValue("website", props.website);
    // setValue("city", props.city);
    // setValue("country", props.country);
    setValue("location", props.location ? props.location : "");
    setValue("industry", props.industry);
    setValue("size", props.size);
    setEditModal(true);
  };

  return (
    <div className="rounded-lg border border-borderGrey-400 mt-6">
      <div className="p-4">
        <div className="flex items-center gap-4 text-md w-full justify-between">
          <h1 className="font-semibold text-xl capitalize">{t("about")}</h1>
          {!props.onlyView && (
            <div className="bg-[#EA672F1A] rounded-lg w-8 h-8 flex justify-center items-center">
              <EditIcon
                fontSize={"20"}
                color="#003D50"
                onClick={handleEdit}
                style={{
                  cursor: "pointer",
                  width: "30px",
                }}
              />
            </div>
          )}
        </div>

        <div className="py-2">
          <h1 className="font-semibold text-md ">{t("website")}</h1>
          <h1>
            {props.website ? (
              // props.website.includes("https") ? (
              <a
                href={
                  props?.website?.startsWith("http://") ||
                  props?.website?.startsWith("https://")
                    ? props?.website
                    : `https://${props?.website}`
                }
                target="_blank"
                rel="noopener noreferrer"
                className="text-[#0000FF]"
              >
                {props.website}
              </a>
            ) : (
              // ) : (
              //   props.website
              // )
              t("not_specified")
            )}
          </h1>
        </div>
        <div className="py-2">
          <h1 className="font-semibold text-md ">{t("location")}</h1>
          {/* <h1>
            {props.country
              ? props.country + " - " + props.city
              : "Not specified"}
          </h1> */}
          <h1>{props.location ? props.location : t("not_specified")}</h1>
        </div>
        {/* <div className="py-2">
          <h1 className="font-semibold text-md ">Timezone</h1>
          <h1>{props.timezone ? props.timezone : "Not specified"}</h1>
        </div>
        <div className="py-2">
          <h1 className="font-semibold text-md ">Currency</h1>
          <h1>{props.currency ? props.currency : "Not specified"}</h1>
        </div> */}
        <div className="py-2">
          <h1 className="font-semibold text-md ">{t("company_size")}</h1>
          <h1>{props.size ? props.size : t("not_specified")}</h1>
        </div>
        <div className="py-2">
          <h1 className="font-semibold text-md ">{t("industry")}</h1>
          <h1>{props.industry ? props.industry : t("not_specified")}</h1>
        </div>
      </div>
      {editModal && (
        <DialogModal
          handleClose={() => setEditModal(false)}
          title={"About"}
          open={editModal}
        >
          <EditOverview
            handleClose={() => setEditModal(false)}
            register={register}
            handleSave={props.handleSave}
            errors={errors}
            handleSubmit={handleSubmit}
            onboarding={false}
            setValue={setValue}
            watch={watch}
          />
        </DialogModal>
      )}
    </div>
  );
}

export const EditOverview = ({
  handleClose,
  handleSubmit,
  register,
  errors,
  handleSave,
  onboarding,
  setValue,
  watch,
}) => {
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const onSubmit = async (data) => {
    try {
      setLoading(true);
      await handleSave(data);
      handleClose();
    } catch (err) {
      console.log("🚀 ~ handleSave ~ err:", err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={4}>
        <Grid item lg={6} xs={12}>
          <TextInput
            label={t("companyName")}
            placeholder={"Remoto"}
            name="companyName"
            regProp={{ ...register("companyName") }}
            error={errors.companyName?.message}
            required={true}
          />
        </Grid>
        <Grid item lg={6} xs={12}>
          <TextInput
            label={t("tag")}
            placeholder={t("tagline_title")}
            name="tagline"
            regProp={{ ...register("tagline") }}
            error={errors.tagline?.message}
            required={true}
          />
        </Grid>

        <Grid item xs={6}>
          <span className="text-sm font-medium text-slate-700 flex">
            {t("location")}
            <p className="text-sm text-red-500 ml-1">*</p>
          </span>

          <Location
            onChange={(e) => setValue("location", e)}
            value={watch("location")}
          />
        </Grid>
        <Grid item lg={6} xs={12}>
          <TextInput
            label={t("website")}
            placeholder={"https://remoto.com"}
            name="website"
            regProp={{ ...register("website") }}
            error={errors.website?.message}
            required={true}
          />
        </Grid>

        {/* <Grid item lg={6} xs={12}>
          <SelectOption
            label={messages[locale].country}
            id={messages[locale].country}
            regProp={{ ...register("country") }}
            options={countries}
            error={errors.country?.message}
            placeholder={"Select country"}
          />
        </Grid>
        <Grid item lg={6} xs={12}>
          <TextInput
            label={"City"}
            placeholder={"Barcelona"}
            name="city"
            regProp={{ ...register("city") }}
            error={errors.city?.message}
            required={true}
          />
        </Grid> */}

        {/* <Grid item lg={6} xs={12}>
          <SelectOption
            label={messages[locale].timeZone}
            id={messages[locale].timeZone}
            regProp={{ ...register("timezone") }}
            options={utcConstants}
            error={errors.timezone?.message}
            placeholder={"Select timezone"}
          />
        </Grid>

        <Grid item lg={6} xs={12}>
          <SelectOption
            label={messages[locale].currency}
            id={messages[locale].currency}
            regProp={{ ...register("currency") }}
            options={["USD", "EUR", "GBP"]}
            error={errors.currency?.message}
            placeholder={"Select currency"}
          />
        </Grid> */}

        <Grid item lg={6} xs={12}>
          <SelectOption
            label={t("company_size")}
            id={"size"}
            regProp={{ ...register("size") }}
            options={["1-10, 11-25", "26-50", "+50"]}
            error={errors.size?.message}
            placeholder={"11-25"}
          />
        </Grid>

        <Grid item lg={6} xs={12}>
          <SelectOption
            label={t("industry")}
            id={"industry"}
            regProp={{ ...register("industry") }}
            options={industries}
            error={errors.industry?.message}
            placeholder={"Technology"}
            required={true}
          />
        </Grid>
      </Grid>
      {onboarding ? (
        <div className="mt-6 w-full flex justify-center">
          <IconButton
            icon={<FaArrowRight className="text-white" />}
            text={t("next_step_information")}
            iconFirst={false}
            type="submit"
          />
        </div>
      ) : (
        <div className="flex items-center gap-4 my-4">
          <SecondaryBtn text={t("cancel")} onClick={handleClose} />
          <PrimaryBtn text={t("update")} type="submit" loading={loading} />
        </div>
      )}
    </form>
  );
};
