import {
  Button,
  CircularProgress,
  makeStyles,
  useTheme,
} from "@material-ui/core";
import React from "react";

const useStyles = makeStyles((theme) => ({
  cancelButton: {
    backgroundColor: "transparent",
    color: "#EA672F",
    disableAnimation: true,
    border: "1px solid #EA672F",
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px", // Font size for mobile (screens smaller than or equal to small breakpoint)
      padding: "8px 16px",
    },
  },
}));

export default function FilledButton({ label, loading }) {
  const theme = useTheme();
  return (
    <Button
      variant="contained"
      color="primary"
      type="submit"
      disabled={loading}
      style={{ background: "#003D50" }}
    >
      {loading ? <CircularProgress size={18} color="inherit" /> : label}
    </Button>
  );
}

export function OutlinedButton({ onClick, label }) {
  const classes = useStyles();
  return (
    <>
      <Button
        variant="outlined"
        color="primary"
        className={classes.cancelButton}
        onClick={onClick}
      >
        {label}
      </Button>
    </>
  );
}
