export const utcConstants = [
  "UTC-12",
  "UTC-11",
  "UTC-10",
  "UTC-9",
  "UTC-8",
  "UTC-7",
  "UTC-6",
  "UTC-5",
  "UTC-4",
  "UTC-3",
  "UTC-2",
  "UTC-1",
  "UTC",
  "UTC+1",
  "UTC+2",
  "UTC+3",
  "UTC+4",
  "UTC+5",
  "UTC+6",
  "UTC+7",
  "UTC+8",
  "UTC+9",
  "UTC+10",
  "UTC+11",
  "UTC+12",
];

export const remoteData = [
  { label: "Remote", value: "remote" },
  { label: "Hybrid", value: "hybrid" },
  { label: "Onsite", value: "onsite" },
];
export const timezoneData = [
  { label: "(GMT-12:00) Baker Island Time", value: "UTC-12" },
  { label: "(GMT-11:00) Niue Time", value: "UTC-11" },
  { label: "(GMT-10:00) Hawaii-Aleutian Standard Time", value: "UTC-10" },
  { label: "(GMT-09:30) Marquesas Time", value: "UTC-09:30" },
  { label: "(GMT-09:00) Alaska Time", value: "UTC-9" },
  { label: "(GMT-08:00) Pacific Time", value: "UTC-8" },
  { label: "(GMT-07:00) Mountain Time", value: "UTC-7" },
  { label: "(GMT-06:00) Central Time", value: "UTC-6" },
  { label: "(GMT-05:00) Eastern Time", value: "UTC-5" },
  { label: "(GMT-04:00) Atlantic Time", value: "UTC-4" },
  { label: "(GMT-03:30) Newfoundland Time", value: "UTC-3:30" },
  { label: "(GMT-03:00) Argentina Time", value: "UTC-3" },
  { label: "(GMT-02:00) South Georgia Time", value: "UTC-2" },
  { label: "(GMT-01:00) Azores Time", value: "UTC-1" },
  { label: "(GMT+00:00) Coordinated Universal Time", value: "UTC" },
  { label: "(GMT+01:00) Central European Time", value: "UTC+1" },
  { label: "(GMT+02:00) Eastern European Time", value: "UTC+2" },
  { label: "(GMT+03:00) Moscow Standard Time", value: "UTC+3" },
  { label: "(GMT+03:30) Iran Standard Time", value: "UTC+3:30" },
  { label: "(GMT+04:00) Gulf Standard Time", value: "UTC+4" },
  { label: "(GMT+04:30) Afghanistan Time", value: "UTC+4:30" },
  { label: "(GMT+05:00) Pakistan Standard Time", value: "UTC+5" },
  { label: "(GMT+05:30) India Standard Time", value: "UTC+5:30" },
  { label: "(GMT+05:45) Nepal Time", value: "UTC+5:45" },
  { label: "(GMT+06:00) Bangladesh Standard Time", value: "UTC+6" },
  { label: "(GMT+06:30) Cocos Islands Time", value: "UTC+6:30" },
  { label: "(GMT+07:00) Indochina Time", value: "UTC+7" },
  { label: "(GMT+08:00) China Standard Time", value: "UTC+8" },
  { label: "(GMT+08:45) Central Western Time", value: "UTC+8:45" },
  { label: "(GMT+09:00) Japan Standard Time", value: "UTC+9" },
  { label: "(GMT+09:30) Australian Central Standard Time", value: "UTC+9:30" },
  { label: "(GMT+10:00) Australian Eastern Standard Time", value: "UTC+10" },
  { label: "(GMT+10:30) Lord Howe Standard Time", value: "UTC+10:30" },
  { label: "(GMT+11:00) Solomon Islands Time", value: "UTC+11" },
  { label: "(GMT+11:30) Norfolk Island Time", value: "UTC+11:30" },
  { label: "(GMT+12:00) Fiji Time", value: "UTC+12" },
  { label: "(GMT+12:45) Chatham Islands Time", value: "UTC+12:45" },
  { label: "(GMT+13:00) Tonga Time", value: "UTC+13" },
  { label: "(GMT+14:00) Line Islands Time", value: "UTC+14" },
];
