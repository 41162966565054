import React, { useEffect, useState } from "react";
import { Avatar, Button, Grid, makeStyles } from "@material-ui/core";
import ProfileAvatar from "../../Assets/profileavatar.svg";
import { CiImageOn } from "react-icons/ci";
import PrimaryBtn, { IconButton, SecondaryBtn } from "../Global/Buttons";
import DialogModal from "../Global/Dialog";
import { MdOutlineFileUpload } from "react-icons/md";
import { MdDelete } from "react-icons/md";
import { auth, COLLECTIONS, firestore, storage } from "../../firebase/config";
import {
  collection,
  getDocs,
  limit,
  orderBy,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import { ReactComponent as EditIcon } from "../../Assets/white-pencil.svg";
import useCompanyStore from "../../store/company";
import { useTranslation } from "react-i18next";

export default function ProfilePic({ src, onlyView, email, uid, isCandidate }) {
  const fileInput = React.useRef(null);
  const [imageFile, setImageFile] = useState(null);
  const [modal, setModal] = useState(null);
  const [displaySrc, setDisplaySrc] = useState(() => src ?? null);
  const [loading, setLoading] = useState(false);
  const [profile, setProfile] = useState(() => src ?? null);
  const { company, setCompany } = useCompanyStore();
  const { t } = useTranslation();
  useEffect(() => {
    setProfile(src);
  }, [modal]);

  const handleImageUpload = async () => {
    setLoading(true);
    const dbRef = collection(firestore, COLLECTIONS.COMPANIES);
    const q = query(dbRef, where("email", "==", email), limit(1));
    const req = await getDocs(q);
    const userDocRef = req.docs[0].ref;

    if (imageFile) {
      const storageRef = ref(storage, `/files/${uid}-profile`);
      const uploadTask = uploadBytesResumable(storageRef, imageFile);

      await uploadTask.on(
        "state_changed",
        (snapshot) => {},
        (err) => {},
        () => {
          //download url
          getDownloadURL(uploadTask.snapshot.ref).then((url) => {
            setDisplaySrc(url);
            setLoading(false);
            handleClose();
            updateDoc(userDocRef, {
              imageUrl: url,
            })
              .then(() => {
                setCompany({
                  ...company,
                  imageUrl: url,
                });
                console.log("image uploaded");
              })
              .catch((err) => {});
          });
        }
      );
    } else {
      setDisplaySrc(null);
      setLoading(false);
      handleClose();
      updateDoc(userDocRef, {
        imageUrl: null,
      })
        .then(() => {
          setCompany({
            ...company,
            imageUrl: null,
          });
          console.log("Update Successfull");
        })
        .catch((err) => {
          console.log("🚀 ~ err:", err);
        });
    }
  };

  const handleImageInput = (file) => {
    if (file) {
      setImageFile(file);
      setProfile(null);
    }
  };

  const handleUpload = () => {
    setModal(true);
  };
  const handleClose = () => {
    setModal(false);
  };
  const imageSrc = profile
    ? profile
    : imageFile
    ? URL.createObjectURL(imageFile)
    : null;

  const handleCandidateUpload = async () => {
    setLoading(true);

    const dbRef = collection(firestore, COLLECTIONS.CANDIDATES);
    const q = query(
      dbRef,
      orderBy("postedOn"),
      where("email", "==", email),
      limit(1)
    );
    const req = await getDocs(q);
    const userDocRef = req.docs[0].ref;

    if (imageFile) {
      const storageRef = ref(storage, `/files/${uid}`);
      const uploadTask = uploadBytesResumable(storageRef, imageFile);

      await uploadTask.on(
        "state_changed",
        (snapshot) => {},
        (err) => {},
        () => {
          //download url
          getDownloadURL(uploadTask.snapshot.ref).then((url) => {
            setDisplaySrc(url);
            setLoading(false);
            handleClose();
            updateDoc(userDocRef, {
              imageUrl: url,
            })
              .then(() => {
                setCompany({
                  ...company,
                  imageUrl: url,
                });
                console.log("image uploaded");
              })
              .catch((err) => {});
          });
        }
      );
    } else {
      setDisplaySrc(null);
      setLoading(false);
      handleClose();
      updateDoc(userDocRef, {
        imageUrl: null,
      })
        .then(() => {
          setCompany({
            ...company,
            imageUrl: null,
          });
          console.log("Update Successfull");
        })
        .catch((err) => {
          console.log("🚀 ~ err:", err);
        });
    }
  };

  return (
    <div
      className={`relative ${
        isCandidate ? "w-60 h-60" : "w-32 h-32"
      }  rounded-full bg-gray-100 border border-primary-400`}
    >
      {displaySrc ? (
        <>
          <img
            src={displaySrc || "https://via.placeholder.com/150"}
            alt="Profile"
            className="w-full h-full object-cover rounded-full"
          />
          {!onlyView && (
            <div
              className={`absolute  ${
                isCandidate ? "right-6 bottom-6" : "right-1.5 bottom-1"
              } p-1.5  bg-primary-400 rounded-full cursor-pointer`}
              onClick={handleUpload}
            >
              <EditIcon className="text-white" />
            </div>
          )}
        </>
      ) : (
        <div className="w-full h-full flex justify-center items-center">
          {!onlyView && (
            <>
              <img
                src={ProfileAvatar}
                alt="Profile"
                className="w-full h-full object-cover rounded-full"
              />
              <div
                className={`absolute
                  ${isCandidate ? "right-5 bottom-5" : "right-1.5 bottom-1"}
                  p-1 bg-primary-400 rounded-full cursor-pointer border border-borderGrey-400`}
                onClick={handleUpload}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5 text-white"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M12 4v16m8-8H4"
                  />
                </svg>
              </div>
            </>
          )}
        </div>
      )}
      <input
        type="file"
        id="file-upload"
        className="hidden"
        accept="image/*"
        onChange={(e) => handleImageInput(e.target.files[0])}
        ref={fileInput}
      />
      <DialogModal
        handleClose={handleClose}
        size={"xs"}
        title={t("profilePicture")}
        open={modal}
      >
        <div className="flex items-center justify-center w-full flex-col">
          <div className="relative w-44 h-44 rounded-full bg-gray-100 border border-primary-400 group">
            {imageSrc ? (
              <>
                <img
                  src={imageSrc}
                  alt="Profile Photo"
                  className="absolute inset-0 w-full object-fit h-full max-w-full rounded-full"
                />
                <div
                  className="absolute top-3 right-3 z-50 cursor-pointer bg-white rounded-full p-1 border border-gray-200"
                  onClick={() => {
                    setProfile(null);
                    setImageFile(null);
                  }}
                >
                  <MdDelete style={{ color: "#E22A34" }} />
                </div>

                <div
                  className="absolute rounded-full flex flex-col items-center h-full inset-0 justify-center w-full cursor-pointer bg-black bg-opacity-40 opacity-0 group-hover:opacity-100 transition-opacity"
                  onClick={() => fileInput.current.click()}
                >
                  <MdOutlineFileUpload className="text-white stroke w-8 h-8" />
                  <div className="flex items-center gap-2">
                    <h3 className="text-sm font-medium text-white">
                      {t("upload")}
                    </h3>
                  </div>
                </div>
              </>
            ) : (
              <div
                className=" flex flex-col items-center cursor-pointer h-full justify-center"
                onClick={() => fileInput.current.click()}
              >
                <MdOutlineFileUpload className="text-primary-400 stroke w-8 h-8" />
                <div className="flex items-center gap-2">
                  <h3 className="text-sm font-medium text-primary-400">
                    {t("upload")}
                  </h3>
                </div>
              </div>
            )}
          </div>
          <div className="flex items-center gap-4 my-4">
            <SecondaryBtn text={t("discard")} onClick={handleClose} />
            <PrimaryBtn
              text={t("save_changes")}
              onClick={
                isCandidate ? () => handleCandidateUpload() : handleImageUpload
              }
              loading={loading}
              disabled={loading || (imageSrc && !imageFile)}
            />
          </div>
        </div>
      </DialogModal>
    </div>
  );
}
