import React, { useState, useContext, useEffect } from "react";
import { Button, Grid, makeStyles, Typography } from "@material-ui/core";
import { LanguageContext } from "../../Languages/LanguageContext";
import "react-datepicker/dist/react-datepicker.css";
import SearchSkill from "./SearchSkills";
import FilledButton from "./ProfileButtons";
import PrimaryBtn, { SecondaryBtn } from "../Global/Buttons";
import { AiFillPlusCircle } from "react-icons/ai";
import DialogModal from "../Global/Dialog";
import skillList from "../Data/SkillList";
import { CiSearch } from "react-icons/ci";
import Select, { components } from "react-select";
import { Rating } from "@mui/material";
import { FaEdit } from "react-icons/fa";
import { collection, getDocs, updateDoc } from "firebase/firestore";
import { COLLECTIONS, firestore } from "../../firebase/config";
import { findInsights, findSkillInsight } from "../Utils/CalculateInsight";
import useCandidateStore from "../../store/candidate";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    border: "1px solid #D1D1D1",
    borderRadius: "8px",
    padding: "32px",
    marginTop: "16px",
  },
  label: {
    fontWeight: 600,
    fontSize: "14px",
    lineHeight: "19px",
    color: "#151515",
    letterSpacing: "-0.02em",
    marginLeft: "32px",
  },
  heading: {
    fontWeight: 700,
    fontSize: "28px",
    lineHeight: "120%",
    color: "#000",
    letterSpacing: "-0.02em",
  },
  calender: {
    background: "white",
    marginTop: "10px",
    border: "1px solid #ECECEC",
    borderRadius: "10px",
    width: "100%",
    padding: "16px 32px",
  },
  whiteButton: {
    backgroundColor: "transparent",
    textTransform: "unset",
    color: "#003D50",
    padding: "12px 24px",
    disableAnimation: true,
    fontSize: "16px",
    fontWeight: "700",
    width: "250px",
    marginTop: "16px",
    border: "1px solid #003D50",
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px", // Font size for mobile (screens smaller than or equal to small breakpoint)
      padding: "8px 16px",
    },
  },
  formControl: {
    background: "white",
    marginTop: "10px",
    border: "1px solid #ECECEC",
    padding: "8px 16px",
    "& .MuiSelect-root": {
      backgroundColor: "white ",
      borderBottom: "none",
    },
    "& .MuiInput-underline:before": {
      borderBottom: "none",
    },
    "& .MuiInput-underline:after": {
      borderBottom: "none",
    },
    "&:hover .MuiInput-underline:before": {
      borderBottom: "none",
    },
    "&:hover .MuiInput-underline:after": {
      borderBottom: "none",
    },
  },
  textField: {
    background: "white",
    marginTop: "10px",
    border: "1px solid #ECECEC",
    padding: "8px 16px",
    width: "100%",
    "& .MuiInputBase-input": {
      padding: "8px 16px",
      color: theme.palette.text.primary,
      fontWeight: 400,
      borderBottom: "none",
      outline: "none",
      backgroundColor: "none",
    },
  },
  skillChip: {
    margin: theme.spacing(0.5),
    fontSize: "14px",
    padding: "4px 8px",
    borderRadius: "5px",
    transition: ".3s",
    fontWeight: 400,
    marginRight: "10px",
    border: `1px solid #003D50`,
    color: "#003D50",
  },

  experienceItem: {
    padding: "16px",
    border: "1px solid #D1D1D1",
    borderRadius: "8px",
    marginTop: "32px",
  },
  title: {
    fontWeight: 600,
    fontSize: "32px",
    lineHeight: "120%",
    color: "#003D50",
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px", // Font size for mobile (screens smaller than or equal to small breakpoint)
      lineHeight: "24px",
    },
  },
  companyName: {
    fontWeight: 700,
    fontSize: "20px",
    lineHeight: "120%",
    marginTop: "4px",
    color: "#000",
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px", // Font size for mobile (screens smaller than or equal to small breakpoint)
      lineHeight: "24px",
    },
  },
  duration: {
    fontWeight: 500,
    fontSize: "16px",
    lineHeight: "120%",
    marginTop: "12px",
    color: "#868686",
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px", // Font size for mobile (screens smaller than or equal to small breakpoint)
      lineHeight: "24px",
    },
  },
  description: {
    fontWeight: 500,
    fontSize: "16px",
    lineHeight: "120%",
    marginTop: "16px",
    color: "#000",
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px", // Font size for mobile (screens smaller than or equal to small breakpoint)
      lineHeight: "24px",
    },
  },
}));

const ratingData = [
  { value: 1, label: "1/5" },
  { value: 2, label: "2/5" },
  { value: 3, label: "3/5" },
  { value: 4, label: "4/5" },
  { value: 5, label: "5/5" },
];
const SkillsTab = ({ userInfo, onSave }) => {
  const [addModal, setAddModal] = useState(false);
  const [editModal, setEditModal] = useState("");

  const [selectedSkills, setSelectedSkills] = useState([]);

  useEffect(() => {
    // const updateSkills = async () => {
    //   const candidatesCollection = collection(
    //     firestore,
    //     COLLECTIONS.CANDIDATES
    //   );
    //   const snapshot = await getDocs(candidatesCollection);
    //   snapshot.forEach((doc) => {
    //     const skillsString = doc.data().skills;
    //     if (skillsString && typeof skillsString === "string") {
    //       const skillsArray = skillsString.split(",").map((skill) => ({
    //         name: skill.trim(),
    //         rating: null,
    //       }));
    //       console.log("🚀 ~ skillsArray ~ skillsArray:", skillsArray);
    //       // updateDoc(doc.ref, { skills: skillsArray });
    //     }
    //   });
    // };
    // updateSkills();

    if (userInfo?.skills) {
      setSelectedSkills(userInfo?.skills);
    }
  }, [userInfo]);

  const [error, setError] = useState("");

  const handleSelect = (skills) => {
    setError("");
    setSelectedSkills(skills);
  };
  const handleRemove = (skillToRemove) => {
    setError("");
    setSelectedSkills(
      selectedSkills.filter((skill) => skill !== skillToRemove)
    );
  };

  const handleAddSkill = () => {
    setAddModal(true);
  };

  const handleEditSkill = (skill) => {
    setEditModal(skill);
  };

  return (
    <>
      <Grid>
        <div className="mt-8 rounded-lg border border-borderGrey-400 p-6">
          <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-4">
            {selectedSkills.map((skill) => {
              return (
                <div className="relative rounded-lg h-16 border border-borderGrey-400 p-4 flex items-center ">
                  <div className="absolute cursor-pointer bg-white -top-3 -right-3 rounded-full border border-borderGrey-400 w-6 h-6 flex justify-center items-center">
                    <FaEdit
                      onClick={() => handleEditSkill(skill)}
                      className="text-secondary-400 text-xs"
                    />
                  </div>
                  <div className="flex items-center gap-1">
                    <div className="rounded-full w-10 h-10 bg-primary-400  flex justify-center items-center">
                      <h1 className="text-white font-bold text-lg capitalize">
                        {skill?.name[0]}
                      </h1>
                    </div>
                    <div className="flex flex-col">
                      <h1 className="text-xl font-medium">{skill?.name}</h1>
                      {skill?.rating ? (
                        <Rating
                          name="raitng"
                          readOnly
                          value={skill.rating}
                          style={{ fontSize: "10px" }}
                        />
                      ) : (
                        <p
                          className="text-[8px] font-semibold text-primary-400 cursor-pointer inline"
                          onClick={() => handleEditSkill(skill)}
                        >
                          RATE SKILL
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              );
            })}
            <div
              className="w-full relative h-16 border-2 border-dashed border-borderGrey-400 rounded-md flex items-center justify-center cursor-pointer"
              onClick={handleAddSkill}
            >
              <div className=" flex  flex-col items-center cursor-pointer">
                <div className="flex items-center gap-2">
                  <AiFillPlusCircle className="text-primary-400" />
                  <p className="text-sm font-semibold text-primary-400">
                    Add new skill
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="mt-2">
          <SearchSkill
            selectedSkills={selectedSkills.map((s) => {
              return { value: s, label: s };
            })}
            handleSelect={handleSelect}
            handleRemove={handleRemove}
          />
        </div> */}
        {/* <p className="text-sm text-red-500">{error}</p>

        <div className="mt-4 flex gap-4">
          <SecondaryBtn
            text={"Revert changes"}
            onClick={() => setSelectedSkills(userInfo?.skills?.split(","))}
            disabled={!isSkillsChanged()}
          />
          <PrimaryBtn
            text={"Save changes"}
            loading={loading}
            onClick={handleSave}
            disabled={!isSkillsChanged()}
          />
        </div> */}
        {addModal && (
          <DialogModal
            handleClose={() => setAddModal(false)}
            title="Add Skill"
            open={addModal}
          >
            <AddSkill
              selectedSkill={null}
              userInfo={userInfo}
              onSave={onSave}
              handleClose={() => setAddModal(false)}
            />
            <p className="text-sm text-red-500">{error}</p>
          </DialogModal>
        )}
        {!!editModal && (
          <DialogModal
            handleClose={() => setEditModal("")}
            title="Edit Skill"
            open={!!editModal}
          >
            <AddSkill
              selectedSkill={editModal}
              userInfo={userInfo}
              onSave={onSave}
              handleClose={() => setEditModal("")}
            />
            <p className="text-sm text-red-500">{error}</p>
          </DialogModal>
        )}
      </Grid>
    </>
  );
};

function AddSkill({
  required = true,
  userInfo,
  onSave,
  selectedSkill,
  handleClose,
}) {
  const [selected, setSelected] = useState(null);
  const [skills, setSkills] = useState([]);
  const [loading, setLoading] = useState(false);
  const { candidate } = useCandidateStore();
  const { t } = useTranslation();

  useEffect(() => {
    if (selectedSkill) {
      const output = convertSkill();
      console.log("🚀 ~ useEffect ~ output:", output);
      setSelected(output);
    }
  }, [selectedSkill]);

  const convertSkill = () => {
    const outputObj = {};

    for (const key in selectedSkill) {
      if (selectedSkill.hasOwnProperty(key)) {
        const value = selectedSkill[key];
        if (value) {
          outputObj[key] = {
            value: value,
            label: value !== null ? String(value) : null,
          };
        } else {
          outputObj[key] = null;
        }
      }
    }
    return outputObj;
  };

  useEffect(() => {
    const filteredSkills = skillList.map((skill) => {
      return {
        value: skill.name,
        label: skill.name,
      };
    });

    setSkills(filteredSkills);
  }, []);

  const handleChange = (name, e) => {
    setSelected({
      ...selected,
      [name]: e,
    });
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      const inputValue = event.target.value.trim();
      if (inputValue) {
        const temp = { ...selected };
        const newItem = { value: inputValue, label: inputValue };
        temp.name = newItem;
        setSelected(temp);
        event.target.value = ""; // Clear input after adding item
        event.preventDefault();
      }
    }
  };

  const handleSave = async () => {
    setLoading(true);
    try {
      if (!selected) {
        console.log(selected);
        return;
      }
      const aiInsights = await findInsights(
        selected.name.value,
        selected.rating.value
      );
      const skillInsights = await findSkillInsight(
        selected.name.value,
        candidate
      );

      const body = {
        name: selected.name.value,
        rating: selected.rating.value,
        aiInsights,
        skillInsights,
      };

      let skills = userInfo?.skills?.length > 0 ? [...userInfo.skills] : [];
      if (skills.some((s) => s?.name === body?.name)) {
        const index = skills.findIndex((obj) => obj.name === body.name);
        skills[index] = body;
      } else {
        skills.push(body);
      }
      const updatedInfo = { ...userInfo, skills };
      await onSave(updatedInfo);
      handleClose();
      setSelected(null);
    } catch (err) {
      console.log("🚀 ~ handleSave ~ err:", err);
    } finally {
      setLoading(false);
    }
  };

  const deleteSKill = async () => {
    try {
      if (!selected) {
        console.log(selected);
        return;
      }
      const body = {
        name: selected.name.value,
        rating: selected.rating.value,
      };

      let skills = userInfo?.skills?.length > 0 ? [...userInfo.skills] : [];
      if (skills.some((s) => s?.name === body?.name)) {
        const index = skills.findIndex((obj) => obj.name === body.name);
        skills.splice(index, 1);
      }
      const updatedInfo = { ...userInfo, skills };
      await onSave(updatedInfo);
      handleClose();
      setSelected(null);
    } catch (err) {
      console.log("🚀 ~ handleSave ~ err:", err);
    } finally {
      setLoading(false);
    }
  };
  const isDisabled = () => {
    if (!selected?.name || !selected?.rating) {
      return true;
    }

    return (
      selected?.name === selectedSkill?.name &&
      selected?.rating === selectedSkill?.rating
    );
  };

  const handleRevert = () => {
    if (selectedSkill) {
      const output = convertSkill();
      setSelected(output);
    } else {
      setSelected(selectedSkill);
    }
  };

  return (
    <div>
      <div>
        <label className="block">
          <span className="text-sm font-medium text-slate-700 mb-2 flex">
            {t("skills")}
            {required && <p className="text-sm text-red-500 ml-1">*</p>}
          </span>
        </label>
        <Select
          value={selected?.name}
          onChange={(e) => handleChange("name", e)}
          options={skills}
          placeholder={t("search_skills")}
          components={{ Control: CustomControl }}
          isClearable
          isDisabled={!!selectedSkill}
          menuPortalTarget={document.body}
          onKeyDown={handleKeyDown}
          styles={{
            menu: (styles) => ({
              ...styles,
              zIndex: 999,
            }),
            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
            control: (styles, state) => ({
              ...styles,
              boxShadow: "none",
            }),
            option: (provided, state) => ({
              ...provided,
              backgroundColor: state.isSelected
                ? "#003D501A"
                : provided.backgroundColor,
              color: state.isSelected ? "#003D50" : provided.color,
            }),
          }}
        />
      </div>
      <div className="mt-4">
        <label className="block">
          <span className="text-sm font-medium text-slate-700 mb-2 flex">
            {t("i_rate_myself_as")}
            {required && <p className="text-sm text-red-500 ml-1">*</p>}
          </span>
        </label>
        <Select
          value={selected?.rating}
          onChange={(e) => handleChange("rating", e)}
          options={ratingData}
          placeholder={t("rate_yourself")}
          components={{
            Control: (props) => <CustomControl {...props} hideIcon={true} />,
          }}
          menuPortalTarget={document.body}
          onKeyDown={handleKeyDown}
          styles={{
            menu: (styles) => ({
              ...styles,
              zIndex: 999,
            }),
            control: (styles, state) => ({
              ...styles,
              boxShadow: "none",
            }),
            option: (provided, state) => ({
              ...provided,
              backgroundColor: state.isSelected
                ? "#003D501A"
                : provided.backgroundColor,
              color: state.isSelected ? "#003D50" : provided.color,
            }),
            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
          }}
        />
      </div>
      <div className="my-5 flex justify-between">
        <div className="flex gap-4">
          <SecondaryBtn
            text={t("revert_changes")}
            onClick={handleRevert}
            disabled={isDisabled()}
          />
          <PrimaryBtn
            text={t("save_changes")}
            loading={loading}
            onClick={handleSave}
            disabled={isDisabled()}
          />
        </div>
        {!!selectedSkill && (
          <div>
            <button
              type="button"
              onClick={deleteSKill}
              className="bg-transparent text-red-400 font-semibold border-2 border-red-400  rounded-md px-3 py-2
        hover:bg-red-400 hover:text-white transition-all"
            >
              {t("delete_skill")}
            </button>
          </div>
        )}
      </div>
    </div>
  );
}

const CustomControl = ({ children, hideIcon, ...props }) => {
  return (
    <components.Control {...props}>
      {!hideIcon && <CiSearch className="h-5 w-5 text-gray-500 mx-3" />}

      {children}
    </components.Control>
  );
};

export default SkillsTab;
