import React, { useState, useEffect, useContext, useRef } from "react";
import { Tab, Tabs, Grid, makeStyles, Box } from "@material-ui/core";
import { firestore, COLLECTIONS } from "../../firebase/config";
import {
  collection,
  serverTimestamp,
  query,
  orderBy,
  where,
  limit,
  getDocs,
  updateDoc,
} from "firebase/firestore";
import CandidateSidebar from "../Sidebars/CandidateSidebar";
import ProfileHeader from "../Header/ProfileHeader";
import GeneralTab from "./GeneralTab";
import ResumeTab from "./ResumeTab";
import ExperienceTab from "./ExperienceTab";
import EducationTab from "./EducationTab";
import Progress from "./Progress";
import SkillsTab from "./SkillsTab";
// import EducationTab from "./EducationTab";
// import firebase from "firebase/app";
// import "firebase/firestore";
import { TiTick } from "react-icons/ti";
import { UserContext } from "../../context/UserContext";
import useCandidateStore from "../../store/candidate";
import Loader from "../Loader";
import { useTranslation } from "react-i18next";

const progressVals = {
  General: 20,
  Resume: 40,
  Skills: 60,
  Experience: 80,
  Education: 100,
};

const useStyles = makeStyles((theme) => ({
  title: {
    color: "#000000",
    fontSize: "48px",

    fontWeight: 700,
    lineHeight: "120%" /* 57.6px */,
    letterSpacing: "-0.288px",
    textAlign: "left",
    [theme.breakpoints.down("sm")]: {
      fontSize: "24px",
      marginTop: "24px",
      marginBottom: "24px",
    },
  },
  tabs: {
    color: "#000",
    "& .MuiTabs-indicator": {
      display: "none",
    },
  },

  tabComplete: {
    // styles for each tab
    transition: "background-color 0.2s",
    "&.MuiTab-root": {
      minHeight: "42px",
    },
    "&.Mui-selected": {
      // styles for the selected tab
      backgroundColor: "#2CBA99",
      color: "#fff",
      borderRadius: "8px",
    },
  },
  tabIncomplete: {
    transition: "background-color 0.2s",
    "&.MuiTab-root": {
      minHeight: "42px",
    },
    "&.Mui-selected": {
      // styles for the selected tab
      backgroundColor: "#E22A34",
      color: "#fff",
      borderRadius: "8px",
    },
  },
}));
const ProfilePage = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [currentTab, setCurrentTab] = useState("");
  // const [user, setUser] = useState("");
  const { user } = useContext(UserContext);
  const { candidate: userInfo, fetchCandidate, loading } = useCandidateStore();
  const handleTabChange = (event, newTab) => {
    setCurrentTab(newTab);
  };
  const employmentRefs = useRef([]);
  const educationRefs = useRef([]);

  const handleUserInit = async (user) => {
    // setUser(user);
    setCurrentTab("General");
  };
  const handleSave = async (data) => {
    console.log("🚀 ~ handleSave ~ data:", data);
    // update isComplete based on this data
    data.isComplete = false;
    if (
      data?.firstName &&
      data?.lastName &&
      data?.dateOfBirth &&
      data?.location &&
      data?.timezone &&
      data?.currency &&
      data?.minSalaryExpectancy &&
      data?.aboutMe &&
      data?.education?.length > 0 &&
      data?.employment?.length > 0 &&
      data?.resumeUrl &&
      data?.skills
    ) {
      data.isComplete = true;
    }

    // const userId = firebase.auth().currentUser.uid;
    // const userRef = firebase.firestore().collection("candidates").doc(userId);
    // userRef
    //   .update(data)
    //   .then(() => {
    //     console.log("Profile updated successfully");
    //   })
    //   .catch((error) => {
    //     console.error("Error updating profile:", error);
    //   });
    const dbRef = collection(firestore, COLLECTIONS.CANDIDATES);
    const q = query(
      dbRef,
      orderBy("postedOn"),
      where("email", "==", user.email),
      limit(1)
    );
    const req = await getDocs(q);
    const userDocRef = req.docs[0].ref;

    await updateDoc(userDocRef, {
      ...data,
      postedOn: serverTimestamp(),
    })
      .then(async () => {
        // refetch user
        fetchCandidate(user);

        console.log("Update Successfull");
      })
      .catch((err) => {
        console.log("🚀 ~ handleSave ~ err:", err);
      });
  };

  const profileError =
    userInfo &&
    (!userInfo?.title ||
      !userInfo?.firstName ||
      !userInfo?.lastName ||
      !userInfo?.dateOfBirth ||
      // !userInfo?.country ||
      // !userInfo?.city ||
      !userInfo?.location ||
      !userInfo?.timezone ||
      !userInfo?.currency ||
      !userInfo?.minSalaryExpectancy ||
      !userInfo?.aboutMe ||
      !userInfo?.resumeUrl)
      ? true
      : false;

  // useEffect(
  //   async () => {
  //     const retrieveUser = async () => {
  //       const unsubscribe = auth.onAuthStateChanged((user) => {
  //         if (user) {
  //           setUser(user);
  //           //checkCompany(user);
  //           handleUserInit(user);
  //         } else {
  //           setUser(null);
  //         }
  //         //setLoading(false);
  //       });

  //       return unsubscribe;
  //     };

  //     const unsubscribe = retrieveUser();
  //     return () => unsubscribe();
  //   },
  //   [],
  //   user
  // );
  useEffect(() => {
    if (user) {
      handleUserInit(user);
    }
  }, [user]);

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          <Grid style={{ background: "#E9E4EE" }}>
            <ProfileHeader />
          </Grid>
          <Grid container>
            <Grid item xs={1}>
              <CandidateSidebar component="profile" />
            </Grid>
            <Grid item xs={11} style={{ padding: "32px" }}>
              {!userInfo?.isComplete && (
                <Progress
                  value={progressVals[currentTab]}
                  userInfo={userInfo}
                />
              )}

              <Tabs
                value={currentTab}
                onChange={handleTabChange}
                variant="scrollable"
                scrollButtons="auto"
                className={classes.tabs}
              >
                <Tab
                  label={
                    <div className="flex justify-center items-center gap-1">
                      <h1>{t("general")}</h1>
                      {profileError ? (
                        <Error active={currentTab === "General"} />
                      ) : (
                        <Success active={currentTab === "General"} />
                      )}
                    </div>
                  }
                  value="General"
                  className={
                    profileError ? classes.tabIncomplete : classes.tabComplete
                  }
                />
                {/* <Tab
                  label={
                    <div className="flex justify-center items-center gap-1">
                      <h1>Resume</h1>
                      {userInfo && !userInfo?.resumeUrl ? (
                        <Error active={currentTab === "Resume"} />
                      ) : (
                        <Success active={currentTab === "Resume"} />
                      )}
                    </div>
                  }
                  value="Resume"
                  className={
                    userInfo && !userInfo?.resumeUrl
                      ? classes.tabIncomplete
                      : classes.tabComplete
                  }
                /> */}
                <Tab
                  label={
                    <div className="flex justify-center items-center gap-1">
                      <h1>{t("skills")}</h1>
                      {userInfo && userInfo?.skills?.length > 0 ? (
                        <Success active={currentTab === "Skills"} />
                      ) : (
                        <Error active={currentTab === "Skills"} />
                      )}
                    </div>
                  }
                  value="Skills"
                  className={
                    userInfo && userInfo?.skills?.length > 0
                      ? classes.tabComplete
                      : classes.tabIncomplete
                  }
                />
                <Tab
                  label={
                    <div className="flex justify-center items-center gap-1">
                      <h1>{t("experience")}</h1>
                      {userInfo && userInfo?.employment?.length > 0 ? (
                        <Success active={currentTab === "Experience"} />
                      ) : (
                        <Error active={currentTab === "Experience"} />
                      )}
                    </div>
                  }
                  value="Experience"
                  className={
                    userInfo && userInfo?.employment?.length > 0
                      ? classes.tabComplete
                      : classes.tabIncomplete
                  }
                />
                <Tab
                  label={
                    <div className="flex justify-center items-center gap-1">
                      <h1>{t("education")}</h1>
                      {userInfo && userInfo?.education?.length > 0 ? (
                        <Success active={currentTab === "Education"} />
                      ) : (
                        <Error active={currentTab === "Education"} />
                      )}
                    </div>
                  }
                  value="Education"
                  className={
                    userInfo && userInfo?.education?.length > 0
                      ? classes.tabComplete
                      : classes.tabIncomplete
                  }
                />
              </Tabs>
              {/* {currentTab === "Resume" && (
                <ResumeTab onSave={handleSave} userInfo={userInfo} />
              )} */}
              {currentTab === "General" && (
                <GeneralTab onSave={handleSave} userInfo={userInfo} />
              )}
              {currentTab === "Experience" && (
                <ExperienceTab
                  onSave={handleSave}
                  userInfo={userInfo}
                  employmentRefs={employmentRefs}
                />
              )}
              {currentTab === "Education" && (
                <EducationTab
                  onSave={handleSave}
                  userInfo={userInfo}
                  educationRefs={educationRefs}
                />
              )}
              {currentTab === "Skills" && (
                <SkillsTab onSave={handleSave} userInfo={userInfo} />
              )}
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
};

const Error = ({ active }) => {
  return (
    <div
      className={`w-5 h-5 rounded-full flex justify-center items-center ${
        active ? "bg-white text-[#E22A34]" : "bg-[#E22A34] text-white"
      }`}
    >
      !
    </div>
  );
};

const Success = ({ active }) => {
  return (
    <div
      className={`w-5 h-5 rounded-full ${
        active ? "bg-white text-[#2CBA99]" : "bg-[#2CBA99] text-white"
      } text-xs flex justify-center items-center`}
    >
      <TiTick />
    </div>
  );
};
export default ProfilePage;
