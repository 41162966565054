import { create } from "zustand";
import {
  collection,
  query,
  orderBy,
  where,
  limit,
  getDocs,
} from "firebase/firestore";
import { COLLECTIONS, firestore } from "../firebase/config";

const useCandidateStore = create((set) => ({
  candidate: null,
  loading: false,
  error: null,
  setCandidate: (candidate) => {
    set({ candidate });
  },
  fetchCandidate: async (user) => {
    set({ loading: true, error: null });
    const dbRef = collection(firestore, COLLECTIONS.CANDIDATES);

    try {
      const q = query(
        dbRef,
        orderBy("postedOn"),
        where("email", "==", user.email),
        limit(1)
      );
      const req = await getDocs(q);

      const userDocData = req.docs[0].data();
      if (userDocData?.dateOfBirth) {
        userDocData.dateOfBirth = userDocData?.dateOfBirth.toDate();
      }
      if (userDocData.education) {
        const employmentItemsWithDates = userDocData.education.map((item) => {
          let startDate = null;
          let endDate = null;

          if (item && item.startDate && item.startDate.toDate) {
            startDate = item.startDate.toDate();
          }

          if (item && item.endDate && item.endDate.toDate) {
            endDate = item.endDate.toDate();
          }
          return {
            ...item,
            startDate,
            endDate,
          };
        });

        userDocData.education = employmentItemsWithDates;
      }
      if (userDocData.employment) {
        const employmentItemsWithDates = userDocData.employment.map((item) => {
          let startDate = null;
          let endDate = null;

          if (item && item.startDate && item.startDate.toDate) {
            startDate = item.startDate.toDate();
          }

          if (item && item.endDate && item.endDate.toDate) {
            endDate = item.endDate.toDate();
          }
          return {
            ...item,
            startDate,
            endDate,
          };
        });

        userDocData.employment = employmentItemsWithDates;
      }

      set({
        candidate: { id: req.docs[0].id, ...userDocData },
        loading: false,
      });
    } catch (error) {
      set({ error: "Failed to fetch user details", loading: false });
    }
  },
}));

export default useCandidateStore;
