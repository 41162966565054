import React, { useContext } from "react";
import DialogModal from "./Dialog";
import PrimaryBtn from "./Buttons";
import { UserContext } from "../../context/UserContext";
import { sendEmailVerification } from "firebase/auth";
import { toast } from "react-toastify";
import { errorNotfiy, successNotfiy } from "./Toasts";

export default function VerifyEmailPopup({
  handleClose,
  open,
  message = " Verify your email to apply for jobs!",
}) {
  const { user } = useContext(UserContext);

  const sendEmail = () => {
    sendEmailVerification(user)
      .then((res) => {
        successNotfiy("Verification email sent successfully");
        handleClose();
      })
      .catch((err) => {
        console.log("🚀 ~ sendEmail ~ err:", err);
        errorNotfiy("Something went wrong while sending email");
        handleClose();
      });
  };
  return (
    <DialogModal
      handleClose={handleClose}
      open={open}
      title="Email Verification"
      size="sm"
    >
      <h1 className="text-lg font-medium mx-2">{message}</h1>
      <div className="flex items-center gap-4 my-4 mx-2">
        <PrimaryBtn text={"Verify Now"} onClick={sendEmail} />
      </div>
    </DialogModal>
  );
}
