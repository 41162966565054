import React, { useState, useEffect, useContext } from "react";
import { LanguageContext } from "../../Languages/LanguageContext";
import { IconButton, Grid, makeStyles } from "@material-ui/core";
import { MoreVert as MenuIcon } from "@material-ui/icons";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { auth } from "../../firebase/config";
import { signOut } from "firebase/auth";
import RemotocoLogo from "../../Assets/remotologo.svg";
import LanguageChange from "../../Languages/LanguageChange";

import theme from "../../theme/theme";
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles(() => ({
  link: {
    textDecoration: "none",
    color: "black",
    fontSize: "30px",
    textAlign: "left",
  },
  companyIcon: {
    width: "150px",
    height: "45px",
    [theme.breakpoints.down("sm")]: {
      width: "100px",
      height: "32px",
    },
  },
  canvas: {
    background: "#E9E4EE",
    paddingBottom: "20px",
    paddingTop: "20px",
  },
  icon: {
    color: theme.palette.primary.main,
  },
}));

function ComapanyDrawer() {
  const classes = useStyles();
  const { locale, messages } = useContext(LanguageContext);
  const [anchorEl, setAnchorEl] = useState(null);
  const [user, setUser] = useState(null);
  const navigate = useNavigate();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSignOut = () => {
    signOut(auth)
      .then(() => {
        // Sign-out successful.
        navigate("/login");
      })
      .catch((error) => {});
  };

  const handleLogoClick = () => {
    if (user) {
      window.location.href = `/profile`;
    } else {
      window.location.href = `/${locale}`;
    }
  };

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        setUser(user);
      } else {
        setUser(null);
      }
    });

    return unsubscribe;
  }, []);

  return (
    <>
      <Grid container className={classes.canvas} justifyContent="center">
        <Grid container item xs={10} justifyContent="space-between">
          {/************** Logo  ***********/}
          <Grid
            container
            item
            xs={4}
            className={classes.logo}
            onClick={handleLogoClick}
          >
            <img
              src={RemotocoLogo}
              className={classes.companyIcon}
              alt="remoto logo"
            />
          </Grid>
          <Grid
            container
            item
            xs={8}
            justifyContent="flex-end"
            alignItems="center"
          >
            {/************** Language Change  ***********/}
            <Grid item>
              <LanguageChange />
            </Grid>
            {/************** Drop down  ***********/}
            <Grid item>
              <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                className={classes.menu}
                PaperProps={{
                  style: {
                    marginTop: "60px",
                    borderRadius: "25px",
                    padding: "8px 24px",
                    border: "1px solid #E9E4EE",
                  },
                }}
                transformOrigin={{ horizontal: "right", vertical: "top" }}
                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
              >
                <MenuItem onClick={() => (window.location.href = "/profile")}>
                  {messages[locale].profile}
                </MenuItem>
                <hr
                  color="#ECECEC"
                  style={{ marginTop: "10px", marginBottom: "10px" }}
                />
                <MenuItem onClick={() => (window.location.href = "/jobs")}>
                  {messages[locale].jobs}
                </MenuItem>
                <hr
                  color="#ECECEC"
                  style={{ marginTop: "10px", marginBottom: "10px" }}
                />
                <MenuItem onClick={() => (window.location.href = "/partners")}>
                  {messages[locale].partners}
                </MenuItem>
                <hr
                  color="#ECECEC"
                  style={{ marginTop: "10px", marginBottom: "10px" }}
                />
                <MenuItem onClick={handleSignOut}>
                  {messages[locale].logout}
                </MenuItem>
              </Menu>
            </Grid>
            <IconButton className={classes.icon} onClick={handleClick}>
              <MenuIcon />
            </IconButton>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
export default ComapanyDrawer;
