import React, { createContext, useEffect, useState } from "react";

import enTranslation from "./en.json";
import espTranslation from "./esp.json";
import ptTranslation from "./pt.json";

export const LanguageContext = createContext();

export const LanguageProvider = ({ children }) => {
  // Retrieve the selected language from localStorage or use the default
  const [locale, setLocale] = useState(
    localStorage.getItem("selectedLanguage") || "esp"
  );

  const messages = {
    en: enTranslation,
    esp: espTranslation,
    pt: ptTranslation,
  };

  // Update localStorage when the selected language changes
  useEffect(() => {
    localStorage.setItem("selectedLanguage", locale);
  }, [locale]);

  const changeLanguage = (newLocale) => {
    setLocale(newLocale);
  };

  return (
    <LanguageContext.Provider value={{ locale, messages, changeLanguage }}>
      {children}
    </LanguageContext.Provider>
  );
};
