import React, { useState, useEffect } from "react";
import pdfToText from "react-pdftotext";
import { DOMParser } from "@xmldom/xmldom";
import PizZip from "pizzip";
import axios from "axios";

export const readFile = (file) => {
  if (file) {
    const extension = file.name.split(".").pop().toLowerCase();

    switch (extension) {
      case "docx":
        readDocxFile(file);
        break;

      case "pdf":
        const text = readtPdfText(file);
        return text;
        break;

      case "txt":
        readTextFile(file);
        break;

      default:
        console.error("Unsupported file type, docx and txt allowed");
        break;
    }
  }
};

const readtPdfText = async (file) => {
  if (!file) {
    throw "Error";
  }

  const result = pdfToText(file)
    .then((text) => {
      return text;
    })
    .catch((error) => console.error("Failed to extract text from pdf", error));
  return result;
};

const readDocxFile = async (file) => {
  const reader = new FileReader();

  reader.onload = async (e) => {
    const content = e.target.result;
    const paragraphs = getParagraphs(content);
    const text = paragraphs.join("\n");
    return text;
  };

  reader.onerror = (err) => console.error(err);
  reader.readAsBinaryString(file);
};

function readTextFile(file) {
  if (file) {
    const fileReader = new FileReader();
    fileReader.readAsText(file, "UTF-8");
    fileReader.onload = async (e) => {
      if (e?.target?.result) {
        return e?.target?.result;
      }
    };
  }
}

function str2xml(str) {
  if (str.charCodeAt(0) === 65279) {
    str = str.substr(1);
  }
  return new DOMParser().parseFromString(str, "text/xml");
}

function getParagraphs(content) {
  const zip = new PizZip(content);
  const xml = str2xml(zip.files["word/document.xml"].asText());
  const paragraphsXml = xml.getElementsByTagName("w:p");
  const paragraphs = [];

  for (let i = 0, len = paragraphsXml.length; i < len; i++) {
    let fullText = "";
    const textsXml = paragraphsXml[i].getElementsByTagName("w:t");
    for (let j = 0, len2 = textsXml.length; j < len2; j++) {
      const textXml = textsXml[j];
      if (textXml.childNodes) {
        fullText += textXml.childNodes[0].nodeValue;
      }
    }
    if (fullText) {
      paragraphs.push(fullText);
    }
  }
  return paragraphs;
}

export const parseDataUsingOpenAI = async (text) => {
  const apiKey = "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";
  const url = `https://api.openai.com/v1/chat/completions`;

  const messages = [
    {
      role: "system",
      content: `Extract detailed structured information from the following resume text and return it in the specified JSON schema. Each field should be populated with data extracted from the text where applicable.

### JSON Schema:
{
  "title": "",
  "firstName": "",
  "lastName": "",
  "dateOfBirth": "YYYY-MM-DD",
  "location": "",
  "aboutMe": "",
  "linkedInUrl": "",
  "portfolioUrl": "",
  "employment": [
    {
      "jobTitle": "",
      "company": "",
      "industry": "",
      "jobType": "",
      "location": "",
      "startDate": "YYYY-MM-DD",
      "endDate": "YYYY-MM-DD",
      "duties": "",
      "chipSkills": [],
      "isCurrentlyWorking": false
    }
  ],
  "education":[
    {
      "educationLevel": "",
      "degree": "",
      "institute": "",
      "major": "",
      "startDate": "YYYY-MM-DD",
      "endDate": "YYYY-MM-DD",
      "isCurrentlyWorking": false
    }
  ]
}

### Resume Text:
{resume_text}

### Instructions:
- **title**: Extract from the resume if available, otherwise leave blank.
- **firstName**: Extract from the resume.
- **lastName**: Extract from the resume.
- **dateOfBirth**: Extract if available, format as "YYYY-MM-DD".
- **location**: Extract current location.
- **aboutMe**: Extract the summary or profile section.
- **linkedInUrl**: Extract LinkedIn URL if available.
- **portfolioUrl**: Extract portfolio URL if available.
- **employment**: 
  - Extract job-related education details such as job title, company, etc.
  - **jobTitle**: Title of the job.
  - **company**: Name of the company.
  - **industry**: Industry related to the job.
  - **jobType**: Type of job (e.g., Full-time, Part-time).
  - **location**: Location where the job is located.
  - **startDate**: Start date of the job in "YYYY-MM-DD".
  - **endDate**: End date of the job in "YYYY-MM-DD", assign to current date if currently working or not found.
  - **duties**: Main duties performed in the job.
  - **chipSkills**: Relevant skills related to the job, if mentioned.
  - **isCurrentlyWorking**: Set to true if the person is currently working in the job.
- **education**:
  - Extract educational background such as level of education, degree, etc.
  - **educationLevel**: Level of education (e.g., Bachelor's, Master's).
  - **degree**: Degree obtained.
  - **institute**: Name of the educational institute.
  - **major**: Major or field of study.
  - **startDate**: Start date of education in "YYYY-MM-DD".
  - **endDate**: End date of education in "YYYY-MM-DD".
  - **isCurrentlyWorking**: Set to true if the person is currently studying.

The response should be in JSON format matching the schema above.
`,
    },
    {
      role: "user",
      content: text,
    },
  ];

  const data = {
    messages: messages,
    model: "gpt-3.5-turbo",
    temperature: 0.7,
    max_tokens: 1000,
    response_format: { type: "json_object" },
  };
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${apiKey}`,
  };

  try {
    const response = await axios.post(url, data, { headers });
    console.log("🚀 ~ parseDataUsingOpenAI ~ response:", response);
    const completion = JSON.parse(
      response.data.choices[0].message.content.trim()
    );
    console.log("🚀 ~ parseDataUsingOpenAI ~ completion:", completion);
    return completion;
  } catch (error) {
    console.log("🚀 ~ parseDataUsingOpenAI ~ error:", error);
  }
};
