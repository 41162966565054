import { onAuthStateChanged } from "firebase/auth";
import React, { createContext, useEffect, useState } from "react";
import { app, auth } from "../firebase/config";
import { getPremiumStatus } from "../utils/getPremiumStatus";

export const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [premiumPlan, setPremiumPlan] = useState(null);

  useEffect(() => {
    const checkPremium = async () => {
      const check = auth.currentUser ? await getPremiumStatus() : null;
      if (check) {
        setPremiumPlan(check);
      }
    };
    checkPremium();
  }, [app, auth.currentUser?.uid]);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setUser(user);
      } else {
        setUser(null);
      }
    });
    return () => unsubscribe();
  }, [auth, app]);

  return (
    <UserContext.Provider
      value={{ user, setUser, isLogged: !!user, premiumPlan }}
    >
      {children}
    </UserContext.Provider>
  );
};
