import { createTheme } from "@material-ui/core/styles";

// const primary = "#003D50";
const secondary = "#EA672F";

// const secondary = "#0B0B15";
const primary = "#003D50";

const accentColor = "#F48190";
//const cardRadius = "50px";
//const inputRadius = "25px";
const theme = createTheme({
  typography: {
    fontFamily: "Satoshi-Variable",
  },

  breakpoints: {
    values: {
      xs: 0, // Extra small devices (phones)
      sm: 600, // Small devices (tablets)
      md: 960, // Medium devices (laptops)
      lg: 1280, // Large devices (desktops)
      xl: 1920, // Extra large devices (large desktops)
    },
  },
  palette: {
    primary: {
      main: primary,
    },
    secondary: {
      main: secondary,
    },
    accent: {
      main: accentColor,
    },
  },
  overrides: {
    MuiTab: {
      root: {
        padding: "4px",
      },
      "&$selected": {
        padding: "40px",
      },
    },
    MuiTextField: {
      root: {
        borderRadius: "10px", // Define your border radius value here
        // background: "white",
        // marginTop: "10px",
        // padding: "8px 16px",
        // border: "1px solid #ECECEC",
        // width: "100%",
      },

      "& .MuiInputBase-input": {
        padding: "16px 32px",
        fontWeight: 400,
        borderBottom: "none",
        outline: "none",
        backgroundColor: "none",
      },
    },
    MuiButton: {
      root: {
        borderRadius: "10px",
      },
    },
    MuiFormControl: {
      root: {
        borderRadius: "10px", // Define your border radius value here
      },
    },
  },
});

export default theme;
