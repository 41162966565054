import React, { useState, useEffect, useContext } from "react";
import { LanguageContext } from "../../Languages/LanguageContext";
import {
  Grid,
  makeStyles,
  useTheme,
  useMediaQuery,
  Menu,
  MenuItem,
} from "@material-ui/core";
import DrawerComponent from "./Drawer";
import { Link, useNavigate } from "react-router-dom";
import RemotocoLogo from "../../Assets/remotologo.svg";
import { auth } from "../../firebase/config";
import LanguageChange from "../../Languages/LanguageChange";
import { transparent } from "material-ui/styles/colors";

const useStyles = makeStyles((theme) => ({
  companyIcon: {
    width: "150px",
    height: "45px",
    [theme.breakpoints.down("sm")]: {
      width: "100px",
      height: "32px",
    },
  },
  logo: {
    color: "#826AA6",

    cursor: "pointer",
  },

  canvas: {
    background: "#FAFAFA",
    height: "96px",
    // paddingBottom: "30px",
    // paddingTop: "50px",
  },
  emptyChip: {
    padding: "8px 16px",
    fontSize: "16px",
    borderRadius: "10px",
    transition: ".3s",
    fontWeight: 700,
    marginRight: "15px",
    background: transparent,
    border: `1px solid #003D50`,
    color: "#003D50",
    textDecoration: "none",
  },

  filledChip: {
    padding: "8px 16px",
    fontSize: "16px",
    borderRadius: "8px",
    transition: ".3s",
    fontWeight: 600,
    border: `1px solid #003D50`,
    color: "white",
    background: "#003D50",
    textDecoration: "none",
  },
  formControl: {
    borderRadius: "25px",
    border: `1px solid #003D50`,
    padding: "2px 8px ",
    marginRight: "15px",
    justifyContent: "center",
    alignItems: "center",
    "& .MuiSelect-root": {
      borderBottom: "none",
      color: "#003D50",
      textAlign: "flex-end",
      fontSize: "16px",
    },
    "& .MuiInput-underline:before": {
      borderBottom: "none",
    },
    "& .MuiInput-underline:after": {
      borderBottom: "none",
    },
    "&:hover .MuiInput-underline:before": {
      borderBottom: "none",
    },
    "&:hover .MuiInput-underline:after": {
      borderBottom: "none",
    },
  },
}));

function Navbar() {
  const classes = useStyles();
  const [user, setUser] = useState(null);
  const { locale, messages } = useContext(LanguageContext);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDemoClick = () => {
    window.open("https://calendly.com/sikander-khan", "_blank");
  };

  const handleLogoClick = () => {
    if (user) {
      navigate("/remotee");
    } else {
      navigate(`/${locale}`);
    }
  };

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        setUser(user);
      } else {
        setUser(null);
      }
    });

    return unsubscribe;
  }, []);

  return (
    <>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        className={classes.canvas}
      >
        <Grid item lg={10} xs={10}>
          <Grid container justifyContent="space-between">
            <Grid
              container
              item
              xs={4}
              className={classes.logo}
              onClick={handleLogoClick}
            >
              <img
                src={RemotocoLogo}
                className={classes.companyIcon}
                alt="remoto logo"
              />
            </Grid>
            <Grid
              container
              item
              xs={8}
              justifyContent="flex-end"
              alignItems="center"
            >
              <div className="lg:hidden">
                <Grid item>
                  <LanguageChange />
                </Grid>
              </div>
              <div className="hidden lg:flex gap-6 items-center">
                {/* <LinkComponent to={"#partners"} title="Our partners" />
                <LinkComponent to={"#work"} title="How we work" />
                <LinkComponent to={"#benefits"} title="Benefits" />
                <LinkComponent to={"#contact"} title="Contact us" /> */}

                <Grid item>
                  <LanguageChange />
                </Grid>
              </div>

              {isMobile ? (
                <DrawerComponent />
              ) : (
                <>
                  <Link to={`/login`} className={classes.emptyChip}>
                    {messages[locale].login}
                  </Link>
                  <div>
                    {/* <button
                      aria-controls="register-menu"
                      aria-haspopup="true"
                      onClick={handleClick}
                      className={classes.filledChip}
                    >
                      Get Started
                    </button> */}
                    <button
                      aria-controls="register-menu"
                      aria-haspopup="true"
                      onClick={handleDemoClick}
                      className={classes.filledChip}
                    >
                      {/* {messages[locale].register} */}
                      Book a Demo
                    </button>
                    <Menu
                      id="register-menu"
                      anchorEl={anchorEl}
                      open={Boolean(anchorEl)}
                      onClose={handleClose}
                      PaperProps={{
                        style: {
                          marginTop: "60px",
                          borderRadius: "10px",
                          padding: "8px 24px",
                          border: "1px solid #E9E4EE",
                        },
                      }}
                      transformOrigin={{ vertical: "top", horizontal: "right" }}
                      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                    >
                      <MenuItem
                        component={Link}
                        to="/remotee/register"
                        onClick={handleClose}
                      >
                        {messages[locale].remoteeDescription}
                      </MenuItem>
                      <MenuItem
                        component={Link}
                        to="/remoter/register"
                        onClick={handleClose}
                      >
                        {messages[locale].remoterDescription}
                      </MenuItem>
                    </Menu>
                  </div>
                </>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

function LinkComponent({ to, title }) {
  return (
    <a
      href={to}
      className="font-semibold text-sm cursor-pointer hover:text-primary-400 transition duration-2000"
    >
      {title}
    </a>
  );
}
export default Navbar;
