import React, { useState, useContext } from "react";
import { LanguageContext } from "../../Languages/LanguageContext";
import { IconButton, makeStyles } from "@material-ui/core";
import { MoreVert as MenuIcon } from "@material-ui/icons";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";

import theme from "../../theme/theme";

const useStyles = makeStyles(() => ({
  link: {
    textDecoration: "none",
    color: "black",
    fontSize: "30px",
    textAlign: "left",
  },
  icon: {
    color: theme.palette.primary.main,
  },
}));

function DrawerComponent() {
  const classes = useStyles();
  const { locale, messages } = useContext(LanguageContext);
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorE2, setAnchorE2] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleClickRegister = (event) => {
    setAnchorE2(event.currentTarget);
  };

  const handleCloseRegister = () => {
    setAnchorE2(null);
  };

  return (
    <>
      <IconButton className={classes.icon} onClick={handleClick}>
        <MenuIcon />
      </IconButton>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        className={classes.menu}
        PaperProps={{
          style: {
            marginTop: "60px",
            borderRadius: "10px",
            padding: "8px 24px",
            border: "1px solid #E9E4EE",
          },
        }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <MenuItem onClick={(event) => (window.location.href = "/login")}>
          {messages[locale].login}
        </MenuItem>
        <MenuItem onClick={handleClickRegister}>
          <Menu
            id="simple-menu"
            anchorEl={anchorE2}
            open={Boolean(anchorE2)}
            onClose={handleCloseRegister}
            className={classes.menu}
            PaperProps={{
              style: {
                marginTop: "60px",
                borderRadius: "25px",
                padding: "8px 24px",
                border: "1px solid #E9E4EE",
              },
            }}
            transformOrigin={{ vertical: "top", horizontal: "right" }}
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          >
            <MenuItem
              onClick={(event) => (window.location.href = "/remotee/register")}
            >
              I'm looking for a job
            </MenuItem>
            <MenuItem
              onClick={(event) => (window.location.href = "/remoter/register")}
            >
              I'm looking to hire people
            </MenuItem>
          </Menu>
          {messages[locale].register}
        </MenuItem>
      </Menu>
    </>
  );
}

export default DrawerComponent;
