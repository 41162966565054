import { create } from "zustand";
import { collection, query, orderBy, where, getDocs } from "firebase/firestore";
import { COLLECTIONS, firestore } from "../firebase/config";

const useCandidateUnlocks = create((set, getState) => ({
  bulkIds: [],
  setBulkIds: (jobIds) => set({ bulkIds: jobIds }),

  refresh: false,
  setRefresh: (bool) => set({ refresh: bool }),

  filteredApplicants: [],
  setFilteredApplicants: (apps) => set({ filteredApplicants: apps }),

  applications: [],
  setApplications: (apps) => set({ applications: apps }),

  applicationDetails: null,
  setApplicationDetails: (app) => set({ applicationDetails: app }),

  // applicants for each job
  fetchApplicants: async (jobId) => {
    try {
      const dbRefCompany = collection(firestore, COLLECTIONS.APPLICATIONS);
      const qC = query(
        dbRefCompany,
        orderBy("profileMatch", "desc"),
        where("jobId", "==", jobId)
      );
      const reqC = await getDocs(qC);
      const tempList = reqC.docs.map((application) => ({
        ...application.data(),
        id: application.id,
      }));
      set({
        applications: tempList,
      });
    } catch (err) {
      console.log("🚀 ~ fetchApplicants ~ err:", err);
    }
  },
}));

export default useCandidateUnlocks;
