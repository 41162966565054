// const { isPremium } = useContext(UserContext);

import { app } from "../firebase/config";
import { getCheckoutUrl } from "../pages/checkout";
import useCandidateUnlocks from "../store/unlocks";
import { stripePriceIds } from "./constants";

// const handlePremium = async () => {
//   const priceId = stripePriceIds[0];
//   const checkUrl = await getCheckoutUrl(app, priceId);
//   window.location = checkUrl;
// };
// const handlePortal = async () => {
//   const portalUrl = await getPortalUrl(app);
//   window.location = portalUrl;
// };

export const handleCommunitySubscription = async () => {
  try {
    const url = await getCheckoutUrl(app, stripePriceIds[1]);
    window.location.href = url;
  } catch (error) {
    console.error("Error during early bird subscription:", error);
  }
};

export const handleEarlyBirdSubscription = async () => {
  try {
    const url = await getCheckoutUrl(app, stripePriceIds[2]);
    // window.location.href = url;
    window.open(
      url,
      "stripeCheckout",
      "width=500,height=600,scrollbars=yes,resizable=yes"
    );

    window.addEventListener("message", (event) => {
      if (event.data === "earlyBirdSuccess") {
        // Reload parent window
        window.location.reload();
      }
    });
  } catch (error) {
    console.error("Error during lifetime access subscription:", error);
  }
};

export const handleCandidateUnlockCheckout = async (applicationId) => {
  try {
    const url = await getCheckoutUrl(app, stripePriceIds[3], applicationId);
    // window.open(url, "_blank");
    window.open(
      url,
      "stripeCheckout",
      "width=500,height=600,scrollbars=yes,resizable=yes"
    );

    window.addEventListener("message", async (event) => {
      if (event.data === "checkoutSuccess") {
        // Reload parent window
        const searchParams = new URLSearchParams(window.location.search);

        // Access the parameters
        const job = searchParams.get("jobId");
        await useCandidateUnlocks.getState().fetchApplicants(job);
        useCandidateUnlocks
          .getState()
          .setRefresh(!useCandidateUnlocks.getState().refresh);
        // window.location.reload();
      }
    });
  } catch (error) {
    console.error("Error during lifetime access subscription:", error);
  }
};
