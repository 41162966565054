import React, { useEffect, useRef, useState } from "react";
import {
  getFirestore,
  collection,
  query,
  where,
  onSnapshot,
  getDocs,
  writeBatch,
  orderBy,
} from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { HiBell } from "react-icons/hi2";
import {
  differenceInDays,
  differenceInHours,
  differenceInMinutes,
  differenceInMonths,
  differenceInYears,
} from "date-fns";
import useDropdownHandler from "../../utils/useDropdownHandler";
import { useNavigate } from "react-router-dom";

const Notifications = () => {
  const [notifications, setNotifications] = useState([]);
  const popupRef = useRef(null);
  const { isExpanded, setIsExpanded } = useDropdownHandler(popupRef);
  const navigate = useNavigate();
  const db = getFirestore();
  const auth = getAuth();
  const currentUser = auth.currentUser;

  useEffect(() => {
    if (currentUser) {
      const q = query(
        collection(db, "notifications"),
        where("companyEmail", "==", currentUser.email),
        where("type", "==", "applyJob"),
        orderBy("timestamp", "desc")
      );
      const unsubscribe = onSnapshot(q, (querySnapshot) => {
        const notificationsArray = [];
        querySnapshot.forEach((doc) => {
          notificationsArray.push({ id: doc.id, ...doc.data() });
        });
        setNotifications(notificationsArray);
      });

      return () => unsubscribe();
    }
  }, [currentUser, db]);

  const handleClick = () => {
    if (!isExpanded) {
      handleMarkAllAsRead();
    }
    setIsExpanded((prev) => !prev);
  };

  const handleMarkAllAsRead = async () => {
    const notificationsRef = collection(db, "notifications"); // Update the collection name if necessary
    const notificationsQuery = query(
      notificationsRef,
      where("read", "==", false)
    ); // Only select unread notifications

    try {
      const querySnapshot = await getDocs(notificationsQuery);
      const batch = writeBatch(db);

      querySnapshot.forEach((doc) => {
        const docRef = doc.ref;
        batch.update(docRef, { read: true });
      });

      await batch.commit();
      const temp = [...notifications];
      setNotifications(
        temp.map((t) => {
          return { ...t, read: true };
        })
      );

      console.log("All notifications marked as read.");
    } catch (error) {
      console.error("Error updating notifications:", error);
    }
  };
  const formatTimeAgo = (date) => {
    const now = new Date();
    const yearsDiff = differenceInYears(now, date);
    const monthsDiff = differenceInMonths(now, date);
    const daysDiff = differenceInDays(now, date);
    const hoursDiff = differenceInHours(now, date);
    const minutesDiff = differenceInMinutes(now, date);

    if (yearsDiff >= 1) {
      return `${yearsDiff} year${yearsDiff > 1 ? "s" : ""} ago`;
    } else if (monthsDiff >= 1) {
      return `${monthsDiff} month${monthsDiff > 1 ? "s" : ""} ago`;
    } else if (daysDiff >= 1) {
      return `${daysDiff} day${daysDiff > 1 ? "s" : ""} ago`;
    } else if (hoursDiff >= 1) {
      return `${hoursDiff} hour${hoursDiff > 1 ? "s" : ""} ago`;
    } else {
      return `${minutesDiff} minute${minutesDiff > 1 ? "s" : ""} ago`;
    }
  };

  const handleNotificationClick = (notification) => {
    if (notification.type === "applyJob") {
      navigate("/applications?jobId=" + notification.job, {
        state: {
          applicationId: notification.application,
        },
      });
    }
  };

  const unReadNotifications = notifications?.filter((n) => !n.read);
  return (
    <div ref={popupRef}>
      <BellIcon notifications={unReadNotifications} handleClick={handleClick} />
      {notifications.length > 0 && isExpanded && (
        <div className="absolute w-96 border p-3 mt-2 right-44 border-borderGrey-100 max-h-96 overflow-y-auto shadow-md z-50 bg-white">
          {notifications.map((notification) => (
            <div
              key={notification.id}
              className="notification-item py-2 border-b border-gray-200 flex items-center gap-2 justify-between cursor-pointer"
              onClick={() => handleNotificationClick(notification)}
            >
              <span className="ml-2">{notification.message}</span>
              <span className="text-gray-500 text-sm text-nowrap">
                {formatTimeAgo(notification?.timestamp?.toDate())}
              </span>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export const BellIcon = ({ notifications, handleClick }) => {
  return (
    <div className="bell-icon relative">
      <HiBell
        className="text-primary-400 text-2xl cursor-pointer"
        onClick={handleClick}
      />
      {notifications.length > 0 && (
        <div className="absolute bg-secondary-400 rounded-full top-0 right-0 text-[8px] text-white w-3 h-3 flex items-center justify-center">
          <span className="notification-count">{notifications.length}</span>
        </div>
      )}
    </div>
  );
};

export default Notifications;
