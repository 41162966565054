import React, { useContext } from "react";
import { LanguageContext } from "./LanguageContext";
import {
  Grid,
  makeStyles,
  FormControl,
  Select,
  MenuItem,
} from "@material-ui/core";
import i18next from "i18next";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    paddingRight: "16px",
    [theme.breakpoints.down("sm")]: {
      paddingRight: "4px",
    },
  },
  formControl: {
    background: "transparent",
    border: "1px solid #003D50",
    borderRadius: "8px",
    padding: "2px 8px",
    "& .MuiSelect-root": {
      backgroundColor: "transparent",
      borderBottom: "none",
      color: "#003D50",
      display: "flex",
    },
    "& .MuiInput-underline:before": {
      borderBottom: "none",
    },
    "& .MuiInput-underline:after": {
      borderBottom: "none",
    },
    "&:hover .MuiInput-underline:before": {
      borderBottom: "none",
    },
    "&:hover .MuiInput-underline:after": {
      borderBottom: "none",
    },
  },

  menuItem: {
    fontFamily: "Font Awesome 5 Free",
  },
}));

const LanguageChange = () => {
  const classes = useStyles();
  const { locale, changeLanguage } = useContext(LanguageContext);
  // const countryCode = "US";
  // const flagEmoji = countryFlagEmoji(countryCode);

  const handleLanguageChange = (event) => {
    changeLanguage(event.target.value);
    if (event.target.value === "esp") {
      i18next.changeLanguage("es");
    } else {
      i18next.changeLanguage(event.target.value);
    }
  };
  return (
    <>
      <Grid className={classes.wrapper}>
        <FormControl className={classes.formControl}>
          <Select
            labelId="language-select-label"
            id="language-select"
            value={locale}
            onChange={handleLanguageChange}
          >
            <MenuItem
              value="en"
              style={{ display: "flex", alignItems: "center" }}
            >
              <img
                src="https://flagcdn.com/16x12/us.png"
                srcset="https://flagcdn.com/32x24/us.png 2x,
    https://flagcdn.com/48x36/us.png 3x"
                width="16"
                height="12"
                style={{ marginRight: "4px" }}
                alt="USA"
              />
              en
            </MenuItem>
            <MenuItem
              value="esp"
              style={{ display: "flex", alignItems: "center" }}
            >
              <img
                src="https://flagcdn.com/16x12/es.png"
                srcset="https://flagcdn.com/32x24/es.png 2x,
    https://flagcdn.com/48x36/es.png 3x"
                width="16"
                height="12"
                style={{ marginRight: "4px" }}
                alt="Spain"
              />
              esp
            </MenuItem>
            <MenuItem
              value="pt"
              style={{ display: "flex", alignItems: "center" }}
            >
              <img
                src="https://flagcdn.com/16x12/pt.png"
                srcset="https://flagcdn.com/32x24/pt.png 2x,
    https://flagcdn.com/48x36/pt.png 3x"
                width="16"
                height="12"
                style={{ marginRight: "4px" }}
                alt="Portugal"
              />
              pt
            </MenuItem>
          </Select>
        </FormControl>
      </Grid>
    </>
  );
};
export default LanguageChange;
