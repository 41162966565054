import React, { useContext, useState } from "react";
import { UserContext } from "../../context/UserContext";
import { MdEmail } from "react-icons/md";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { sendEmailVerification } from "firebase/auth";

import { RxCross1 } from "react-icons/rx";
import { FaCircleCheck } from "react-icons/fa6";
export default function VerifyEmail() {
  const { user } = useContext(UserContext);
  const [isSent, setIsSent] = useState(false);

  const sendEmail = () => {
    sendEmailVerification(user)
      .then((res) => {
        console.log(res);
        setIsSent(true);
      })
      .catch((err) => {
        console.log("🚀 ~ sendEmail ~ err:", err);
        toast.error("Something went wrong while sending email", {
          position: toast.POSITION.TOP_CENTER,
        });
      });
  };

  return (
    <>
      <ToastContainer autoClose={3000} />
      {!isSent ? (
        <div className="flex items-center gap-1 py-4 px-6 bg-primary-400 text-white justify-between">
          <div className="flex items-center gap-1">
            <MdEmail className="text-lg" />
            <p className="text-sm">
              {`Please verify your email address by clicking the link sent to `}
              <strong>{user?.email}</strong>
            </p>
          </div>
          <div>
            <buttton
              className="text-sm border-b-2 border-white cursor-pointer"
              onClick={sendEmail}
            >
              <strong>Resend Verification Email</strong>
            </buttton>
          </div>
        </div>
      ) : (
        <SentMessage onChange={() => setIsSent(false)} />
      )}
    </>
  );
}

export function VerifiedEmail({ onChange }) {
  return (
    <div className="flex items-center gap-1 py-4 px-6 bg-[#2CBA99] text-white justify-between">
      <div className="flex items-center gap-2 flex-1 justify-center">
        <FaCircleCheck className="text-lg" />
        <p className="text-sm">Thank you for verifying your email address</p>
      </div>
      <div>
        <button
          className="text-sm cursor-pointer bg-white h-6 w-6 rounded-md flex justify-center items-center"
          onClick={onChange}
        >
          <RxCross1 className="text-[#2CBA99]" />
        </button>
      </div>
    </div>
  );
}

export function SentMessage({ onChange }) {
  return (
    <div
      className="flex items-center gap-1 py-4 px-6 text-white justify-between"
      style={{
        background:
          "linear-gradient(134.78deg, #FF7E47 -15.89%, #FF540B 116.93%)",
      }}
    >
      <div className="flex items-center gap-2 flex-1 justify-center">
        <FaCircleCheck className="text-lg" />
        <p className="text-sm">
          Verification email sent. Please check your inbox.
        </p>
      </div>
      <div>
        <button
          className="text-sm cursor-pointer bg-white h-6 w-6 rounded-md flex justify-center items-center"
          onClick={onChange}
        >
          <RxCross1 className="text-secondary-400" />
        </button>
      </div>
    </div>
  );
}
