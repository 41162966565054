import { CircularProgress } from "@material-ui/core";
import React from "react";

export default function PrimaryBtn({
  text,
  color = "bg-secondary-400",
  onClick,
  loading,
  type = "button",
  disabled,
}) {
  const border = "border-2 border-secondary-400";
  return (
    <button
      onClick={onClick}
      type={type}
      disabled={disabled}
      className={`${color} ${border} text-white font-semibold rounded-md px-3 py-2
        hover:opacity-90 disabled:bg-borderGrey-400 disabled:border-borderGrey-400`}
    >
      {loading ? <CircularProgress size={18} color="inherit" /> : text}
    </button>
  );
}

export function IconButton({
  icon,
  text,
  color = "bg-secondary-400",
  onClick,
  loading,
  iconFirst = true,
  type = "button",
}) {
  return (
    <button
      onClick={onClick}
      type={type}
      disabled={loading}
      className={`${color} text-white font-semibold rounded-md px-3 py-2 flex gap-2 items-center 
        hover:opacity-90`}
    >
      {loading ? (
        <CircularProgress size={18} color="inherit" />
      ) : (
        <>
          {iconFirst ? (
            <>
              {icon} {text}
            </>
          ) : (
            <>
              {text} {icon}
            </>
          )}
        </>
      )}
    </button>
  );
}

export function SecondaryIconButton({
  icon,
  text,
  color = "bg-transparent",
  onClick,
  loading,
}) {
  return (
    <button
      onClick={onClick}
      type="button"
      disabled={loading}
      className={`${color} text-primary-400 font-semibold rounded-md px-3 py-2 flex gap-2 items-center 
        hover:opacity-90 border-2 border-primary-400`}
    >
      {loading ? (
        <CircularProgress size={18} color="inherit" />
      ) : (
        <>
          {icon} {text}
        </>
      )}
    </button>
  );
}

export const SecondaryBtn = ({ text, onClick, disabled }) => {
  return (
    <button
      type="button"
      disabled={disabled}
      onClick={onClick}
      className="bg-transparent text-primary-400 font-semibold border-2 border-primary-400  rounded-md px-3 py-2
        hover:opacity-90 disabled:bg-borderGrey-400 disabled:border-borderGrey-400 disabled:text-white"
    >
      {text}
    </button>
  );
};
