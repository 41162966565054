import React, { useState, useContext, useEffect } from "react";
import {
  Grid,
  makeStyles,
  Typography,
  TextField,
  Button,
  DialogActions,
} from "@material-ui/core";
import industries from "../Data/Industries";
import countries from "../Data/Countries";
import { ReactComponent as ExpIcon } from "../../Assets/experienceIcon.svg";
import { ReactComponent as EmptyIcon } from "../../Assets/empty_exp.svg";
import { LanguageContext } from "../../Languages/LanguageContext";
import { MdDelete } from "react-icons/md";
import { Timestamp } from "firebase/firestore";
import { IoMdClose } from "react-icons/io";
import { useForm } from "react-hook-form";
import { experienceSchema } from "../../schemas/profile";
import { yupResolver } from "@hookform/resolvers/yup";
import TextInput from "../Global/TextInput";
import SelectOption from "../Global/SelectOption";
import SearchSkill from "./SearchSkills";
import { PlusIcon } from "@heroicons/react/24/solid";
import DateSelector from "./DateSelector";
import DialogModal from "../Global/Dialog";
import { FaEdit } from "react-icons/fa";
import PrimaryBtn, {
  IconButton,
  SecondaryBtn,
  SecondaryIconButton,
} from "../Global/Buttons";
import { GoPlus } from "react-icons/go";
import "../../style.css";
import { parse } from "date-fns";
import { IoIosWarning } from "react-icons/io";
import Location from "../Global/Location";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    border: "1px solid #D1D1D1",
    borderRadius: "8px",
    padding: "32px",
    marginTop: "16px",
  },
  label: {
    fontWeight: 600,
    fontSize: "14px",
    lineHeight: "19px",
    color: "#151515",
    letterSpacing: "-0.02em",
    marginLeft: "32px",
  },
  heading: {
    fontWeight: 700,
    fontSize: "28px",
    lineHeight: "120%",
    color: "#000",
    letterSpacing: "-0.02em",
  },

  whiteButton: {
    backgroundColor: "transparent",
    textTransform: "unset",
    color: "#003D50",
    padding: "12px 24px",
    disableAnimation: true,
    fontSize: "16px",
    fontWeight: "700",
    width: "250px",
    marginTop: "16px",
    border: "1px solid #003D50",
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px", // Font size for mobile (screens smaller than or equal to small breakpoint)
      padding: "8px 16px",
    },
  },

  closeBtn: {
    backgroundColor: "transparent",
    textTransform: "unset",
    color: "#E22A34",
    padding: "8px 16px",
    disableAnimation: true,
    fontSize: "16px",
    fontWeight: "600",
    border: "1px solid #E22A34",
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
      padding: "8px 16px",
    },
    "&:hover": {
      border: "1px solid #E22A34",
    },
  },
  cancelBtn: {
    backgroundColor: "#003D50",
    textTransform: "unset",
    color: "#fff",
    padding: "8px 16px",
    disableAnimation: true,
    fontSize: "16px",
    fontWeight: "500",
    border: "1px solid #003D50",
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
      padding: "8px 16px",
    },
    "&:hover": {
      border: "1px solid #003D50",
    },
  },
  formControl: {
    background: "white",
    marginTop: "10px",
    border: "1px solid #ECECEC",
    padding: "8px 16px",
    "& .MuiSelect-root": {
      backgroundColor: "white ",
      borderBottom: "none",
    },
    "& .MuiInput-underline:before": {
      borderBottom: "none",
    },
    "& .MuiInput-underline:after": {
      borderBottom: "none",
    },
    "&:hover .MuiInput-underline:before": {
      borderBottom: "none",
    },
    "&:hover .MuiInput-underline:after": {
      borderBottom: "none",
    },
  },
  textField: {
    background: "white",
    marginTop: "10px",
    border: "1px solid #ECECEC",
    padding: "8px 16px",
    width: "100%",
    "& .MuiInputBase-input": {
      padding: "8px 16px",
      color: theme.palette.text.primary,
      fontWeight: 400,
      borderBottom: "none",
      outline: "none",
      backgroundColor: "none",
    },
  },
  skillChip: {
    margin: theme.spacing(0.5),
    fontSize: "14px",
    padding: "4px 8px",
    borderRadius: "5px",
    transition: ".3s",
    fontWeight: 400,
    marginRight: "10px",
    border: `1px solid #003D50`,
    color: "#003D50",
  },

  experienceItem: {
    padding: "16px",
    border: "1px solid #D1D1D1",
    borderRadius: "8px",
    marginTop: "32px",
  },
  title: {
    fontWeight: 600,
    fontSize: "32px",
    lineHeight: "120%",
    color: "#003D50",
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px", // Font size for mobile (screens smaller than or equal to small breakpoint)
      lineHeight: "24px",
    },
  },
  companyName: {
    fontWeight: 700,
    fontSize: "20px",
    lineHeight: "120%",
    marginTop: "4px",
    color: "#000",
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px", // Font size for mobile (screens smaller than or equal to small breakpoint)
      lineHeight: "24px",
    },
  },
  duration: {
    fontWeight: 500,
    fontSize: "16px",
    lineHeight: "120%",
    marginTop: "12px",
    color: "#868686",
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px", // Font size for mobile (screens smaller than or equal to small breakpoint)
      lineHeight: "24px",
    },
  },
  description: {
    fontWeight: 500,
    fontSize: "16px",
    lineHeight: "120%",
    marginTop: "16px",
    color: "#000",
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px", // Font size for mobile (screens smaller than or equal to small breakpoint)
      lineHeight: "24px",
    },
  },
  border: {
    border: "1px solid red",
  },
}));

const ExperienceTab = (props) => {
  const classes = useStyles();
  const { locale, messages } = useContext(LanguageContext);
  const [items, setItems] = useState(
    props.userInfo.employment ? props.userInfo.employment : []
  );
  const [isEmploymentEditing, setIsEmploymentEditing] = useState(false);
  const [employmentEditIndex, setEmploymentEditIndex] = useState(null);
  const [deleteIndex, setDeleteIndex] = useState(-1);
  const [open, setOpen] = useState(false);
  const [selectedSkills, setSelectedSkills] = useState([]);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [initialValues, setInitialValues] = useState({});
  const [initialSkills, setInitialSkills] = useState([]);
  const { t } = useTranslation();
  const handleSelect = (skills) => {
    setError("");
    setSelectedSkills(skills);
  };
  const handleRemove = (skillToRemove) => {
    setError("");
    setSelectedSkills(
      selectedSkills.filter((skill) => skill !== skillToRemove)
    );
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    reset,
    setValue,
  } = useForm({
    resolver: yupResolver(experienceSchema),
    defaultValues: {
      jobTitle: "",
      company: "",
      // industry: "",
      jobType: "",
      // country: "",
      // city: "",
      startDate: new Date().getTime(),
      endDate: new Date().getTime(),
      duties: "",
      chipSkills: [],
      isCurrentlyWorking: false,
    },
  });

  const handleChange = (name, value) => {
    if (name === "startDate") {
      setValue("startDate", value);
    } else if (name === "endDate") {
      setValue("endDate", value);
    }
  };

  // useEffect(() => {
  //   setInitialValues(watch());
  //   setInitialSkills(selectedSkills);
  // }, []);

  const handleSave = async (data) => {
    try {
      setLoading(true);

      const tempItems = [...items];
      data.chipSkills = [...selectedSkills];
      tempItems.push(data);

      if (props.onboarding) {
        const candidate = {
          employment: [...tempItems],
        };
        const updatedUserInfo = { ...props.userInfo, ...candidate };
        props.onSave(updatedUserInfo);
        setItems(tempItems);
        setOpen(false);
        return;
      }

      const employmentItemsWithStrings = tempItems.map((item) => ({
        ...item,
        startDate: Timestamp.fromDate(new Date(item.startDate)),
        endDate: Timestamp.fromDate(new Date(item.endDate)),
      }));

      const candidate = {
        employment: employmentItemsWithStrings,
      };
      const updatedUserInfo = { ...props.userInfo, ...candidate };
      await props.onSave(updatedUserInfo);
      setItems(tempItems);
      setOpen(false);
    } catch (err) {
      console.log("🚀 ~ handleSave ~ err:", err);
    } finally {
      setLoading(false);
    }
  };

  const handleEmploymentEditSave = async (data) => {
    try {
      setLoading(true);
      const updatedItems = [...items];
      data.chipSkills = [...selectedSkills];
      updatedItems[employmentEditIndex] = { ...data };
      if (props.onboarding) {
        const candidate = {
          employment: [...updatedItems],
        };
        const updatedUserInfo = { ...props.userInfo, ...candidate };
        props.onSave(updatedUserInfo);
        setItems(updatedItems);
        setIsEmploymentEditing(false);
        setOpen(false);
        return;
      }

      const employmentItemsWithStrings = updatedItems.map((item) => ({
        ...item,
        startDate: Timestamp.fromDate(new Date(item.startDate)),
        endDate: Timestamp.fromDate(new Date(item.endDate)),
      }));
      const candidate = {
        employment: employmentItemsWithStrings,
      };
      const updatedUserInfo = { ...props.userInfo, ...candidate };

      await props.onSave(updatedUserInfo);
      setItems(updatedItems);
      setIsEmploymentEditing(false);
      setOpen(false);
    } catch (err) {
      console.log("🚀 ~ handleSave ~ err:", err);
    } finally {
      setLoading(false);
    }
  };

  const handleEmploymentEdit = (index) => {
    const tempData = items[index];
    setDefaultValues(tempData);

    setInitialValues(tempData);
    setInitialSkills(tempData?.chipSkills ?? []);

    setEmploymentEditIndex(index);
    setIsEmploymentEditing(true);
    setOpen(true);
  };

  const setDefaultValues = (tempData) => {
    setValue("jobTitle", tempData.jobTitle);
    setValue("company", tempData.company);
    // setValue("industry", tempData.industry);
    setValue("jobType", tempData.jobType);
    // setValue("country", tempData.country);
    // setValue("city", tempData.city);
    setValue("location", tempData.location ? tempData.location : "");
    setValue(
      "startDate",
      tempData.startDate ? new Date(tempData.startDate).getTime() : null
    );
    setValue(
      "endDate",
      tempData.endDate ? new Date(tempData.endDate).getTime() : null
    );
    setValue("duties", tempData.duties);
    setValue(
      "isCurrentlyWorking",
      tempData.isCurrentlyWorking ? tempData.isCurrentlyWorking : false
    );
    setSelectedSkills(tempData.chipSkills ?? []);
  };

  const handleDeleteConfirm = () => {
    const temp = [...items];
    temp.splice(deleteIndex, 1);

    if (props.onboarding) {
      const candidate = {
        employment: [...temp],
      };
      const updatedUserInfo = { ...props.userInfo, ...candidate };
      props.onSave(updatedUserInfo);
      setItems(temp);
      setDeleteIndex(-1);
      return;
    }
    const employmentItemsWithStrings = temp.map((item) => ({
      ...item,
      startDate: Timestamp.fromDate(new Date(item.startDate)),
      endDate: Timestamp.fromDate(new Date(item.endDate)),
    }));
    const candidate = {
      employment: employmentItemsWithStrings,
    };
    const updatedUserInfo = { ...props.userInfo, ...candidate };
    props.onSave(updatedUserInfo);
    setItems(temp);
    setDeleteIndex(-1);
  };

  const handleClickOpen = () => {
    setOpen(true);
    reset();
  };

  const handleClose = () => {
    setSelectedSkills([]);
    setIsEmploymentEditing(false);
    reset();
    setOpen(false);
  };
  const handleEmploymentDelete = (indexToRemove) => {
    setDeleteIndex(indexToRemove);
  };

  const isFormChanged = () => {
    const currentValues = watch();

    const result = Object.keys(currentValues).some((key) => {
      if (key === "startDate" || key === "endDate") {
        return (
          new Date(initialValues[key]).getTime() !==
          new Date(currentValues[key]).getTime()
        );
      } else if (key === "chipSkills") {
        return false;
      } else {
        return initialValues[key] != currentValues[key];
      }
    });

    const resultSkills =
      selectedSkills.length !== initialSkills.length ||
      selectedSkills.some((skill, index) => skill !== initialSkills[index]);

    return resultSkills || result;
  };

  const hasErrorsAtIndex = (index) => {
    return (
      props?.errors?.employment?.[index] &&
      Object.keys(props?.errors.employment[index]).length > 0
    );
  };

  return (
    <>
      <Grid>
        <Grid>
          {!props.onboarding && (
            <Grid
              item
              container
              alignItems="center"
              justifyContent="space-between"
            >
              <Grid item xs={6}>
                <Typography className={classes.heading}>
                  {t("employmentHistory")}
                </Typography>
              </Grid>
              <Grid
                container
                item
                xs={6}
                justifyContent="flex-end"
                alignItems="center"
              >
                <SecondaryIconButton
                  icon={<GoPlus className="text-primary-400" />}
                  text={t("add_work_experience")}
                  onClick={handleClickOpen}
                />
              </Grid>
            </Grid>
          )}
          {items.length > 0 ? (
            <div>
              {items.map((item, index) => (
                <Grid
                  container
                  key={index}
                  justifyContent="space-between"
                  // ref={(el) => (props.expRefs.current[index] = el)}
                  className={`${classes.experienceItem} ${
                    hasErrorsAtIndex(index) ? classes.border : ""
                  }`}
                  ref={(el) => (props.employmentRefs.current[index] = el)}
                >
                  <Grid item xs={12}>
                    {props?.errors?.employment?.[index] &&
                      Object.keys(props.errors.employment[index]).map(
                        (field) =>
                          props.errors.employment[index][field]?.message && (
                            <div
                              key={field}
                              className="rounded-md border border-red-400 flex items-center py-1 px-2 gap-1 mb-2 w-fit"
                            >
                              <IoIosWarning className="text-red-400" />
                              {props.errors.employment[index][field].message}
                            </div>
                          )
                      )}
                  </Grid>
                  <Grid item xs={10}>
                    <ExperienceCard item={item} />
                  </Grid>
                  <Grid
                    container
                    item
                    xs={2}
                    justifyContent="flex-end"
                    alignItems="flex-start"
                  >
                    <div className="flex items-center gap-2 text-md">
                      <FaEdit
                        fontSize={"20"}
                        color="#003D50"
                        onClick={() => handleEmploymentEdit(index)}
                        style={{
                          cursor: "pointer",
                          width: "30px",
                        }}
                      />

                      <MdDelete
                        color="red"
                        size={22}
                        style={{
                          cursor: "pointer",
                          width: "30px",
                        }}
                        onClick={() => handleEmploymentDelete(index)}
                      />
                    </div>
                  </Grid>
                </Grid>
              ))}
            </div>
          ) : (
            <div className="h-96 flex justify-center items-center flex-col">
              <div>
                <EmptyIcon />
              </div>
              <div className="text-gray-400 text-2xl font-semibold my-4">
                {t("no_work_experience_added")}
              </div>
              <IconButton
                icon={<GoPlus className="text-white" />}
                text={t("add_work_experience")}
                onClick={handleClickOpen}
              />
            </div>
          )}
        </Grid>
      </Grid>
      <ExperienceDialog
        open={open}
        handleClose={handleClose}
        isEmploymentEditing={isEmploymentEditing}
        handleEmploymentEditSave={handleEmploymentEditSave}
        handleSave={handleSave}
        handleChange={handleChange}
        register={register}
        errors={errors}
        handleSubmit={handleSubmit}
        watch={watch}
        setValue={setValue}
        selectedSkills={selectedSkills}
        handleSelect={handleSelect}
        handleRemove={handleRemove}
        loading={loading}
        isFormChanged={isFormChanged}
        onboardingError={props.errors}
        index={employmentEditIndex}
      />
      <DeleteDialog
        open={deleteIndex !== -1}
        handleClose={() => setDeleteIndex(-1)}
        handleDeleteConfirm={handleDeleteConfirm}
      />
    </>
  );
};

export function ExperienceCard({ item }) {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <div className="flex gap-8 items-start">
      <div>
        <ExpIcon />
      </div>
      <div>
        <Typography className={classes.title}>{item.jobTitle}</Typography>
        <Typography className={classes.companyName}>
          {item.company} - {item.jobType}
        </Typography>
        <Typography className={classes.duration}>
          {new Date(item.startDate).toLocaleDateString("en-GB")} -{" "}
          {item.isCurrentlyWorking ? (
            <>{t("now")}</>
          ) : (
            new Date(item.endDate).toLocaleDateString("en-GB")
          )}
        </Typography>
        <Typography className={classes.description}>{item.duties}</Typography>
      </div>
    </div>
  );
}

export function ExperienceDialog({
  open,
  handleClose,
  isEmploymentEditing,
  handleEmploymentEditSave,
  handleSave,
  handleChange,
  register,
  errors,
  handleSubmit,
  watch,
  setValue,
  selectedSkills,
  handleSelect,
  handleRemove,
  loading,
  isFormChanged,
  onboardingError,
  index,
}) {
  const classes = useStyles();
  const { locale, messages } = useContext(LanguageContext);
  const { t } = useTranslation();
  const displayEmploymentErrors = (errors) => {
    if (!errors || !errors.employment) return null;

    return errors.employment.map((error, i) => {
      if (index !== i) return false;
      return (
        <div key={i}>
          {Object.keys(error).map((key) => (
            <div
              key={key}
              className="rounded-md border border-red-400 flex items-center py-1 px-2 gap-1 mb-2 w-fit text-xs"
            >
              <IoIosWarning className="text-red-400" />
              {error[key]?.message}
            </div>
          ))}
        </div>
      );
    });
  };

  return (
    <DialogModal handleClose={handleClose} title={"Add Experience"} open={open}>
      {!!onboardingError && displayEmploymentErrors(onboardingError)}
      <form
        id="employment-dialog"
        onSubmit={
          isEmploymentEditing
            ? handleSubmit(handleEmploymentEditSave)
            : handleSubmit(handleSave)
        }
      >
        <Grid container spacing={2}>
          <Grid item lg={6} xs={12}>
            <TextInput
              label={t("jobTitle")}
              placeholder={"React JS Developer"}
              name="jobTitle"
              regProp={{ ...register("jobTitle") }}
              error={errors.jobTitle?.message}
              required={true}
            />
          </Grid>

          <Grid item lg={6} xs={12}>
            <TextInput
              label={t("company")}
              placeholder={"Remoto"}
              name="company"
              regProp={{ ...register("company") }}
              error={errors.company?.message}
              required={true}
            />
          </Grid>

          {/* <Grid item lg={6} xs={12}>
            <SelectOption
              label={messages[locale].industry}
              id={messages[locale].industry}
              regProp={{ ...register("industry") }}
              options={industries}
              error={errors.industry?.message}
              placeholder={"Select industry"}
            />
          </Grid> */}

          <Grid item lg={6} xs={12}>
            <SelectOption
              label={t("jobType")}
              id={t("jobType")}
              regProp={{ ...register("jobType") }}
              options={[
                "Full Time",
                "Part Time",
                "Contract",
                "Self-Employed",
                "Internship",
                "Freelance",
                "Personal Project",
              ]}
              error={errors.jobType?.message}
              placeholder={"Select job type"}
            />
          </Grid>

          <Grid item lg={6} xs={12}>
            <span className="text-sm font-medium text-slate-700 flex">
              {t("location")}
            </span>

            <Location
              onChange={(e) => setValue("location", e)}
              value={watch("location")}
            />
          </Grid>

          {/* <Grid item lg={6} xs={12}>
            <SelectOption
              label={messages[locale].country}
              id={messages[locale].country}
              regProp={{ ...register("country") }}
              options={countries}
              error={errors.country?.message}
              placeholder={"Select country"}
            />
          </Grid>

          <Grid item lg={6} xs={12}>
            <TextInput
              label={"City"}
              placeholder={"Lisbon"}
              name="city"
              regProp={{ ...register("city") }}
              error={errors.city?.message}
              required={true}
            />
          </Grid> */}

          <Grid item lg={6} xs={12}>
            <span className="text-sm font-medium text-slate-700 flex">
              {t("startDate")}
              <p className="text-sm text-red-500 ml-1">*</p>
            </span>
            <DateSelector
              selectedDate={
                watch("startDate")
                  ? // ? parse(watch("startDate"), "dd/MM/yyyy", new Date())
                    new Date(watch("startDate"))
                  : null
              }
              onChange={(date) =>
                handleChange("startDate", new Date(date).getTime())
              }
              placeholder={"Select Start Date"}
              maxDate={new Date()}
            />
            <p className="text-sm text-red-500">{errors?.startDate?.message}</p>
          </Grid>
          <Grid item lg={6} xs={12}>
            <span className="text-sm font-medium text-slate-700 flex">
              {t("endDate")}

              <p className="text-sm text-red-500 ml-1">*</p>
            </span>
            <DateSelector
              disabled={watch("isCurrentlyWorking")}
              selectedDate={
                watch("endDate")
                  ? // ? parse(watch("endDate"), "dd/MM/yyyy", new Date())
                    new Date(watch("endDate"))
                  : null
              }
              placeholder={"Select End Date"}
              onChange={(date) =>
                handleChange("endDate", new Date(date).getTime())
              }
            />

            <p className="text-sm text-red-500">{errors?.endDate?.message}</p>
          </Grid>
          <Grid item xs={12}>
            <label className="cursor-pointer flex items-center">
              <input
                type="checkbox"
                checked={watch("isCurrentlyWorking")}
                className="checked:accent-secondary-400"
                onChange={() => {
                  setValue("isCurrentlyWorking", !watch("isCurrentlyWorking"));
                }}
              />
              <p className="ml-2">{t("currently_working_here")}</p>
            </label>
          </Grid>

          <Grid item xs={12}>
            <SearchSkill
              selectedSkills={selectedSkills?.map((s) => {
                return { value: s, label: s };
              })}
              handleSelect={handleSelect}
              handleRemove={handleRemove}
            />
          </Grid>

          <Grid item xs={12}>
            <span className="text-sm font-medium text-slate-700 mb-2 flex">
              {/* {messages[locale].dutiesandResponsibilities} */}
              {t("description")}
            </span>
            <TextField
              id="duties"
              placeholder={t("react_js_development")}
              type="text"
              name="duties"
              {...register("duties")}
              style={{ width: "100%" }}
              multiline
              minRows={7}
              className={classes.textField}
              InputProps={{
                borderRadius: "25px",
                disableOutline: true,
                disableUnderline: true,
              }}
            />
          </Grid>
        </Grid>
        <div className="flex items-center gap-4 my-4">
          <SecondaryBtn text={t("cancel")} onClick={handleClose} />
          <PrimaryBtn
            text={isEmploymentEditing ? t("update") : t("save")}
            loading={loading}
            type="submit"
            disabled={isEmploymentEditing ? !isFormChanged() : false}
          />
        </div>
      </form>
    </DialogModal>
  );
}

export function DeleteDialog({
  open,
  handleClose,
  handleDeleteConfirm,
  title = "are_you_sure_to_remove",
}) {
  const { t } = useTranslation();
  return (
    <DialogModal
      handleClose={handleClose}
      open={open}
      size="sm"
      title={t(title)}
      isDelete={true}
    >
      <DialogActions style={{ justifyContent: "flex-start" }}>
        <div className="flex gap-4 items-center mb-4 justify-start">
          <button
            type="button"
            onClick={handleDeleteConfirm}
            className="bg-transparent text-red-400 font-semibold border-2 border-red-400  rounded-md px-3 py-2
        hover:opacity-90 disabled:bg-borderGrey-400 disabled:border-borderGrey-400 disabled:text-white"
          >
            {t("confirm_deletion")}
          </button>

          <button
            onClick={handleClose}
            className={`bg-primary-400 border-2 border-primary-400 text-white font-semibold rounded-md px-3 py-2
        hover:opacity-90 disabled:bg-borderGrey-400 disabled:border-borderGrey-400`}
          >
            {t("cancel")}
          </button>
        </div>
      </DialogActions>
    </DialogModal>
  );
}

export default ExperienceTab;
