import React, { useContext, useEffect, useRef, useState } from "react";
import ResumeTab from "./ResumeTab";
import {
  collection,
  query,
  orderBy,
  where,
  limit,
  getDocs,
  updateDoc,
  doc,
  Timestamp,
} from "firebase/firestore";
import { UserContext } from "../../context/UserContext";
import { COLLECTIONS, firestore, storage } from "../../firebase/config";
import { FaArrowRight } from "react-icons/fa6";
import {
  getBlob,
  getDownloadURL,
  ref,
  uploadBytesResumable,
} from "firebase/storage";
import { parseDataUsingOpenAI, readFile } from "../Utils/FileReader";
import { IconButton, SecondaryIconButton } from "../Global/Buttons";
import { FaArrowLeft } from "react-icons/fa6";
import {
  Grid,
  CircularProgress,
  LinearProgress,
  Box,
  makeStyles,
} from "@material-ui/core";
import TextInput from "../Global/TextInput";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { educationSchema, experienceSchema } from "../../schemas/profile";
import { useLocation, useNavigate } from "react-router-dom";
import { IoSearchOutline } from "react-icons/io5";
import { HiOutlineSparkles } from "react-icons/hi2";
import { FiEye } from "react-icons/fi";
import Resume from "./Onboarding/Resume";
import Review from "./Onboarding/Review";
import Progress from "./Onboarding/Progress";
import { useTranslation } from "react-i18next";
import useCandidateStore from "../../store/candidate";

export const profileSchema = yup.object().shape({
  title: yup.string().required("Title is required").max(36),
  firstName: yup.string().required("First name is required").max(16),
  lastName: yup.string().required("Last name is required").max(16),
  // location: yup.string().required("Location is required"),
  // country: yup
  //   .string()
  //   .required("Country is required")
  //   .max(50, "Country name cannot exceed 50 characters"),
  // city: yup
  //   .string()
  //   .required("City is required")
  //   .max(50, "City name cannot exceed 50 characters"),
  aboutMe: yup
    .string()
    .max(500, "About Me cannot exceed 500 characters")
    .required("About section is required"),
  employment: yup.array().of(experienceSchema),
  education: yup.array().of(educationSchema),
});

const messages = [
  "processing_resume",
  "analyzing_information",
  "delivering_finishing_touches",
  "just_few_more_seconds",
];

const useStyles = makeStyles({
  root: {
    height: 8,
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor: "#E9E9E9",
  },
  bar: {
    borderRadius: 5,
    backgroundColor: "#EA672F",
  },
});

export default function CandidateOnboarding() {
  const [step, setStep] = useState(2);
  const { user } = useContext(UserContext);
  const [userInfo, setUserInfo] = useState(null);
  const [resumeFile, setResumeFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const { candidate, setCandidate, fetchCandidate } = useCandidateStore();
  const [parseData, setParsedData] = useState(null);
  const [messageIndex, setMessageIndex] = useState(0);
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    trigger,
    watch,
    reset,
  } = useForm({
    resolver: yupResolver(profileSchema),
    reValidateMode: "onChange",
    defaultValues: {
      title: "",
      firstName: "",
      lastName: "",
      // location: "",
      aboutMe: "",
    },
  });

  useEffect(() => {
    if (parseData) {
      let data = { ...parseData };
      const employmentItems = data?.employment?.map((item) => ({
        ...item,
        startDate: item.startDate
          ? new Date(item.startDate).getTime()
          : new Date().getTime(),
        endDate: item.endDate
          ? new Date(item.endDate).getTime()
          : new Date().getTime(),
      }));
      const educationItems = data?.education?.map((item) => ({
        ...item,
        startDate: item.startDate
          ? new Date(item.startDate).getTime()
          : new Date().getTime(),
        endDate: item.endDate
          ? new Date(item.endDate).getTime()
          : new Date().getTime(),
      }));
      data = {
        ...data,
        employment: employmentItems,
        education: educationItems,
      };
      reset(data);
    }
  }, [parseData]);

  const handleSave = () => {
    const data = { ...watch() };
    const educationItemsWithStrings = data?.employment?.map((item) => ({
      ...item,
      startDate: Timestamp.fromDate(new Date(item.startDate)),
      endDate: Timestamp.fromDate(new Date(item.endDate)),
    }));

    const employmentItemsWithStrings = data?.education?.map((item) => ({
      ...item,
      startDate: Timestamp.fromDate(new Date(item.startDate)),
      endDate: Timestamp.fromDate(new Date(item.endDate)),
    }));
    const body = {
      employment: employmentItemsWithStrings,
      education: educationItemsWithStrings,
      isOnboarded: true,
      ...data,
    };
    setLoading(true);
    const docRef = doc(firestore, COLLECTIONS.CANDIDATES, userInfo?.id);
    updateDoc(docRef, {
      ...body,
    })
      .then(async () => {
        console.log("done");
        await fetchCandidate(candidate);
        setStep(4);
      })
      .catch((err) => {});
    setLoading(false);
  };

  const handleUpload = async () => {
    if (!resumeFile || typeof resumeFile === "string") {
      setStep(3);
      return;
    }

    let index = 0;
    const messageInterval = setInterval(() => {
      if (index < messages.length - 1) {
        // index = (index + 1) % messages.length; //replays messages
        setMessageIndex(index);
        index++;
      } else {
        setMessageIndex(index);
      }
    }, 3000);

    setLoading(true);

    try {
      const dbRef = collection(firestore, COLLECTIONS.CANDIDATES);
      const q = query(
        dbRef,
        orderBy("postedOn"),
        where("email", "==", user.email),
        limit(1)
      );
      const req = await getDocs(q);
      const userDocRef = req.docs[0].ref;

      const storageRef = ref(storage, `/files/resumes/${user.uid}`);
      const uploadTask = uploadBytesResumable(storageRef, resumeFile);

      await uploadTask.on(
        "state_changed",
        (snapshot) => {},
        (err) => {},
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((url) => {
            updateDoc(userDocRef, {
              resumeUrl: url,
            })
              .then(async () => {
                const result = await readFile(resumeFile);
                const parsingData = await parseDataUsingOpenAI(result);
                setParsedData(parsingData);
                setStep(3);
                // setResumeFile(url ? url : null);

                setLoading(false);

                clearInterval(messageInterval);
              })
              .catch((err) => {
                console.log("🚀 ~ handleUpload ~ err:", err);
                setLoading(false);

                clearInterval(messageInterval);
              });
          });
        }
      );
    } catch (err) {
      console.log("🚀 ~ handleUpload ~ err:", err);
    }
  };

  const setParentFile = (file) => {
    setResumeFile(file);
  };

  const employmentRefs = useRef([]);
  const educationRefs = useRef([]);

  useEffect(() => {
    if (user) {
      const fetchUserData = async () => {
        const dbRef = collection(firestore, COLLECTIONS.CANDIDATES);
        const q = query(
          dbRef,
          orderBy("postedOn"),
          where("email", "==", user.email),
          limit(1)
        );
        const req = await getDocs(q);
        const userDocData = req.docs[0].data();
        // if (userDocData?.dateOfBirth) {
        //   userDocData.dateOfBirth = userDocData?.dateOfBirth.toDate();
        // }
        // if (userDocData.education) {
        //   const employmentItemsWithDates = userDocData.education.map((item) => {
        //     let startDate = null;
        //     let endDate = null;

        //     if (item && item.startDate && item.startDate.toDate) {
        //       startDate = item.startDate.toDate();
        //     }

        //     if (item && item.endDate && item.endDate.toDate) {
        //       endDate = item.endDate.toDate();
        //     }
        //     return {
        //       ...item,
        //       startDate,
        //       endDate,
        //     };
        //   });

        //   userDocData.education = employmentItemsWithDates;
        // }
        // if (userDocData.employment) {
        //   const employmentItemsWithDates = userDocData.employment.map(
        //     (item) => {
        //       let startDate = null;
        //       let endDate = null;

        //       if (item && item.startDate && item.startDate.toDate) {
        //         startDate = item.startDate.toDate();
        //       }

        //       if (item && item.endDate && item.endDate.toDate) {
        //         endDate = item.endDate.toDate();
        //       }
        //       return {
        //         ...item,
        //         startDate,
        //         endDate,
        //       };
        //     }
        //   );

        //   userDocData.employment = employmentItemsWithDates;
        // }
        if (userDocData?.isOnboarded) {
          navigate("/jobs");
        }

        setUserInfo({ id: req.docs[0].id, ...userDocData, resumeUrl: null });
        // setResumeFile(userDocData?.resumeUrl ?? null);
        // reset(userDocData);
      };
      fetchUserData();
    }
  }, [user]);

  const handleBackClick = () => {
    setResumeFile(null);
    setStep(2);
  };

  const renderStep = () => {
    switch (step) {
      case 2:
        return (
          <Resume
            userInfo={userInfo}
            loading={loading}
            setParentFile={setParentFile}
            handleUpload={handleUpload}
            resumeFile={resumeFile}
          />
        );
      case 3:
        return (
          <Review
            watch={watch}
            register={register}
            errors={errors}
            handleSave={handleSave}
            handleSubmit={handleSubmit}
            setValue={setValue}
            employmentRefs={employmentRefs}
            educationRefs={educationRefs}
            trigger={trigger}
            loading={loading}
            handleBackClick={handleBackClick}
          />
        );
      default:
        return <Hired setStep={setStep} />;
    }
  };

  return (
    <div className="min-h-screen ">
      <div className="w-4/5 mx-auto flex flex-col items-center gap-12 pt-16">
        <Progress step={step} />
      </div>
      {loading ? (
        <LoadingComponent index={messageIndex} />
      ) : (
        <div>{renderStep()}</div>
      )}
    </div>
  );
}

function LoadingComponent({ index }) {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div className="flex justify-center items-center flex-col gap-6 mt-24 w-1/3 mx-auto">
      {index === 0 ? (
        <FiEye className="w-14 h-14" />
      ) : index === 1 ? (
        <IoSearchOutline className="w-14 h-14" />
      ) : (
        <HiOutlineSparkles className="w-14 h-14" />
      )}
      <div className="text-[42px] font-semibold w-full mx-auto text-center">
        {t(messages[index])}
      </div>
      <Box sx={{ width: "100%" }}>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Box sx={{ width: "100%" }}>
            <LinearProgress
              variant="determinate"
              value={
                index === 0 ? 25 : index === 1 ? 40 : index === 2 ? 80 : 100
              }
              classes={{
                root: classes.root,
                colorPrimary: classes.colorPrimary,
                bar: classes.bar,
              }}
            />
          </Box>
        </Box>
      </Box>
    </div>
  );
}

function Hired({ setStep }) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  return (
    <>
      <div className="text-3xl my-12 font-semibold w-3/5 mx-auto text-center">
        <span className="text-primary-400">{t("you_made_it")}</span>{" "}
        {t("welcome_remoto")}
      </div>
      <div className="w-full flex justify-center gap-4 items-center">
        <SecondaryIconButton
          text={t("go_back_review")}
          icon={<FaArrowLeft className="text-primary-400" />}
          onClick={() => setStep(3)}
        />
        <IconButton
          icon={<FaArrowRight className="text-white" />}
          text={t("next_step_get_started")}
          iconFirst={false}
          onClick={() => navigate("/jobs")}
        />
      </div>
    </>
  );
}
