import { Box, Grid } from "@material-ui/core";
import React, { useContext, useState } from "react";
import { IoFlash } from "react-icons/io5";
import { FaCheckCircle } from "react-icons/fa";
import PrimaryBtn, { SecondaryBtn } from "../Global/Buttons";
import { handleEarlyBirdSubscription } from "../../utils/payments";
import CompanyHeader from "../Header/CompanyHeader";
import Header from "../Header/index";
import { auth } from "../../firebase/config";
import VerifyEmailPopup from "../Global/VerifyEmailPopup";
import { UserContext } from "../../context/UserContext";
import { useNavigate } from "react-router-dom";
import { getPremiumStatus } from "../../utils/getPremiumStatus";
import DialogModal from "../Global/Dialog";
import useCompanyStore from "../../store/company";
export default function EarlyBirdPage() {
  const [loading, setLoading] = useState(false);
  const [isNotVerified, setIsNoVerified] = useState(false);
  const { premiumPlan } = useContext(UserContext);
  const { company } = useCompanyStore();
  const navigate = useNavigate();
  const [signupModal, setSignupModal] = useState(false);

  if (premiumPlan === "earlybird") {
    navigate("/jobs");
  }
  if (auth.currentUser && !company) {
    navigate("/jobs");
  }
  return (
    <Box>
      {isNotVerified && (
        <VerifyEmailPopup
          open={isNotVerified}
          handleClose={() => setIsNoVerified(false)}
          message={"Verify your email to get early bird subscription!"}
        />
      )}
      {signupModal && (
        <DialogModal
          handleClose={() => setSignupModal(false)}
          open={signupModal}
          title="Sign Up"
          size="sm"
        >
          <div>
            <h1 className="text-xl font-medium">
              Become our member by signing up for free
            </h1>
          </div>
          <div className="flex items-center gap-4 my-4">
            <SecondaryBtn
              text={"Close"}
              onClick={() => setSignupModal(false)}
            />
            <PrimaryBtn
              text={"Sign up"}
              onClick={() => navigate("/remoter/register")}
            />
          </div>
        </DialogModal>
      )}
      {auth?.currentUser ? (
        <CompanyHeader />
      ) : (
        <Grid style={{ background: "#E9E4EE" }}>
          <Header />
        </Grid>
      )}

      <Box className="py-24 w-5/6 md:w-4/6 mx-auto flex flex-col items-center">
        <div className="text-sm w-fit rounded-2xl border border-green-400 p-2 flex gap-2 items-center bg-[#EAF8F5]">
          <IoFlash style={{ color: "#2CBA99" }} />

          <h1>You are eligible for the Early Bird Offer!</h1>
        </div>

        <div className="mt-4">
          <h1 className="text-3xl md:text-[44px] lg:text-5xl font-semibold text-center">
            Get the Early Bird Offer & Unlock Unlimited Access!
          </h1>
          <p className="mt-6 text-xl md:text-2xl font-medium text-center">
            The first 100 companies to register get unlimited access to premium
            features. Elevate your business with this limited-time opportunity.
            Secure your spot now!
          </p>
        </div>
        <div className="mt-6 w-full">
          <div className="border-2 border-secondary-400 rounded-lg text-center">
            <div className="bg-secondary-400 rounded-tr-md rounded-tl-md text-sm italic text-white py-1 font-medium">
              LIMITED SPOTS AVAILABLE!
            </div>
            <div class="grid grid-cols-1 md:grid-cols-3 md:gap-4 w-full max-w-5xl text-left">
              <div class="bg-white px-4 md:py-4 flex justify-start md:justify-center rounded-md">
                <div class="flex space-y-4 my-4 flex-col">
                  <div class="flex items-center space-x-2">
                    <FaCheckCircle className="text-green-400" />
                    <span>AI Candidate Matching</span>
                  </div>

                  <div class="flex items-center space-x-2">
                    <FaCheckCircle className="text-green-400" />
                    <span>Unlimited Candidate Unlocks</span>
                  </div>

                  <div class="flex items-center space-x-2">
                    <FaCheckCircle className="text-green-400" />
                    <span>Premium Customer Support</span>
                  </div>
                </div>
              </div>
              <div class="bg-white px-4 md:py-4 flex justify-start md:justify-center rounded-md">
                <div class="flex space-y-4 mb-4 md:my-4 flex-col">
                  <div class="flex items-center space-x-2">
                    <FaCheckCircle className="text-green-400" />
                    <span>Unlimited Job Postings</span>
                  </div>
                  <div class="flex items-center space-x-2">
                    <FaCheckCircle className="text-green-400" />
                    <span>Early Access to Beta Features</span>
                  </div>
                  {/* <div class="flex items-center space-x-2">
                    <FaCheckCircle className="text-green-400" />
                    <span>Community Access</span>
                  </div>
                  <div class="flex items-center space-x-2">
                    <FaCheckCircle className="text-green-400" />
                    <span>Community Access</span>
                  </div>
                  <div class="flex items-center space-x-2">
                    <FaCheckCircle className="text-green-400" />
                    <span>Community Access</span>
                  </div> */}
                </div>
              </div>
              <div class="bg-white p-4 flex flex-col justify-center rounded-md gap-5">
                <div className="text-sm w-fit rounded-2xl border border-green-400 p-2 flex gap-2 items-center bg-[#EAF8F5]">
                  <IoFlash style={{ color: "#2CBA99" }} />

                  <h1>Subscribe</h1>
                </div>
                <div className="text-left flex items-center gap-2">
                  <h1 className="text-3xl font-semibold text-secondary-400">
                    €100.00
                  </h1>
                  <span className="text-sm">
                    {/* Per Month */}
                    {/* <br /> */}
                    <span className="text-xs">For the first year</span>
                  </span>
                </div>
                <div className="text-left">
                  <PrimaryBtn
                    text={"Unlock Access Today!"}
                    onClick={async () => {
                      if (!auth.currentUser) {
                        setSignupModal(true);
                        return;
                      }
                      if ((await getPremiumStatus()) === "earlybird") {
                        alert("Already subscribed");
                        return;
                      }
                      if (auth && !auth.currentUser.emailVerified) {
                        setIsNoVerified(true);
                        return;
                      }
                      setLoading(true);
                      await handleEarlyBirdSubscription();
                      setLoading(false);
                    }}
                    loading={loading}
                    disabled={loading}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Box>
    </Box>
  );
}
