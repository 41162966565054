import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { makeStyles } from "@material-ui/core";
import "../../style.css";

const useStyles = makeStyles((theme) => ({
  calender: {
    background: "white",
    marginTop: "10px",
    border: "1px solid #ECECEC",
    borderRadius: "10px",
    width: "100%",
    padding: "8px 12px",
  },
}));

export default function DateSelector({
  disabled = false,
  selectedDate,
  onChange,
  maxDate,
  placeholder,
}) {
  const classes = useStyles();
  return (
    <div>
      <DatePicker
        disabled={disabled}
        selected={selectedDate ? new Date(selectedDate) : null}
        onChange={(date) => {
          if (date) {
            onChange(date);
          }
        }}
        placeholderText={placeholder ? placeholder : ""}
        dateFormat="dd/MM/yyyy"
        className={classes.calender}
        maxDate={maxDate ? new Date(maxDate) : undefined}
      />
    </div>
  );
}
