import React, { useState, useContext } from "react";
import { LanguageContext } from "../../Languages/LanguageContext";
import { Grid, makeStyles } from "@material-ui/core";
import LanguageChange from "../../Languages/LanguageChange";
import RemotocoLogo from "../../Assets/remotologo.svg";
import RemotoPlusLogo from "../../Assets/remoto-plus.svg";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ProfileIcon from "../../Assets/profile.svg";
import LogoutIcon from "../../Assets/logout.svg";
import { firestore, COLLECTIONS, auth } from "../../firebase/config";

import { signOut } from "firebase/auth";
import ProfileAvatar from "../../Assets/profileavatar.svg";
import {
  collection,
  query,
  orderBy,
  where,
  limit,
  getDocs,
} from "firebase/firestore";
import Avatar from "@material-ui/core/Avatar";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { UserContext } from "../../context/UserContext";
import useCompanyStore from "../../store/company";
import Notifications from "../Notification";
import { IoMdSettings } from "react-icons/io";
import { FaCrown } from "react-icons/fa";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  companyIcon: {
    maxWidth: "100%",
    height: "auto",
    [theme.breakpoints.down("sm")]: {
      width: "70px",
    },
  },
  logo: {
    color: "#826AA6",
    cursor: "pointer",
  },

  canvas: {
    borderBottom: "1px solid #D1D1D1",
    background: "#FFFFFF",
    height: "96px",
  },
  emptyChip: {
    padding: "8px 16px ",
    fontSize: "16px",
    borderRadius: "25px",
    transition: ".3s",
    fontWeight: 700,
    marginRight: "15px",
    border: `1px solid #003D50`,
    color: "#003D50",
    letterSpacing: "-0.02em",
    textDecoration: "none",
  },

  filledChip: {
    padding: "8px 16px ",
    fontSize: "16px",
    borderRadius: "25px",
    transition: ".3s",
    fontWeight: 700,
    marginRight: "15px",
    border: `1px solid white`,
    color: "white",
    background: "#003D50",
    letterSpacing: "-0.02em",
    textDecoration: "none",
  },

  profileInitials: {
    width: "75px",
    height: "75px",
  },
  menu: {
    borderRadius: "20px",
    marginTop: theme.spacing(1),
  },
  icon: {
    minWidth: "30px",
  },
  meuItemText: {
    color: "#555555",
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "24px",
    letterSpacing: "-0.02em",
  },
  formControl: {
    borderRadius: "25px",
    border: `1px solid #003D50`,
    padding: "0px 8px",
    marginRight: "15px",

    "& .MuiSelect-root": {
      borderBottom: "none",
      color: "#003D50",
      fontSize: "16px",
    },
    "& .MuiInput-underline:before": {
      borderBottom: "none",
    },
    "& .MuiInput-underline:after": {
      borderBottom: "none",
    },
    "&:hover .MuiInput-underline:before": {
      borderBottom: "none",
    },
    "&:hover .MuiInput-underline:after": {
      borderBottom: "none",
    },
  },
}));

function CompanyHeader() {
  const { premiumPlan } = useContext(UserContext);
  const { company, setCompany } = useCompanyStore();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const handleLogoClick = () => {
    navigate("/profile");
  };

  const classes = useStyles();
  const location = useLocation();
  const pathname = location.pathname;

  return (
    <>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        className={classes.canvas}
      >
        <Grid container item lg={10} xs={10}>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid
              item
              xs={4}
              md={2}
              className={classes.logo}
              onClick={handleLogoClick}
            >
              <div className="flex items-center gap-2">
                {premiumPlan && premiumPlan === "earlybird" ? (
                  <img
                    src={RemotoPlusLogo}
                    className={classes.companyIcon}
                    alt="remoto logo"
                  />
                ) : (
                  <img
                    src={RemotocoLogo}
                    className={classes.companyIcon}
                    alt="remoto logo"
                  />
                )}

                {/* {premiumPlan && premiumPlan === "earlybird" && (
                  <FaCrown className="text-orange-400" />
                )} */}
              </div>
            </Grid>
            <Grid
              container
              item
              md={8}
              alignItems="center"
              className="justify-center"
            >
              <div className="hidden lg:flex items-center w-2/3 justify-between max-w-2xl">
                {/* <LinkComponent
                  to={"/"}
                  title="Dashboard"
                  active={pathname === "/"}
                /> */}
                <LinkComponent
                  to={"/profile"}
                  title={t("profile")}
                  active={pathname === "/profile"}
                />
                <LinkComponent
                  to={"/jobs"}
                  title={t("jobs")}
                  active={
                    pathname.includes("jobs") || pathname.includes("job")
                    // pathname === "/jobs" ||
                    // pathname === "/post-job" ||
                    // (pathname.includes("jobs") && pathname.includes("edit"))
                  }
                />
                <LinkComponent
                  to={"/applications"}
                  title={t("applicants")}
                  active={
                    pathname === "/applicants" ||
                    pathname.includes("applications")
                  }
                />
                {/* <LinkComponent
                  to={"/community"}
                  title="Community"
                  active={pathname === "/community"}
                /> */}
              </div>
            </Grid>

            <Grid item xs={4} md={2}>
              <div className="flex items-center justify-center">
                <Grid item>
                  <LanguageChange />
                </Grid>
                <Grid>
                  <Notifications />
                </Grid>
                {/* <Grid>
                  <IoMdSettings
                    className="text-primary-400 cursor-pointer text-2xl mx-2"
                    onClick={() => navigate("/settings")}
                  />
                </Grid> */}
                <NavbarDropdown
                  data={company}
                  isCompany={true}
                  clearStore={() => setCompany(null)}
                />
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
function LinkComponent({ to, title, active }) {
  return (
    <Link
      to={to}
      className={`font-medium text-lg cursor-pointer  transition duration-2000 py-1   rounded-lg ${
        active
          ? "text-white bg-primary-400 hover:text-white px-4"
          : "bg-transparent hover:text-primary-400 px-2"
      }`}
    >
      {title}
    </Link>
  );
}

export function NavbarDropdown({ data, isCompany, clearStore }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const classes = useStyles();
  const navigate = useNavigate();
  const { locale, messages } = useContext(LanguageContext);
  const { setUser } = useContext(UserContext);
  const { t } = useTranslation();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSignOut = () => {
    signOut(auth)
      .then(() => {
        // Sign-out successful.
        // navigate(`/${locale}`);
        setUser(null);
        clearStore();
        navigate("/login");
      })
      .catch((error) => {});
  };

  return (
    <Grid item justifyContent="flex-end" alignItems="center">
      <Avatar
        src={data?.imageUrl ? data?.imageUrl : ProfileAvatar}
        onClick={handleClick}
        width={30}
        height={30}
        alt="profile"
        style={{
          cursor: "pointer",
          marginLeft: "8px",
          border: "1px solid lightgray",
        }}
      />
      <Grid container item>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
          className={classes.menu}
          PaperProps={{
            style: {
              marginTop: "48px",
              borderRadius: "8px",
              border: "1px solid #E9E4EE",
              boxShadow: "0px 1px 3px 0px #1018281A",
              width: "220px",
            },
          }}
          MenuListProps={{
            style: {
              paddingTop: 0,
              paddingBottom: 0,
            },
          }}
          transformOrigin={{
            horizontal: "right",
            vertical: "top",
          }}
          anchorOrigin={{
            horizontal: "right",
            vertical: "bottom",
          }}
        >
          <MenuItem>
            <div className="flex items-center gap-1">
              <Avatar
                src={data?.imageUrl ? data?.imageUrl : ProfileAvatar}
                onClick={handleClick}
                width={30}
                height={30}
                alt="profile"
                style={{
                  cursor: "pointer",
                  border: "1px solid lightgray",
                }}
              />
              <div className="flex flex-col">
                <h3 className="text-sm font-bold">
                  {isCompany
                    ? data?.companyName
                    : data?.firstName + " " + data?.lastName}
                </h3>
                <p className="-mt-1 text-sm text-borderGrey-600 w-5/6 text-ellipsis overflow-hidden">
                  {data?.email}
                </p>
              </div>
            </div>
          </MenuItem>
          <hr color="#ECECEC" />

          <MenuItem onClick={() => navigate("/profile")}>
            {messages[locale].profile}
          </MenuItem>
          <hr color="#ECECEC" />
          <MenuItem onClick={() => navigate("/settings")}>
            {t("settings")}
          </MenuItem>
          <hr color="#ECECEC" />
          <MenuItem onClick={handleSignOut}>{messages[locale].logout}</MenuItem>
        </Menu>
      </Grid>
    </Grid>
  );
}
export default CompanyHeader;
