import { create } from "zustand";

const useAppliedJobs = create((set, getState) => ({
  appliedJobs: [],
  setAppliedJobs: (jobs) => set({ appliedJobs: jobs }),

  // Function to add a new saved item
  addAppliedJob: (item) =>
    set((state) => ({ appliedJobs: [...state.appliedJobs, item] })),

  // Function to remove a saved item
  removeAppliedJob: (itemId) =>
    set((state) => ({
      appliedJobs: state.appliedJobs.filter((item) => item !== itemId),
    })),

  // Function to check if an item is saved
  isItemApplied: (itemId) =>
    getState().appliedJobs.some((item) => item === itemId),
}));

export default useAppliedJobs;
