import React, { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import {
  doc,
  updateDoc,
  collection,
  where,
  getDocs,
  query,
  Timestamp,
  orderBy,
  limit,
  getDoc,
} from "firebase/firestore";
import { auth, COLLECTIONS, firestore } from "../firebase/config";
import { stripePriceIds } from "../utils/constants";
import { BsFillPatchCheckFill } from "react-icons/bs";
import Loader from "../components/Loader";
import { trackEvent } from "../analytics";
import { sendUnlockNotification } from "../utils/commanFunctions";
const SuccessPage = () => {
  const location = useLocation();
  const [sessionId, setSessionId] = useState(null);
  const [success, setSuccess] = useState(-1);
  const user = auth.currentUser;

  useEffect(() => {
    const fetchSession = async () => {
      if (user) {
        const queryParams = new URLSearchParams(location.search);

        const sessionId = queryParams.get("session_id");
        setSessionId(sessionId);

        if (sessionId) {
          const checkoutRef = collection(
            firestore,
            `customers/${user.uid}/checkout_sessions`
          );
          const q = query(checkoutRef, where("sessionId", "==", sessionId));
          const reqC = await getDocs(q);
          console.log("🚀 ~ fetchSession ~ reqC:", reqC.docs[0].data());

          if (reqC.docs.length > 0) {
            const filteredDocs = reqC.docs.filter((doc) => {
              const data = doc.data();
              return data.is_expired !== true;
            });

            if (filteredDocs.length > 0) {
              console.log("Filtered documents:", filteredDocs);
              const data = filteredDocs[0].data();
              console.log("🚀 ~ fetchSession ~ data:", data);

              // unlock candidate
              // if (data?.metadata && data?.price === stripePriceIds[3]) {
              if (data?.metadata && data?.line_items?.length > 0) {
                setSuccess(1);
                const apps = data.metadata.applicationId;
                const parsedIds = JSON.parse(apps);
                const updatePromises = parsedIds.map(async (applicationId) => {
                  const docRef = doc(
                    firestore,
                    COLLECTIONS.APPLICATIONS,
                    applicationId
                  );
                  const docSnap = await getDoc(docRef);
                  if (docSnap.exists()) {
                    const applicationData = docSnap.data();

                    sendUnlockNotification(
                      applicationData.jobId,
                      applicationId,
                      applicationData.candidateEmail,
                      applicationData.jobTitle
                    );

                    return updateDoc(docRef, {
                      isLocked: false,
                    });
                  }
                });

                try {
                  await Promise.all(updatePromises);
                  // Assume there is only one document returned by the query
                  trackEvent(
                    `Company Activity`,
                    "Unlock Candidate",
                    `Application Info: ${apps}`
                  );

                  const docRef = doc(
                    firestore,
                    `customers/${user.uid}/checkout_sessions/${reqC.docs[0].id}`
                  );

                  await updateDoc(docRef, { is_expired: true });

                  if (window.opener) {
                    // Notify the parent window of the success
                    window.opener.postMessage("checkoutSuccess", "*");
                    // Close the current window
                    window.close();
                  }

                  // navigate("/jobs");
                } catch (err) {
                  console.error("Error deleting documents:", err);
                }
              }
              //early bird
              else if (data?.price === stripePriceIds[2]) {
                setSuccess(2);
                const dbRef = collection(firestore, COLLECTIONS.COMPANIES);
                const q = query(
                  dbRef,
                  orderBy("postedOn"),
                  where("email", "==", user.email),
                  limit(1)
                );
                const req = await getDocs(q);
                if (!req.empty) {
                  const userDocRef = req.docs[0].ref;
                  const createdDate = data?.created?.toDate();
                  const endDate = new Date(createdDate);
                  endDate.setFullYear(endDate.getFullYear() + 1);

                  await updateDoc(userDocRef, {
                    subscription: {
                      status: "earlyBirdPremium",
                      cancel_at: Timestamp.fromDate(endDate),
                    },
                  })
                    .then(async (d) => {
                      console.log("added");
                      trackEvent(
                        `Company Activity`,
                        "Early Bird Subscription",
                        `Company Info: ${d?.id}`
                      );
                      // Assume there is only one document returned by the query
                      const docRef = doc(
                        firestore,
                        `customers/${user.uid}/checkout_sessions/${reqC.docs[0].id}`
                      );

                      await updateDoc(docRef, { is_expired: true });

                      if (window.opener) {
                        // Notify the parent window of the success
                        window.opener.postMessage("earlyBirdSuccess", "*");
                        // Close the current window
                        window.close();
                      }
                    })
                    .catch((err) => {});
                }
              }
            }
          }
        }
      }
    };
    fetchSession();
  }, [location.search, user]);

  return (
    <div className="min-h-screen w-full flex justify-center items-center">
      {sessionId ? (
        <div className="gap-6 flex flex-col items-center">
          <BsFillPatchCheckFill className="text-secondary-400 text-5xl" />
          {success === 1 ? (
            <h1 className="text-primary-400 text-3xl font-bold flex items-center gap-2">
              Candidate Unlocked
            </h1>
          ) : success === 2 ? (
            <h1 className="text-primary-400 text-3xl font-bold flex items-center gap-2">
              You are on Remoto{" "}
              <span className="italic text-white text-2xl bg-secondary-400 font-medium rounded-md p-1 pr-2">
                PLUS
              </span>
            </h1>
          ) : (
            <></>
          )}
        </div>
      ) : (
        <Loader />
      )}
    </div>
  );
};

export default SuccessPage;
