import React, { useState, useEffect, useContext } from "react";
import { LanguageContext } from "../../Languages/LanguageContext";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import countries from "../Data/Countries";
import industries from "../Data/Industries";
import nationalities from "../Data/Nationalities";
import skillList from "../Data/SkillList";
import { makeStyles } from "@material-ui/core/styles";
import { CloseCircle as CancelIcon } from "iconsax-react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DeleteAccountConfirmationDialog from "../Utils/DeleteAccountConfirmationDialog";
import theme from "../../theme/theme";
import {
  TextField,
  Button,
  FormControl,
  MenuItem,
  Select,
  Grid,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  Slide,
  CircularProgress,
  Box,
  ThemeProvider,
} from "@material-ui/core";

import ProfileAvatar from "../../Assets/profileavatar.svg";
import ProfileHeader from "../Header/ProfileHeader";
import { firestore, COLLECTIONS } from "../../firebase/config";
import "@fontsource/work-sans";
import { auth, storage } from "../../firebase/config";
import { deleteUser } from "firebase/auth";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import EditItemIcon from "../../Assets/editgreen.svg";
import { Trash as DeleteIcon } from "iconsax-react";

import {
  collection,
  serverTimestamp,
  query,
  orderBy,
  where,
  limit,
  getDocs,
  updateDoc,
  doc,
  deleteDoc,
} from "firebase/firestore";
import Avatar from "@material-ui/core/Avatar";
import Footer from "../Footer/Footer";
import { DocumentText as ResumeIcon } from "iconsax-react";
const useStyles = makeStyles((theme) => ({
  wrapper: {
    justifyContent: "center",
    alignItems: "center",

    background: "#E9E4EE",
  },
  formControl: {
    background: "white",
    marginTop: "10px",
    border: "1px solid #ECECEC",
    width: "80%",
    padding: "8px 32px",
    "& .MuiSelect-root": {
      backgroundColor: "white",
      borderBottom: "none",
    },
    "& .MuiInput-underline:before": {
      borderBottom: "none",
    },
    "& .MuiInput-underline:after": {
      borderBottom: "none",
    },
    "&:hover .MuiInput-underline:before": {
      borderBottom: "none",
    },
    "&:hover .MuiInput-underline:after": {
      borderBottom: "none",
    },
  },
  heading: {
    fontWeight: 600,
    fontSize: "24px",
    lineHeight: "24px",
    letterSpacing: "-0.02em",
    [theme.breakpoints.down("sm")]: {
      fontSize: "20px", // Font size for mobile (screens smaller than or equal to small breakpoint)
      lineHeight: "24px",
    },
  },
  textField: {
    background: "white",
    marginTop: "10px",
    border: "1px solid #ECECEC",
    width: "100%",
    "& .MuiInputBase-input": {
      padding: "16px 32px",

      color: theme.palette.text.primary,
      fontWeight: 400,

      borderBottom: "none",
      outline: "none",

      backgroundColor: "none",
    },
  },
  label: {
    fontWeight: 600,
    fontSize: "16px",
    lineHeight: "19px",
    color: "#000000",
    letterSpacing: "-0.02em",
    marginLeft: "32px",
  },
  input: {
    borderRadius: "25px",
    disableOutline: true,
    disableUnderline: true,
  },
  button: {
    backgroundColor: "#003D50",
    textTransform: "unset",
    color: "white",
    padding: "16px 32px",
    disableAnimation: true,
    fontSize: "16px",
    fontWeight: "700",
    "&:hover": { backgroundColor: "#003D50" },
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px", // Font size for mobile (screens smaller than or equal to small breakpoint)
      padding: "8px 16px",
    },
  },
  whiteButtonDialog: {
    backgroundColor: "transparent",
    textTransform: "unset",
    color: "#003D50",
    padding: "16px 32px",
    disableAnimation: true,
    fontSize: "16px",
    fontWeight: "700",
    marginRight: "8px",
    border: "1px solid #003D50",
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px", // Font size for mobile (screens smaller than or equal to small breakpoint)
      padding: "8px 16px",
    },
  },
  divider: {
    color: "#ECECEC",
  },
  item: {
    marginTop: "30px",
  },
  placeholder: {
    color: "#999",
    fontStyle: "normal",
  },
  calender: {
    background: "white",
    marginTop: "10px",
    border: "1px solid #ECECEC",
    borderRadius: "10px",
    width: "75%",
    padding: "16px 32px",
  },

  educationTitle: {
    fontWeight: 600,
    fontSize: "20px",
    lineHeight: "24px",
    color: "#000000",
    letterSpacing: "-0.02em",
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px", // Font size for mobile (screens smaller than or equal to small breakpoint)
      lineHeight: "24px",
    },
  },
  educationMajor: {
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "24px",
    color: "#000000",
    letterSpacing: "-0.02em",
    marginTop: "4px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px", // Font size for mobile (screens smaller than or equal to small breakpoint)
      lineHeight: "24px",
    },
  },
  educationDuration: {
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "24px",
    color: "#000000",
    letterSpacing: "-0.02em",
    marginTop: "4px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px", // Font size for mobile (screens smaller than or equal to small breakpoint)
      lineHeight: "24px",
    },
  },
  skillChip: {
    margin: theme.spacing(0.5),
    fontSize: "14px",
    padding: "4px 8px",
    borderRadius: "5px",
    transition: ".3s",
    fontWeight: 400,
    marginRight: "10px",
    border: `1px solid #003D50`,
    color: "#003D50",
  },
  profilePicture: {
    marginTop: "20px",
    marginLeft: "20px",
    cursor: "pointer",
    [theme.breakpoints.down("sm")]: {
      width: "100px",
      height: "1000px",
    },
  },
}));

const EditProfile = () => {
  const classes = useStyles();
  const { locale, messages } = useContext(LanguageContext);
  const [user, setUser] = useState(null);
  const [userId, setUserId] = useState("");
  const [buttonLoading, setButtonLoading] = useState(false);
  const [loading, setLoading] = useState(true);
  const [imageFile, setImageFile] = useState(null);
  const [imageUrl, setImageUrl] = useState("");
  const [resumeUrl, setResumeUrl] = useState("");
  const [isResumeLocal, setIsResumeLocal] = useState(false);
  const [title, setTitle] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");

  const [dateOfBirth, setDateofBirth] = useState(new Date());
  const [country, setCountry] = useState("");
  const [city, setCity] = useState("");
  const [nationality, setNationality] = useState("");
  const [timezone, setTimezone] = useState("");
  const [currency, setCurrency] = useState("");
  const [minSalaryExpectancy, setMinSalaryExpectancy] = useState("");
  const [salaryFrequency, setSalaryFrequency] = useState("annually");
  const [linkedInUrl, setLinkedInUrl] = useState("");
  const [portfolioUrl, setPortfolioUrl] = useState("");
  const [skills, setSkills] = useState("");
  const [aboutMe, setAboutMe] = useState("");

  const [chipSkills, setChipSkills] = useState([]);

  const fileInput = React.useRef(null);
  const fileInputResume = React.useRef(null);
  const [educationOpen, setEducationOpen] = useState(false);
  const [isEducationEditing, setIsEducationEditing] = useState(false);
  const [educationEditIndex, setEducationEditIndex] = useState(null);
  const [EducationItem, setEducationItem] = useState({
    educationLevel: "",
    degree: "",
    institute: "",
    major: "",
    startDate: new Date().toLocaleDateString(),
    endDate: new Date().toLocaleDateString(),
  });
  const [resumeFile, setResumeFile] = useState(null);

  const [EducationItems, setEducationItems] = useState([]);
  const [isEmploymentEditing, setIsEmploymentEditing] = useState(false);
  const [employmentEditIndex, setEmploymentEditIndex] = useState(null);
  const [open, setOpen] = useState(false);
  const [item, setItem] = useState({
    jobTitle: "",
    company: "",
    industry: "",
    jobType: "",
    country: "",
    city: "",
    startDate: new Date().toLocaleDateString(),
    endDate: new Date().toLocaleDateString(),
    duties: "",
    chipSkills: [],
    isCurrentlyWorking: false,
  });
  const [isDeleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [isCurrentlyWorking, setIsCurrentlyWorking] = useState(false);
  const [items, setItems] = useState([]);

  // Calculate the maximum date (today's date minus 1 day)
  const maxDate = new Date();
  maxDate.setDate(maxDate.getDate());

  const handleDefaultImageClick = () => {
    fileInput.current.click();
  };
  const handleResumeButtonClick = () => {
    fileInputResume.current.click();
  };
  const handleImageInput = (file) => {
    setImageFile(file);
    setImageUrl(null);
  };
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setResumeUrl(URL.createObjectURL(file));
    setResumeFile(file);
    setIsResumeLocal(true);
  };

  const handleClickOpen = () => {
    setOpen(true);
    setItem({
      jobTitle: "",
      company: "",
      industry: "",
      jobType: "",
      country: "",
      city: "",
      startDate: new Date(),
      endDate: new Date(),
      duties: "",
      chipSkills: [],
    });
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSave = (event) => {
    if (event.target.id === "employment-dialog") {
      event.preventDefault();
      setItems([...items, item]);
      setIsCurrentlyWorking(false);
      setOpen(false);
    }
  };
  const handleSkillChange = (event, value) => {
    setChipSkills(value);
  };
  const handleRemoveSkill = (skillToRemove) => {
    const updatedSkills = chipSkills.filter((skill) => skill !== skillToRemove);
    setChipSkills(updatedSkills);
  };

  const handleChange = (name, value) => {
    if (name === "startDate") {
      setItem({
        ...item,
        startDate: value,
      });
    } else if (name === "endDate") {
      setItem({
        ...item,
        endDate: value,
      });
    } else {
      setItem({
        ...item,
        [name]: value,
      });
    }
  };
  const handleEmploymentEdit = (index) => {
    setItem(items[index]);
    setIsCurrentlyWorking(items[index].isCurrentlyWorking);
    setEmploymentEditIndex(index);
    setIsEmploymentEditing(true);
    setOpen(true);
  };

  const handleEmploymentDelete = (indexToRemove) => {
    const shouldDelete = window.confirm(
      "Are you sure you want to delete this item?"
    );

    if (shouldDelete) {
      const updatedItems = items.filter(
        (item, index) => index !== indexToRemove
      );
      setItems(updatedItems);
    }
  };

  const handleEmploymentEditSave = (event) => {
    if (event.target.id === "employment-dialog") {
      event.preventDefault();
      const updatedItems = [...items];
      updatedItems[employmentEditIndex] = item;
      setItems(updatedItems);

      setIsCurrentlyWorking(false);
      setIsEmploymentEditing(false);
      setOpen(false);
    }
  };

  const handleEducationClickOpen = () => {
    setEducationOpen(true);
    setEducationItem({
      educationLevel: "",
      degree: "",
      institute: "",
      major: "",
      startDate: new Date(),
      endDate: new Date(),
    });
  };

  const handleEducationClose = () => {
    setEducationOpen(false);
  };

  const handleEducationSave = (event) => {
    if (event.target.id === "education-dialog") {
      event.preventDefault();
      setEducationItems([...EducationItems, EducationItem]);
      setEducationOpen(false);
    }
  };

  const handleEducationChange = (name, value) => {
    if (name === "startDate") {
      setEducationItem({
        ...EducationItem,
        startDate: value,
      });
    } else if (name === "endDate") {
      setEducationItem({
        ...EducationItem,
        endDate: value,
      });
    } else {
      setEducationItem({
        ...EducationItem,
        [name]: value,
      });
    }
  };
  const handleEducationEdit = (index) => {
    setEducationItem(EducationItems[index]);

    setEducationEditIndex(index);
    setIsEducationEditing(true);
    setEducationOpen(true);
  };
  const handleEducationDelete = (indexToRemove) => {
    const shouldDelete = window.confirm(
      "Are you sure you want to delete this education item?"
    );

    if (shouldDelete) {
      const updatedItems = EducationItems.filter(
        (item, index) => index !== indexToRemove
      );
      setEducationItems(updatedItems);
    }
  };

  const handleEducationEditSave = async (event) => {
    if (event.target.id === "education-dialog") {
      event.preventDefault();
      const updatedItems = [...EducationItems];
      updatedItems[educationEditIndex] = EducationItem;
      setEducationItems(updatedItems);
      setIsEducationEditing(false);
      setEducationOpen(false);
    }
  };

  const handleUserInit = async (user) => {
    setUser(user);
    const dbRef = collection(firestore, COLLECTIONS.CANDIDATES);
    const q = query(
      dbRef,
      orderBy("postedOn"),
      where("email", "==", user.email),
      limit(1)
    );
    const req = await getDocs(q);

    const userDocData = req.docs[0].data();

    setUserId(req.docs[0].id);
    if (userDocData.imageUrl) {
      setImageUrl(userDocData.imageUrl);
    }
    if (userDocData.resumeUrl) {
      setResumeUrl(userDocData.resumeUrl);
    }
    if (userDocData.title) {
      setTitle(userDocData.title);
    }
    if (userDocData.firstName) {
      setFirstName(userDocData.firstName);
    }
    if (userDocData.lastName) {
      setLastName(userDocData.lastName);
    }
    if (userDocData.dateOfBirth) {
      setDateofBirth(moment(userDocData.dateOfBirth, "MM/DD/YYYY").toDate());
    }
    if (userDocData.country) {
      setCountry(userDocData.country);
    }
    if (userDocData.city) {
      setCity(userDocData.city);
    }
    if (userDocData.nationality) {
      setNationality(userDocData.nationality);
    }
    if (userDocData.timezone) {
      setTimezone(userDocData.timezone);
    }
    if (userDocData.currency) {
      setCurrency(userDocData.currency);
    }
    if (userDocData.minSalaryExpectancy) {
      setMinSalaryExpectancy(userDocData.minSalaryExpectancy);
    }
    if (userDocData.salaryFrequency) {
      setSalaryFrequency(userDocData.salaryFrequency);
    }
    if (userDocData.linkedInUrl) {
      setLinkedInUrl(userDocData.linkedInUrl);
    }
    if (userDocData.portfolioUrl) {
      setPortfolioUrl(userDocData.portfolioUrl);
    }
    if (userDocData.skills) {
      setSkills(userDocData.skills);
    }
    if (userDocData.chipSkills) {
      setChipSkills(userDocData.chipSkills);
    }
    if (userDocData.aboutMe) {
      setAboutMe(userDocData.aboutMe);
    }
    if (userDocData.education) {
      const educationItemsWithDates = userDocData.education.map((item) => ({
        ...item,
        startDate: moment(item.startDate, "MM/DD/YYYY").toDate(),
        endDate: moment(item.endDate, "MM/DD/YYYY").toDate(),
      }));
      setEducationItems(educationItemsWithDates);
    }
    if (userDocData.employment) {
      const employmentItemsWithDates = userDocData.employment.map((item) => ({
        ...item,
        startDate: moment(item.startDate, "MM/DD/YYYY").toDate(),
        endDate: moment(item.endDate, "MM/DD/YYYY").toDate(),
      }));

      setItems(employmentItemsWithDates);
    }
  };

  const handleOpenDeleteDialog = () => {
    setDeleteDialogOpen(true);
  };

  const handleCloseDeleteDialog = () => {
    setDeleteDialogOpen(false);
  };

  const deleteAccount = async () => {
    const applicationQuery = query(
      collection(firestore, COLLECTIONS.APPLICATIONS),
      where("candidateEmail", "==", user.email)
    );
    const req = await getDocs(applicationQuery);
    console.log(req.docs.length);

    req.forEach(async (doc) => {
      await deleteDoc(doc.ref);
    });

    const docRef = doc(firestore, COLLECTIONS.CANDIDATES, userId);
    deleteDoc(docRef)
      .then(() => {
        deleteUser(user)
          .then(() => {
            toast.success("Account Deleted!", {
              position: toast.POSITION.BOTTOM_CENTER,
            });
          })
          .catch((error) => {
            toast.error("Some Problem occured while deleting the account", {
              position: toast.POSITION.BOTTOM_CENTER,
            });
          });
      })
      .catch((err) => {
        console.error("Error deleting document:", err);
      });
  };

  const handleDelete = async () => {
    const confirmDelete = window.confirm(
      "Are you sure you want to delete your account?"
    );

    if (confirmDelete) {
      handleOpenDeleteDialog();
    }
  };

  const handleSubmit = async (e) => {
    if (e.target.id === "basic-info") {
      e.preventDefault();
      if (chipSkills.length) {
        setButtonLoading(true);

        // const dobString = dateOfBirth.toLocaleDateString();

        const dbRef = collection(firestore, COLLECTIONS.CANDIDATES);
        const q = query(
          dbRef,
          orderBy("postedOn"),
          where("email", "==", user.email),
          limit(1)
        );
        const req = await getDocs(q);
        const userDocRef = req.docs[0].ref;

        if (imageFile) {
          const storageRef = ref(storage, `/files/${user.uid}`);
          const uploadTask = uploadBytesResumable(storageRef, imageFile);

          await uploadTask.on(
            "state_changed",
            (snapshot) => {},
            (err) => {},
            () => {
              //download url
              getDownloadURL(uploadTask.snapshot.ref).then((url) => {
                setImageUrl(url);

                updateDoc(userDocRef, {
                  imageUrl: url,
                })
                  .then(() => {
                    if (!resumeFile) {
                      setButtonLoading(false);
                      window.location.href = "./remotee";
                    }
                  })
                  .catch((err) => {});
              });
            }
          );
        }
        if (resumeFile) {
          const storageRef = ref(storage, `/files/resumes/${user.uid}`);
          const uploadTask = uploadBytesResumable(storageRef, resumeFile);

          await uploadTask.on(
            "state_changed",
            (snapshot) => {},
            (err) => {},
            () => {
              //download url
              getDownloadURL(uploadTask.snapshot.ref).then((url) => {
                setResumeUrl(url);
                updateDoc(userDocRef, {
                  resumeUrl: url,
                })
                  .then(() => {
                    setButtonLoading(false);
                    window.location.href = "./remotee";
                  })
                  .catch((err) => {});
              });
            }
          );
        }

        const educationItemsWithStrings = EducationItems.map((item) => ({
          ...item,
          startDate: item.startDate.toLocaleDateString("en-US"),
          endDate: item.endDate.toLocaleDateString("en-US"),
        }));

        const employmentItemsWithStrings = items.map((item) => ({
          ...item,
          startDate: item.startDate.toLocaleDateString("en-US"),
          endDate: item.endDate.toLocaleDateString("en-US"),
        }));
        const candidate = {
          title: title,
          firstName: firstName,
          lastName: lastName,
          email: user.email,
          dateOfBirth: dateOfBirth.toLocaleDateString("en-US"),
          country: country,
          city: city,
          nationality: nationality,
          timezone: timezone,
          currency: currency,
          minSalaryExpectancy: minSalaryExpectancy,
          salaryFrequency: salaryFrequency,
          linkedInUrl: linkedInUrl,
          portfolioUrl: portfolioUrl,
          skills: skills,
          chipSkills: chipSkills,
          aboutMe: aboutMe,
          education: educationItemsWithStrings,
          employment: employmentItemsWithStrings,
          uid: user.uid,
        };

        await updateDoc(userDocRef, {
          ...candidate,
          postedOn: serverTimestamp(),
        })
          .then(() => {
            ("Update Successfull");
            if (!imageFile && !resumeFile) {
              setButtonLoading(false);

              window.location.href = "./remotee";
            }
          })
          .catch((err) => {});
      } else {
        // setJobDescription("Fill all the other information first");
        toast.error("Fill Out all the information first!", {
          position: toast.POSITION.BOTTOM_CENTER,
        });
      }
    }
  };

  useEffect(
    () => {
      const retrieveUser = async () => {
        const unsubscribe = auth.onAuthStateChanged((user) => {
          if (user) {
            setUser(user);
            //checkCompany(user);
            handleUserInit(user);
          } else {
            setUser(null);
          }
          setLoading(false);
        });

        return unsubscribe;
      };

      const unsubscribe = retrieveUser();
      return () => unsubscribe();
    },
    [],
    user
  );

  return (
    <ThemeProvider theme={theme}>
      <Grid style={{ background: "#E9E4EE" }}>
        <ProfileHeader />
      </Grid>
      <Grid container spacing={1} mb={3}>
        {loading ? (
          <Box display="flex" justifyContent="center">
            <CircularProgress />
          </Box>
        ) : user ? (
          <>
            <Grid container className={classes.wrapper}>
              <Grid
                item
                lg={11}
                xs={11}
                style={{
                  background: "white",
                  borderRadius: "50px",
                  padding: "30px",
                }}
              >
                <form id="basic-info" onSubmit={handleSubmit}>
                  <Grid container>
                    <Grid container>
                      <Grid item lg={4} xs={12}>
                        <Typography className={classes.label}>
                          {messages[locale].profilePicture}
                        </Typography>
                        <Grid container item justifyContent="center">
                          <Avatar
                            src={
                              imageUrl
                                ? imageUrl
                                : imageFile
                                ? URL.createObjectURL(imageFile)
                                : ProfileAvatar
                            }
                            onClick={handleDefaultImageClick}
                            alt="Select profile"
                            style={{
                              width: "200px",
                              height: "200px",
                              marginTop: "20px",
                              justifyContent: "center",
                            }}
                          />
                          <input
                            type="file"
                            accept="image/*"
                            onChange={(e) =>
                              handleImageInput(e.target.files[0])
                            }
                            ref={fileInput}
                            style={{ display: "none" }}
                          />
                        </Grid>
                      </Grid>

                      <Grid container item xs={12} lg={8}>
                        <Grid item xs={12}>
                          <Typography className={classes.label}>
                            {messages[locale].title} *
                          </Typography>
                          <TextField
                            id="title"
                            placeholder="UI/UX Developer"
                            className={classes.textField}
                            value={title}
                            onChange={(e) => setTitle(e.target.value)}
                            InputProps={{
                              disableOutline: true,
                              disableUnderline: true,
                            }}
                            required
                          />
                        </Grid>
                        <Grid
                          item
                          lg={6}
                          xs={12}
                          style={{ padding: "30px 10px" }}
                        >
                          <Typography className={classes.label}>
                            {messages[locale].firstName} *
                          </Typography>
                          <TextField
                            id="first-name"
                            placeholder="John"
                            className={classes.textField}
                            value={firstName}
                            onChange={(e) => setFirstName(e.target.value)}
                            InputProps={{
                              borderRadius: "25px",
                              disableOutline: true,
                              disableUnderline: true,
                            }}
                            required
                          />
                        </Grid>
                        <Grid
                          item
                          lg={6}
                          xs={12}
                          style={{ padding: "30px 10px" }}
                        >
                          <Typography className={classes.label}>
                            {messages[locale].lastName} *
                          </Typography>
                          <TextField
                            id="last-name"
                            placeholder="Doe"
                            className={classes.textField}
                            value={lastName}
                            onChange={(e) => setLastName(e.target.value)}
                            InputProps={{
                              borderRadius: "25px",
                              disableOutline: true,
                              disableUnderline: true,
                            }}
                            required
                          />
                        </Grid>
                        <Grid
                          item
                          lg={6}
                          xs={12}
                          style={{ padding: "30px 10px" }}
                        >
                          <Typography className={classes.label}>
                            {messages[locale].email}
                          </Typography>

                          <TextField
                            type="email"
                            value={user.email}
                            disabled="true"
                            className={classes.textField}
                            InputProps={{
                              borderRadius: "25px",
                              disableOutline: true,
                              disableUnderline: true,
                            }}
                            required
                          />
                        </Grid>
                        <Grid
                          item
                          lg={6}
                          xs={12}
                          style={{ padding: "30px 10px" }}
                        >
                          <Typography className={classes.label}>
                            {messages[locale].dateOfBirth} *
                          </Typography>

                          <DatePicker
                            selected={
                              new Date(Date.parse(dateOfBirth, "dd/MM/yyyy"))
                            }
                            onChange={(date) => {
                              if (date) {
                                setDateofBirth(date);
                              }
                            }}
                            dateFormat="dd/MM/yyyy"
                            className={classes.calender}
                            maxDate={new Date()}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item lg={4} xs={12} style={{ padding: "30px 10px" }}>
                      <Typography className={classes.label}>
                        {messages[locale].country} *
                      </Typography>
                      <FormControl fullWidth className={classes.formControl}>
                        <Select
                          labelId="country-label"
                          id="country"
                          value={country}
                          onChange={(e) => setCountry(e.target.value)}
                          displayEmpty
                          inputProps={{ "aria-label": "Placeholder" }}
                          // input={<TextField/>}
                          renderValue={(selected) => {
                            if (!selected) {
                              return (
                                <em className={classes.placeholder}>Spain</em>
                              );
                            }

                            return selected;
                          }}
                          required
                        >
                          <MenuItem value="" disabled>
                            <span className={classes.placeholder}>
                              Placeholder
                            </span>
                          </MenuItem>
                          {countries.map((country, index) => (
                            <MenuItem key={index} value={country}>
                              {country}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>

                    <Grid item lg={4} xs={12} style={{ padding: "30px 10px" }}>
                      <Typography className={classes.label}>
                        {messages[locale].city} *
                      </Typography>
                      <TextField
                        id="city"
                        placeholder="New York"
                        className={classes.textField}
                        value={city}
                        onChange={(e) => setCity(e.target.value)}
                        margin="normal"
                        InputProps={{
                          borderRadius: "25px",
                          disableOutline: true,
                          disableUnderline: true,
                        }}
                        required
                      />
                    </Grid>

                    <Grid item lg={4} xs={12} style={{ padding: "30px 10px" }}>
                      <Typography className={classes.label}>
                        {messages[locale].nationality} *
                      </Typography>
                      <FormControl fullWidth className={classes.formControl}>
                        <Select
                          labelId="nationality-label"
                          id="nationality"
                          value={nationality}
                          onChange={(e) => setNationality(e.target.value)}
                          displayEmpty
                          inputProps={{ "aria-label": "Placeholder" }}
                          // input={<TextField/>}
                          renderValue={(selected) => {
                            if (!selected) {
                              return (
                                <em className={classes.placeholder}>Spain</em>
                              );
                            }

                            return selected;
                          }}
                          required
                        >
                          <MenuItem value="" disabled>
                            <span className={classes.placeholder}>
                              Placeholder
                            </span>
                          </MenuItem>
                          {nationalities.map((nationality, index) => (
                            <MenuItem key={index} value={nationality}>
                              {nationality}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>

                    <Grid item lg={4} xs={12} style={{ padding: "30px 10px" }}>
                      <Typography className={classes.label}>
                        {messages[locale].timeZone} *
                      </Typography>
                      <FormControl fullWidth className={classes.formControl}>
                        <Select
                          labelId="timezone-label"
                          id="timezone"
                          value={timezone}
                          onChange={(e) => setTimezone(e.target.value)}
                          displayEmpty
                          inputProps={{ "aria-label": "Placeholder" }}
                          // input={<TextField/>}
                          renderValue={(selected) => {
                            if (!selected) {
                              return (
                                <em className={classes.placeholder}>UTC+5</em>
                              );
                            }

                            return selected;
                          }}
                          required
                        >
                          <MenuItem value="" disabled>
                            <span className={classes.placeholder}>
                              Placeholder
                            </span>
                          </MenuItem>

                          <MenuItem value="UTC-12">UTC-12</MenuItem>
                          <MenuItem value="UTC-11">UTC-11</MenuItem>
                          <MenuItem value="UTC-10">UTC-10</MenuItem>
                          <MenuItem value="UTC-9">UTC-9</MenuItem>
                          <MenuItem value="UTC-8">UTC-8</MenuItem>
                          <MenuItem value="UTC-7">UTC-7</MenuItem>
                          <MenuItem value="UTC-6">UTC-6</MenuItem>
                          <MenuItem value="UTC-5">UTC-5</MenuItem>
                          <MenuItem value="UTC-4">UTC-4</MenuItem>
                          <MenuItem value="UTC-3">UTC-3</MenuItem>
                          <MenuItem value="UTC-2">UTC-2</MenuItem>
                          <MenuItem value="UTC-1">UTC-1</MenuItem>
                          <MenuItem value="UTC">UTC</MenuItem>
                          <MenuItem value="UTC+1">UTC+1</MenuItem>
                          <MenuItem value="UTC+2">UTC+2</MenuItem>
                          <MenuItem value="UTC+3">UTC+3</MenuItem>
                          <MenuItem value="UTC+4">UTC+4</MenuItem>
                          <MenuItem value="UTC+5">UTC+5</MenuItem>
                          <MenuItem value="UTC+6">UTC+6</MenuItem>
                          <MenuItem value="UTC+7">UTC+7</MenuItem>
                          <MenuItem value="UTC+8">UTC+8</MenuItem>
                          <MenuItem value="UTC+9">UTC+9</MenuItem>
                          <MenuItem value="UTC+10">UTC+10</MenuItem>
                          <MenuItem value="UTC+11">UTC+11</MenuItem>
                          <MenuItem value="UTC+12">UTC+12</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>

                    <Grid item lg={4} xs={12} style={{ padding: "30px 10px" }}>
                      <Typography className={classes.label}>
                        {messages[locale].currency} *
                      </Typography>
                      <FormControl fullWidth className={classes.formControl}>
                        <Select
                          labelId="currency-label"
                          id="currency"
                          value={currency}
                          onChange={(e) => setCurrency(e.target.value)}
                          displayEmpty
                          inputProps={{ "aria-label": "Placeholder" }}
                          // input={<TextField/>}
                          renderValue={(selected) => {
                            if (!selected) {
                              return (
                                <em className={classes.placeholder}>USD</em>
                              );
                            }

                            return selected;
                          }}
                          required
                        >
                          <MenuItem value="" disabled>
                            <span className={classes.placeholder}>
                              Placeholder
                            </span>
                          </MenuItem>

                          <MenuItem value="USD">USD</MenuItem>
                          <MenuItem value="EUR">EUR</MenuItem>
                          <MenuItem value="GBP">GBP</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>

                    <Grid item lg={4} xs={12} style={{ padding: "30px 10px" }}>
                      <Typography className={classes.label}>
                        {messages[locale].salaryExpectancy} *
                      </Typography>
                      <Grid
                        container
                        alignItems="center"
                        style={{ width: "95%" }}
                      >
                        <TextField
                          id="min-salary-expectancy"
                          placeholder="50000"
                          className={classes.textField}
                          style={{ width: "50%" }}
                          value={minSalaryExpectancy}
                          onChange={(e) =>
                            setMinSalaryExpectancy(e.target.value)
                          }
                          margin="normal"
                          type="number"
                          InputProps={{
                            borderRadius: "25px",
                            disableOutline: true,
                            disableUnderline: true,
                            inputProps: { min: 0 },
                          }}
                          required
                        />
                        <Select
                          onChange={(e) => setSalaryFrequency(e.target.value)}
                          value={salaryFrequency}
                          name="frequency"
                          disableUnderline
                          defaultValue="annually"
                          style={{
                            background: "white",
                            marginLeft: "4px",
                            border: "1px solid #ECECEC",
                            borderRadius: "25px",
                            padding: "4px 16px",
                          }}
                        >
                          <MenuItem value="annually"> Annually </MenuItem>

                          <MenuItem value="monthly"> Monthly </MenuItem>
                        </Select>
                      </Grid>
                    </Grid>

                    <Grid item lg={4} xs={12} style={{ padding: "30px 10px" }}>
                      <Typography className={classes.label}>
                        LinkedIn
                      </Typography>
                      <TextField
                        id="linkedin-url"
                        placeholder="www.linkedin.com/in/johndoe"
                        className={classes.textField}
                        value={linkedInUrl}
                        onChange={(e) => setLinkedInUrl(e.target.value)}
                        margin="normal"
                        InputProps={{
                          borderRadius: "25px",
                          disableOutline: true,
                          disableUnderline: true,
                        }}
                      />
                    </Grid>

                    <Grid item lg={4} xs={12} style={{ padding: "30px 10px" }}>
                      <Typography className={classes.label}>
                        {messages[locale].portfolio}
                      </Typography>
                      <TextField
                        id="portfolio-url"
                        placeholder="www.portfolio.com/johndoe"
                        className={classes.textField}
                        value={portfolioUrl}
                        onChange={(e) => setPortfolioUrl(e.target.value)}
                        margin="normal"
                        InputProps={{
                          borderRadius: "25px",
                          disableOutline: true,
                          disableUnderline: true,
                        }}
                      />
                    </Grid>

                    <Grid item lg={4} xs={12} style={{ padding: "30px 10px" }}>
                      <Typography className={classes.label}>
                        {messages[locale].skills} *
                      </Typography>
                      <Autocomplete
                        multiple // For selecting multiple options
                        id="multiselect-dropdown"
                        onChange={(event, newValue) => {
                          // Add the new value to the skills state
                          setChipSkills([...chipSkills, newValue]);

                          // Call your handleChange function here if needed
                          handleSkillChange(event, newValue);
                        }}
                        value={chipSkills}
                        options={skillList.map((skill) => skill.name)}
                        freeSolo
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            fullWidth
                            placeholder={
                              chipSkills.length > 0 ? "" : "Select Skills"
                            }
                            className={classes.formControl}
                          />
                        )}
                        renderTags={(value, getTagProps) => (
                          <Grid container className={classes.chips}>
                            {value.map((option, index) => (
                              <Grid className={classes.skillChip}>
                                <Grid container alignItems="center">
                                  <Typography
                                    key={index}
                                    {...getTagProps({ index })}
                                  >
                                    {option}
                                  </Typography>

                                  <CancelIcon
                                    size={20}
                                    color="#A9A9A9"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => handleRemoveSkill(option)}
                                  />
                                </Grid>
                              </Grid>
                            ))}
                          </Grid>
                        )}
                      />
                    </Grid>
                  </Grid>
                  <Grid item style={{ padding: "30px 10px" }}>
                    <Typography className={classes.label}>
                      {messages[locale].aboutMe} *
                    </Typography>
                    <TextField
                      id="about-me"
                      placeholder="I'm a very skilled React JS developer with 3 years of experience in the industry ..... "
                      multiline
                      rows={10}
                      className={classes.textField}
                      value={aboutMe}
                      onChange={(e) => setAboutMe(e.target.value)}
                      margin="normal"
                      InputProps={{
                        borderRadius: "25px",
                        disableOutline: true,
                        disableUnderline: true,
                      }}
                      required
                    />
                  </Grid>
                  <Grid
                    container
                    justifyContent="flex-end"
                    alignItems="center"
                    style={{ marginTop: "30px" }}
                  >
                    <Grid item>
                      {resumeUrl ? (
                        <a
                          href={
                            isResumeLocal
                              ? resumeUrl
                              : `/resume-viewer/${userId}`
                          }
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <ResumeIcon color="#754d9f" size={40} />
                        </a>
                      ) : (
                        <></>
                      )}
                    </Grid>
                    <Grid item>
                      <Button
                        className={classes.whiteButtonDialog}
                        onClick={handleResumeButtonClick}
                        style={{ marginLeft: "20px" }}
                      >
                        {resumeUrl ? "Update Resume/CV" : "Upload Resume/CV"}
                      </Button>
                      <input
                        type="file"
                        accept=".pdf,.doc,.docx"
                        style={{ display: "none" }}
                        onChange={handleFileChange}
                        ref={fileInputResume}
                      />
                    </Grid>
                  </Grid>
                  <hr
                    color="#ECECEC"
                    style={{ marginTop: "50px", marginBottom: "50px" }}
                  />

                  <Grid>
                    <Grid
                      container
                      item
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <Grid item lg={6} xs={6}>
                        <Typography className={classes.heading}>
                          {messages[locale].education}
                        </Typography>
                      </Grid>
                      <Grid
                        container
                        item
                        lg={6}
                        xs={6}
                        justifyContent="flex-end"
                        alignItems="center"
                      >
                        <Grid item>
                          <Button
                            variant="contained"
                            color="primary"
                            className={classes.button}
                            onClick={handleEducationClickOpen}
                          >
                            {messages[locale].addItem}
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>

                    <Dialog
                      TransitionComponent={Slide}
                      TransitionProps={{
                        direction: "up",
                        timeout: {
                          enter: 300,
                          exit: 300,
                        },
                      }}
                      className={classes.container}
                      spacing={2}
                      open={educationOpen}
                      onClose={handleClose}
                      aria-labelledby="form-dialog-title"
                    >
                      <DialogContent>
                        <form
                          id="education-dialog"
                          onSubmit={
                            isEducationEditing
                              ? handleEducationEditSave
                              : handleEducationSave
                          }
                        >
                          <Grid container>
                            <Grid
                              item
                              lg={6}
                              xs={12}
                              style={{ padding: "30px 10px" }}
                            >
                              <Typography className={classes.label}>
                                {messages[locale].educationLevel}
                              </Typography>

                              <FormControl
                                fullWidth
                                className={classes.formControl}
                              >
                                <Select
                                  id="educationLevel"
                                  name="educationLevel"
                                  value={EducationItem.educationLevel}
                                  onChange={(event) =>
                                    handleEducationChange(
                                      event.target.name,
                                      event.target.value
                                    )
                                  }
                                  displayEmpty
                                  inputProps={{ "aria-label": "Placeholder" }}
                                  renderValue={(selected) => {
                                    if (!selected) {
                                      return (
                                        <em className={classes.placeholder}>
                                          Bachelors
                                        </em>
                                      );
                                    }

                                    return selected;
                                  }}
                                  required
                                >
                                  <MenuItem value="" disabled>
                                    <span className={classes.placeholder}>
                                      Placeholder
                                    </span>
                                  </MenuItem>
                                  <MenuItem value="Bachelors">
                                    Bachelors
                                  </MenuItem>
                                  <MenuItem value="Masters">Masters</MenuItem>
                                  <MenuItem value="Doctorate">
                                    Doctorate
                                  </MenuItem>
                                  <MenuItem value="Diploma">Diploma</MenuItem>
                                  <MenuItem value="Training">Training</MenuItem>
                                </Select>
                              </FormControl>
                            </Grid>
                            <Grid
                              item
                              lg={6}
                              xs={12}
                              style={{ padding: "30px 10px" }}
                            >
                              <Typography className={classes.label}>
                                {messages[locale].degree}
                              </Typography>
                              <TextField
                                style={{ width: "100%" }}
                                margin="dense"
                                id="degree"
                                placeholder="Degree"
                                type="text"
                                name="degree"
                                value={EducationItem.degree}
                                onChange={(event) =>
                                  handleEducationChange(
                                    event.target.name,
                                    event.target.value
                                  )
                                }
                                className={classes.textField}
                                InputProps={{
                                  borderRadius: "25px",
                                  disableOutline: true,
                                  disableUnderline: true,
                                }}
                                required
                              />
                            </Grid>

                            <Grid
                              item
                              lg={6}
                              xs={12}
                              style={{ padding: "30px 10px" }}
                            >
                              <Typography className={classes.label}>
                                {messages[locale].institute}
                              </Typography>
                              <TextField
                                style={{ width: "98%" }}
                                margin="dense"
                                id="institute"
                                placeholder="MIT"
                                type="text"
                                name="institute"
                                value={EducationItem.institute}
                                onChange={(event) =>
                                  handleEducationChange(
                                    event.target.name,
                                    event.target.value
                                  )
                                }
                                className={classes.textField}
                                InputProps={{
                                  borderRadius: "25px",
                                  disableOutline: true,
                                  disableUnderline: true,
                                }}
                                required
                              />
                            </Grid>

                            <Grid
                              item
                              lg={6}
                              xs={12}
                              style={{ padding: "30px 10px" }}
                            >
                              <Typography className={classes.label}>
                                {messages[locale].major}
                              </Typography>
                              <TextField
                                style={{ width: "100%" }}
                                margin="dense"
                                id="major"
                                placeholder="Data Science"
                                type="text"
                                name="major"
                                value={EducationItem.major}
                                onChange={(event) =>
                                  handleEducationChange(
                                    event.target.name,
                                    event.target.value
                                  )
                                }
                                className={classes.textField}
                                InputProps={{
                                  borderRadius: "25px",
                                  disableOutline: true,
                                  disableUnderline: true,
                                }}
                                required
                              />
                            </Grid>
                            <Grid
                              item
                              lg={6}
                              xs={12}
                              style={{ padding: "30px 10px" }}
                            >
                              <Typography className={classes.label}>
                                {messages[locale].startDate}
                              </Typography>
                              <DatePicker
                                selected={
                                  new Date(
                                    Date.parse(
                                      EducationItem.startDate,
                                      "dd/MM/yyyy"
                                    )
                                  )
                                }
                                onChange={(date) => {
                                  if (date) {
                                    handleEducationChange("startDate", date);
                                  }
                                }}
                                dateFormat="dd/MM/yyyy"
                                className={classes.calender}
                                maxDate={new Date()}
                              />
                            </Grid>
                            <Grid
                              item
                              lg={6}
                              xs={12}
                              style={{ padding: "30px 10px" }}
                            >
                              <Typography className={classes.label}>
                                {messages[locale].endDate}
                              </Typography>
                              <DatePicker
                                selected={
                                  new Date(
                                    Date.parse(
                                      EducationItem.endDate,
                                      "dd/MM/yyyy"
                                    )
                                  )
                                }
                                onChange={(date) => {
                                  if (date) {
                                    handleEducationChange("endDate", date);
                                  }
                                }}
                                dateFormat="dd/MM/yyyy"
                                className={classes.calender}
                                maxDate={new Date()}
                              />
                            </Grid>
                          </Grid>
                          <Grid container justifyContent="flex-end">
                            <Grid item>
                              <Button
                                variant="contained"
                                onClick={handleEducationClose}
                                className={classes.whiteButtonDialog}
                                disableElevation
                              >
                                {messages[locale].close}
                              </Button>
                              <Button
                                variant="contained"
                                color="primary"
                                type="submit"
                                className={classes.button}
                              >
                                {isEducationEditing ? (
                                  <>{messages[locale].update}</>
                                ) : (
                                  <>{messages[locale].save}</>
                                )}
                              </Button>
                            </Grid>
                          </Grid>
                        </form>
                      </DialogContent>
                    </Dialog>

                    <div>
                      {EducationItems.map((EducationItem, index) => (
                        <Grid
                          container
                          justifyContent="space-between"
                          className={classes.item}
                          key={index}
                        >
                          <Grid item xs={10}>
                            <Typography className={classes.educationTitle}>
                              {EducationItem.educationLevel} in{" "}
                              {EducationItem.degree} - {EducationItem.institute}
                            </Typography>
                            <Typography className={classes.educationMajor}>
                              {EducationItem.major}
                            </Typography>

                            <Typography className={classes.educationDuration}>
                              {new Date(
                                EducationItem.startDate
                              ).toLocaleDateString("en-GB")}{" "}
                              -{" "}
                              {new Date(
                                EducationItem.endDate
                              ).toLocaleDateString("en-GB")}
                            </Typography>
                          </Grid>
                          <Grid
                            container
                            item
                            xs={2}
                            justifyContent="flex-end"
                            alignItems="flex-start"
                          >
                            <img
                              src={EditItemIcon}
                              onClick={() => handleEducationEdit(index)}
                              alt="EducationEdit"
                              style={{ cursor: "pointer", marginRight: "10px" }}
                            ></img>
                            <DeleteIcon
                              color="#D74C4C"
                              size={22}
                              style={{ cursor: "pointer", marginRight: "10px" }}
                              onClick={() => handleEducationDelete(index)}
                            />
                          </Grid>
                        </Grid>
                      ))}
                    </div>
                  </Grid>

                  <hr
                    color="#ECECEC"
                    style={{ marginTop: "50px", marginBottom: "50px" }}
                  />
                  <Grid>
                    <Grid
                      item
                      container
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <Grid item xs={6}>
                        <Typography className={classes.heading}>
                          {messages[locale].employmentHistory}
                        </Typography>
                      </Grid>
                      <Grid
                        container
                        item
                        xs={6}
                        justifyContent="flex-end"
                        alignItems="center"
                      >
                        <Button
                          variant="contained"
                          color="primary"
                          className={classes.button}
                          onClick={handleClickOpen}
                        >
                          {messages[locale].addItem}
                        </Button>
                      </Grid>
                    </Grid>
                    <Dialog
                      TransitionComponent={Slide}
                      TransitionProps={{
                        direction: "up",
                        timeout: {
                          enter: 300,
                          exit: 300,
                        },
                      }}
                      className={classes.container}
                      spacing={2}
                      open={open}
                      onClose={handleClose}
                      aria-labelledby="form-dialog-title"
                    >
                      <DialogContent>
                        <form
                          id="employment-dialog"
                          onSubmit={
                            isEmploymentEditing
                              ? handleEmploymentEditSave
                              : handleSave
                          }
                        >
                          <Grid container spacing={1}>
                            <Grid
                              item
                              lg={6}
                              xs={12}
                              style={{ padding: "30px 10px" }}
                            >
                              <Typography className={classes.label}>
                                {messages[locale].jobTitle}
                              </Typography>
                              <TextField
                                style={{ width: "100%" }}
                                autoFocus
                                margin="dense"
                                id="job-title"
                                placeholder="React JS Developer"
                                type="text"
                                name="jobTitle"
                                value={item.jobTitle}
                                onChange={(event) =>
                                  handleChange(
                                    event.target.name,
                                    event.target.value
                                  )
                                }
                                className={classes.textField}
                                InputProps={{
                                  borderRadius: "25px",
                                  disableOutline: true,
                                  disableUnderline: true,
                                }}
                                required
                              />
                            </Grid>

                            <Grid
                              item
                              lg={6}
                              xs={12}
                              style={{ padding: "30px 10px" }}
                            >
                              <Typography className={classes.label}>
                                {messages[locale].company}
                              </Typography>
                              <TextField
                                style={{ width: "100%" }}
                                margin="dense"
                                id="company"
                                placeholder="Remoto"
                                type="text"
                                name="company"
                                value={item.company}
                                onChange={(event) =>
                                  handleChange(
                                    event.target.name,
                                    event.target.value
                                  )
                                }
                                className={classes.textField}
                                InputProps={{
                                  borderRadius: "25px",
                                  disableOutline: true,
                                  disableUnderline: true,
                                }}
                                required
                              />
                            </Grid>

                            <Grid
                              item
                              lg={6}
                              xs={12}
                              style={{ padding: "30px 10px" }}
                            >
                              <Typography className={classes.label}>
                                {messages[locale].industry}
                              </Typography>
                              <FormControl
                                fullWidth
                                className={classes.formControl}
                              >
                                <Select
                                  labelId="industry-label"
                                  id="industry"
                                  name="industry"
                                  value={item.industry}
                                  onChange={(event) =>
                                    handleChange(
                                      event.target.name,
                                      event.target.value
                                    )
                                  }
                                  displayEmpty
                                  inputProps={{ "aria-label": "Placeholder" }}
                                  // input={<TextField/>}
                                  renderValue={(selected) => {
                                    if (!selected) {
                                      return (
                                        <em className={classes.placeholder}>
                                          IT & Software
                                        </em>
                                      );
                                    }

                                    return selected;
                                  }}
                                  required
                                >
                                  <MenuItem value="" disabled>
                                    <span className={classes.placeholder}>
                                      Placeholder
                                    </span>
                                  </MenuItem>
                                  {industries.map((industry, index) => (
                                    <MenuItem key={index} value={industry}>
                                      {industry}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                            </Grid>

                            <Grid
                              item
                              lg={6}
                              xs={12}
                              style={{ padding: "30px 10px" }}
                            >
                              <Typography className={classes.label}>
                                {messages[locale].jobType}
                              </Typography>

                              <FormControl
                                fullWidth
                                className={classes.formControl}
                              >
                                <Select
                                  labelId="jobType-label"
                                  id="jobType"
                                  name="jobType"
                                  value={item.jobType}
                                  onChange={(event) =>
                                    handleChange(
                                      event.target.name,
                                      event.target.value
                                    )
                                  }
                                  displayEmpty
                                  inputProps={{ "aria-label": "Placeholder" }}
                                  renderValue={(selected) => {
                                    if (!selected) {
                                      return (
                                        <em className={classes.placeholder}>
                                          Full Time
                                        </em>
                                      );
                                    }

                                    return selected;
                                  }}
                                  required
                                >
                                  <MenuItem value="" disabled>
                                    <span className={classes.placeholder}>
                                      Placeholder
                                    </span>
                                  </MenuItem>

                                  <MenuItem value="Full Time">
                                    Full Time
                                  </MenuItem>
                                  <MenuItem value="Part Time">
                                    Part Time
                                  </MenuItem>
                                  <MenuItem value="Contract">Contract</MenuItem>
                                </Select>
                              </FormControl>
                            </Grid>

                            <Grid
                              item
                              lg={6}
                              xs={12}
                              style={{ padding: "30px 10px" }}
                            >
                              <Typography className={classes.label}>
                                {messages[locale].country}
                              </Typography>
                              <FormControl
                                fullWidth
                                className={classes.formControl}
                              >
                                <Select
                                  labelId="country-label"
                                  id="country"
                                  name="country"
                                  value={item.country}
                                  onChange={(event) =>
                                    handleChange(
                                      event.target.name,
                                      event.target.value
                                    )
                                  }
                                  displayEmpty
                                  inputProps={{ "aria-label": "Placeholder" }}
                                  // input={<TextField/>}
                                  renderValue={(selected) => {
                                    if (!selected) {
                                      return (
                                        <em className={classes.placeholder}>
                                          Spain
                                        </em>
                                      );
                                    }

                                    return selected;
                                  }}
                                  required
                                >
                                  <MenuItem value="" disabled>
                                    <span className={classes.placeholder}>
                                      Placeholder
                                    </span>
                                  </MenuItem>
                                  {countries.map((country, index) => (
                                    <MenuItem key={index} value={country}>
                                      {country}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                            </Grid>

                            <Grid
                              item
                              lg={6}
                              xs={12}
                              style={{ padding: "30px 10px" }}
                            >
                              <Typography className={classes.label}>
                                {messages[locale].city}
                              </Typography>
                              <TextField
                                style={{ width: "100%" }}
                                margin="dense"
                                id="city"
                                placeholder="Lisbon"
                                type="text"
                                name="city"
                                value={item.city}
                                onChange={(event) =>
                                  handleChange(
                                    event.target.name,
                                    event.target.value
                                  )
                                }
                                className={classes.textField}
                                InputProps={{
                                  borderRadius: "25px",
                                  disableOutline: true,
                                  disableUnderline: true,
                                }}
                                required
                              />
                            </Grid>

                            <Grid
                              item
                              lg={6}
                              xs={12}
                              style={{ padding: "30px 10px" }}
                            >
                              <Typography className={classes.label}>
                                {messages[locale].startDate}
                              </Typography>
                              <DatePicker
                                selected={
                                  new Date(
                                    Date.parse(item.startDate, "dd/MM/yyyy")
                                  )
                                }
                                onChange={(date) => {
                                  if (date) {
                                    handleChange("startDate", date);
                                  }
                                }}
                                dateFormat="dd/MM/yyyy"
                                className={classes.calender}
                                maxDate={new Date()}
                                required
                              />
                            </Grid>
                            <Grid
                              item
                              lg={6}
                              xs={12}
                              style={{ padding: "30px 10px" }}
                            >
                              <Typography className={classes.label}>
                                {messages[locale].endDate}
                              </Typography>
                              <DatePicker
                                disabled={isCurrentlyWorking}
                                selected={
                                  item.endDate
                                    ? new Date(
                                        Date.parse(item.endDate, "dd/MM/yyyy")
                                      )
                                    : null
                                }
                                onChange={(date) => {
                                  if (date) {
                                    handleChange("endDate", date);
                                  }
                                }}
                                dateFormat="dd/MM/yyyy"
                                className={classes.calender}
                              />
                              <label>
                                <input
                                  type="checkbox"
                                  checked={isCurrentlyWorking}
                                  onChange={() => {
                                    item.isCurrentlyWorking =
                                      !isCurrentlyWorking;
                                    setIsCurrentlyWorking(!isCurrentlyWorking);
                                  }}
                                />
                                I currently work here
                              </label>
                            </Grid>

                            <Grid item xs={12} style={{ padding: "30px 10px" }}>
                              <Typography className={classes.label}>
                                {messages[locale].skills}
                              </Typography>
                              <FormControl className={classes.formControl}>
                                <Select
                                  labelId="multiselect-dropdown-label"
                                  id="multiselect-dropdown"
                                  name="chipSkills"
                                  multiple
                                  value={item.chipSkills ? item.chipSkills : []}
                                  onChange={(event) =>
                                    handleChange(
                                      event.target.name,
                                      event.target.value
                                    )
                                  }
                                  displayEmpty
                                  inputProps={{ "aria-label": "Placeholder" }}
                                  renderValue={(selected) => {
                                    if (selected.length === 0) {
                                      return (
                                        <em className={classes.placeholder}>
                                          Select Skills
                                        </em>
                                      );
                                    }
                                    return (
                                      <Grid container className={classes.chips}>
                                        {selected.map((value) => (
                                          <Typography
                                            className={classes.skillChip}
                                          >
                                            {value}
                                          </Typography>
                                        ))}
                                      </Grid>
                                    );
                                  }}
                                  required
                                >
                                  <MenuItem value="" disabled>
                                    <span className={classes.placeholder}>
                                      Placeholder
                                    </span>
                                  </MenuItem>

                                  {skillList.map((skill) => (
                                    <MenuItem
                                      key={skill.name}
                                      value={skill.name}
                                    >
                                      {skill.name}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                            </Grid>

                            <Grid item xs={12} style={{ padding: "30px 10px" }}>
                              <Typography className={classes.label}>
                                {messages[locale].dutiesandResponsibilities}
                              </Typography>
                              <TextField
                                id="duties"
                                placeholder="Developing and maintaining React JS applications."
                                type="text"
                                name="duties"
                                value={item.duties}
                                onChange={(event) =>
                                  handleChange(
                                    event.target.name,
                                    event.target.value
                                  )
                                }
                                style={{ width: "100%" }}
                                multiline
                                minRows={7}
                                className={classes.textField}
                                InputProps={{
                                  borderRadius: "25px",
                                  disableOutline: true,
                                  disableUnderline: true,
                                }}
                                required
                              />
                            </Grid>
                          </Grid>
                          <Grid container justifyContent="flex-end">
                            <Button
                              variant="contained"
                              color="white"
                              onClick={handleClose}
                              disableElevation
                              className={classes.whiteButtonDialog}
                            >
                              {messages[locale].close}
                            </Button>
                            <Button
                              variant="contained"
                              color="primary"
                              type="submit"
                              className={classes.button}
                            >
                              {isEmploymentEditing ? (
                                <>{messages[locale].update}</>
                              ) : (
                                <>{messages[locale].save}</>
                              )}
                            </Button>
                          </Grid>
                        </form>
                      </DialogContent>
                      <DialogActions></DialogActions>
                    </Dialog>
                    <div>
                      {items.map((item, index) => (
                        <Grid
                          container
                          className={classes.item}
                          key={index}
                          justifyContent="space-between"
                        >
                          <Grid item xs={10}>
                            <Typography className={classes.educationTitle}>
                              {item.company} - {item.city}, {item.country}{" "}
                            </Typography>
                            <Typography className={classes.educationMajor}>
                              {item.jobTitle} - {item.jobType}
                            </Typography>
                            <Typography className={classes.educationDuration}>
                              {item.industry} -{" "}
                              {new Date(item.startDate).toLocaleDateString(
                                "en-GB"
                              )}{" "}
                              -{" "}
                              {item.isCurrentlyWorking ? (
                                <>NOW</>
                              ) : (
                                new Date(item.endDate).toLocaleDateString(
                                  "en-GB"
                                )
                              )}
                            </Typography>
                            <Typography className={classes.educationMajor}>
                              {item.duties}
                            </Typography>
                          </Grid>
                          <Grid
                            container
                            item
                            xs={2}
                            justifyContent="flex-end"
                            alignItems="flex-start"
                          >
                            <img
                              src={EditItemIcon}
                              onClick={() => handleEmploymentEdit(index)}
                              alt="EmploymentEdit"
                              style={{ cursor: "pointer", marginRight: "10px" }}
                            ></img>
                            <DeleteIcon
                              color="#D74C4C"
                              size={22}
                              style={{ cursor: "pointer", marginRight: "10px" }}
                              onClick={() => handleEmploymentDelete(index)}
                            />
                          </Grid>
                        </Grid>
                      ))}
                    </div>
                  </Grid>

                  <Grid container item justifyContent="space-between">
                    <ToastContainer />
                    <Grid item xs={6}>
                      <Button
                        onClick={handleDelete}
                        variant="contained"
                        className={classes.button}
                        style={{
                          marginTop: "60px",
                          width: "120px",
                          height: "50px",
                          background: "#D74C4C",
                        }}
                      >
                        {buttonLoading ? (
                          <CircularProgress size={24} color="inherit" />
                        ) : (
                          <>Delete Account</>
                        )}
                      </Button>
                    </Grid>
                    <DeleteAccountConfirmationDialog
                      open={isDeleteDialogOpen}
                      onClose={handleCloseDeleteDialog}
                      deleteAccount={deleteAccount}
                      currentUser={user}
                    />
                    <Grid container item xs={6} justifyContent="flex-end">
                      <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        className={classes.button}
                        style={{
                          marginTop: "60px",
                          width: "120px",
                          height: "50px",
                        }}
                      >
                        {buttonLoading ? (
                          <CircularProgress size={24} color="inherit" />
                        ) : (
                          <>{messages[locale].save}</>
                        )}
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              </Grid>
            </Grid>
          </>
        ) : (
          //(user)
          (window.location.href = "/login")
        )}
      </Grid>
      <Footer />
    </ThemeProvider>
  );
};

export default EditProfile;
