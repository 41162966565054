import React from "react";
// import { LanguageContext } from "../../Languages/LanguageContext";
import { makeStyles } from "@material-ui/core/styles";
import "@fontsource/work-sans";
import { Grid, useTheme, useMediaQuery, Typography } from "@material-ui/core";
import ComapanyDrawer from "../Header/CompanyDrawer";
import {
  BriefcaseIcon,
  UserCircleIcon,
  ClipboardDocumentCheckIcon,
} from "@heroicons/react/16/solid";
import StickyBoxComp from "react-sticky-box";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  container: {
    borderRight: "1px solid #D1D1D1",
    background: "#FFFFFF",
    justifyContent: "center",
    padding: "48px",
    height: "100vh",
  },

  menuItemText: {
    fontWeight: 500,
    lineHeight: "24px",
    fontSize: "14px",
    color: "#151515",
    letterSpacing: "-0.004px",

    textAlign: "center",
  },
  menuItem: {
    padding: "36px 0px",
  },
}));
const CandidateSidebar = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const navigate = useNavigate();
  const { t } = useTranslation();
  // const { locale, messages } = useContext(LanguageContext);
  //const [notificationsCount, setNotificationsCount] = useState("");

  return (
    <>
      {isMobile ? (
        <Grid container xs={12}>
          <ComapanyDrawer />
        </Grid>
      ) : (
        <StickyBoxComp>
          <Grid container className={classes.container}>
            <Grid item>
              {props.component === "Jobs" ? (
                <Grid
                  style={{ color: "#003D50", cursor: "pointer", width: "36px" }}
                  onClick={() => navigate("/jobs")}
                >
                  <BriefcaseIcon color="#EA672F" />
                  <Typography
                    className={classes.menuItemText}
                    style={{ color: "#EA672F" }}
                  >
                    {t("jobs")}
                  </Typography>
                </Grid>
              ) : (
                <Grid
                  style={{ cursor: "pointer", width: "36px" }}
                  onClick={() => navigate("/jobs")}
                >
                  <BriefcaseIcon color="#003D50" />
                  <Typography className={classes.menuItemText}>Jobs</Typography>
                </Grid>
              )}
              {props.component === "Applied Jobs" ? (
                <Grid
                  style={{
                    color: "#003D50",
                    marginTop: "36px",
                    cursor: "pointer",
                    width: "36px",
                  }}
                  onClick={() => navigate("/applied-jobs")}
                >
                  <ClipboardDocumentCheckIcon color="#EA672F" />
                  <Typography
                    className={classes.menuItemText}
                    style={{ color: "#EA672F" }}
                  >
                    {t("applied") + " " + t("jobs")}
                  </Typography>
                </Grid>
              ) : (
                <Grid
                  style={{
                    marginTop: "36px",
                    cursor: "pointer",
                    width: "36px",
                  }}
                  onClick={() => navigate("/applied-jobs")}
                >
                  <ClipboardDocumentCheckIcon color="#003D50" />
                  <Typography className={classes.menuItemText}>
                    {t("applied") + " " + t("jobs")}
                  </Typography>
                </Grid>
              )}

              {props.component === "profile" ? (
                <Grid
                  style={{
                    color: "#003D50",
                    marginTop: "36px",
                    cursor: "pointer",
                    width: "36px",
                  }}
                  onClick={() => navigate("/profile")}
                >
                  <UserCircleIcon color="#EA672F" />
                  <Typography
                    className={classes.menuItemText}
                    style={{ color: "#EA672F" }}
                  >
                    {t("profile")}
                  </Typography>
                </Grid>
              ) : (
                <Grid
                  style={{
                    marginTop: "36px",
                    cursor: "pointer",
                    width: "36px",
                  }}
                  onClick={() => navigate("/profile")}
                >
                  <UserCircleIcon color="#003D50" />
                  <Typography className={classes.menuItemText}>
                    {t("profile")}
                  </Typography>
                </Grid>
              )}
            </Grid>
          </Grid>
        </StickyBoxComp>
      )}
    </>
  );
};

export default CandidateSidebar;
