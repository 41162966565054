import React, { useState, useContext, useEffect } from "react";
import { Grid, makeStyles, Typography, TextField } from "@material-ui/core";
import countries from "../Data/Countries";
import ProfileAvatar from "../../Assets/profileavatar.svg";
import theme from "../../theme/theme";
import { LanguageContext } from "../../Languages/LanguageContext";
import "react-datepicker/dist/react-datepicker.css";
import TextInput from "../Global/TextInput";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { profileSchema } from "../../schemas/profile";
import SelectOption from "../Global/SelectOption";
import { timezoneData, utcConstants } from "../../constants";
import FilledButton from "./ProfileButtons";
import {
  collection,
  query,
  orderBy,
  where,
  limit,
  getDocs,
  updateDoc,
  Timestamp,
} from "firebase/firestore";
import { getDownloadURL } from "firebase/storage";
import ProfileImage from "./ProfileImage";
import { COLLECTIONS, firestore, storage } from "../../firebase/config";
import { ref, uploadBytesResumable } from "firebase/storage";
import PrimaryBtn, { SecondaryBtn } from "../Global/Buttons";
import DateSelector from "./DateSelector";
import ResumeParsing from "./ResumeParsing";
import RichText from "../Global/RichText";
import Location from "../Global/Location";
import ReactSelect from "react-select";
import ProfilePic from "../Company/ProfilePic";
import ResumeTab from "./ResumeTab";
import useResumeUpload from "../../utils/useResumeUpload";
import ResumeSection from "./ResumeSection";
import { parse } from "date-fns";
import LanguageSelection from "./LanguageSelection";
import { ReactComponent as EditIcon } from "../../Assets/pencil-square.svg";
import { languages } from "../../utils/constants";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    border: "1px solid #D1D1D1",
    borderRadius: "8px",
    padding: "32px",
    marginTop: "16px",
  },
  label: {
    fontWeight: 600,
    fontSize: "14px",
    lineHeight: "19px",
    color: "#151515",
    letterSpacing: "-0.02em",
    marginLeft: "32px",
  },
  heading: {
    fontWeight: 700,
    fontSize: "16px",
    lineHeight: "19px",
    color: "#000",
    marginTop: "32px",
    letterSpacing: "-0.02em",
  },
  // calender: {
  //   background: "white",
  //   marginTop: "10px",
  //   border: "1px solid #ECECEC",
  //   borderRadius: "10px",
  //   width: "100%",
  //   padding: "16px 32px",
  // },
  calender: {
    background: "white",
    marginTop: "10px",
    border: "1px solid #ECECEC",
    borderRadius: "10px",
    width: "100%",
    padding: "8px 12px",
  },
  whiteButton: {
    backgroundColor: "transparent",
    textTransform: "unset",
    color: "#003D50",
    padding: "12px 24px",
    disableAnimation: true,
    fontSize: "16px",
    fontWeight: "700",
    width: "250px",
    marginTop: "16px",
    border: "1px solid #003D50",
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px", // Font size for mobile (screens smaller than or equal to small breakpoint)
      padding: "8px 16px",
    },
  },
  formControl: {
    background: "white",
    marginTop: "10px",
    border: "1px solid #ECECEC",
    padding: "8px 32px",
    "& .MuiSelect-root": {
      backgroundColor: "white",
      borderBottom: "none",
    },
    "& .MuiInput-underline:before": {
      borderBottom: "none",
    },
    "& .MuiInput-underline:after": {
      borderBottom: "none",
    },
    "&:hover .MuiInput-underline:before": {
      borderBottom: "none",
    },
    "&:hover .MuiInput-underline:after": {
      borderBottom: "none",
    },
  },
  textField: {
    background: "white",
    marginTop: "10px",
    border: "1px solid #ECECEC",
    padding: "8px 16px",
    width: "100%",
    "& .MuiInputBase-input": {
      padding: "16px 32px",

      color: theme.palette.text.primary,
      fontWeight: 400,

      borderBottom: "none",
      outline: "none",

      backgroundColor: "none",
    },
  },
}));

const GeneralTab = (props) => {
  const [initialValues, setInitialValues] = useState({});
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
  } = useForm({
    resolver: yupResolver(profileSchema),
    defaultValues: {
      title: "",
      firstName: "",
      lastName: "",
      dateOfBirth: new Date(),
      // country: "",
      // city: "",
      location: "",
      timezone: "",
      currency: "",
      minSalaryExpectancy: "",
      aboutMe: "",
      linkedInUrl: "",
      portfolioUrl: "",
    },
  });

  const classes = useStyles();
  const { locale, messages } = useContext(LanguageContext);
  const [loading, setLoading] = useState(false);
  const [languageModal, setLanguageModal] = useState(false);
  const [imageUrl, setImageUrl] = useState(
    props.userInfo?.imageUrl ? props.userInfo.imageUrl : ""
  );
  const fileInput = React.useRef(null);
  const { t } = useTranslation();
  const {
    fileName,
    resumeFile,
    fileType,
    handleDownload,
    handleFileChange,
    fileRef,
    handleRevert,
    handleUpload,
  } = useResumeUpload(props.userInfo);

  useEffect(() => {
    const user = props?.userInfo;
    if (user) {
      setDefaultValues(user);
    }
  }, [props?.userInfo]);

  useEffect(() => {
    setInitialValues(watch());
  }, []);

  const setDefaultValues = (user) => {
    setValue("title", user.title);
    setValue("firstName", user.firstName);
    setValue("lastName", user.lastName);
    setValue("dateOfBirth", user.dateOfBirth ? user.dateOfBirth : null);
    setValue("jobType", user.jobType);
    // setValue("country", user.country);
    // setValue("city", user.city);
    setValue("location", user.location ? user.location : "");
    setValue("timezone", user.timezone);
    setValue("currency", user.currency);
    setValue("minSalaryExpectancy", user.minSalaryExpectancy);
    setValue("aboutMe", user.aboutMe);
    setValue("linkedInUrl", user.linkedInUrl);
    setValue("portfolioUrl", user.portfolioUrl);
    handleRevert();
  };

  // const handleDefaultImageClick = () => {
  //   fileInput.current.click();
  // };

  // const handleImageInput = (file) => {
  //   if (file) {
  //     setImageFile(file);
  //     setImageUrl(null);
  //   }
  // };

  const isFormChanged = () => {
    const currentValues = watch();
    return Object.keys(currentValues).some((key) => {
      if (key === "dateOfBirth") {
        return (
          new Date(initialValues[key]).getTime() !==
          new Date(currentValues[key]).getTime()
        );
      } else {
        return initialValues[key] !== currentValues[key];
      }
    });
  };

  const handleSave = async (data) => {
    setLoading(true);
    try {
      const candidate = {
        title: data?.title,
        firstName: data?.firstName,
        lastName: data?.lastName,
        dateOfBirth: Timestamp.fromDate(new Date(data?.dateOfBirth)),
        // country: data?.country,
        // city: data?.city,
        location: data?.location,
        timezone: data?.timezone,
        currency: data?.currency,
        minSalaryExpectancy: data?.minSalaryExpectancy,
        linkedInUrl: data?.linkedInUrl,
        portfolioUrl: data?.portfolioUrl,
        aboutMe: data?.aboutMe,
      };
      const updatedUserInfo = { ...props.userInfo, ...candidate };
      await props.onSave(updatedUserInfo);
      if (resumeFile) {
        handleUpload();
      }
    } catch (err) {
      console.log("🚀 ~ handleSave ~ err:", err);
    } finally {
      setLoading(false);
    }
  };

  return (
    // <ResumeParsing />

    <form onSubmit={handleSubmit(handleSave)}>
      <Grid container className={classes.wrapper}>
        {/* image section */}
        <Grid item lg={3} xs={12} justifyContent="center">
          <div className="flex justify-center items-center w-full">
            <ProfilePic
              src={imageUrl}
              email={props?.userInfo?.email}
              uid={props?.userInfo?.uid}
              isCandidate={true}
            />
          </div>
          <div>
            {props?.userInfo?.languages?.length > 0 ? (
              <div className="rounded-lg border border-borderGrey-400 mt-6 w-11/12">
                <div className="p-4">
                  <div className="flex items-center gap-4 text-md w-full justify-between">
                    <h1 className="font-semibold text-xl capitalize">
                      {t("languages")}
                    </h1>
                    <div className="bg-[#EA672F1A] rounded-lg w-8 h-8 flex justify-center items-center">
                      <EditIcon
                        fontSize={"20"}
                        color="#003D50"
                        onClick={() => setLanguageModal(true)}
                        style={{
                          cursor: "pointer",
                          width: "30px",
                        }}
                      />
                    </div>
                  </div>
                  {props?.userInfo?.languages.map((lng) => (
                    <div className="py-2">
                      <h1 className="font-semibold text-md capitalize">
                        {languages.find((l) => l.value === lng.name).label}
                      </h1>
                      <p className="font-normal text-sm capitalize">
                        {lng.level}
                      </p>
                    </div>
                  ))}
                </div>
              </div>
            ) : (
              <div className="mt-4 mx-auto flex justify-center">
                <SecondaryBtn
                  text={t("add_language")}
                  onClick={() => setLanguageModal(true)}
                />
              </div>
            )}
          </div>

          {languageModal && (
            <LanguageSelection
              open={languageModal}
              handleClose={() => setLanguageModal(false)}
              lngs={props?.userInfo?.languages}
            />
          )}
        </Grid>

        {/* textfield sections */}
        <Grid container item xs={12} lg={9} spacing={4}>
          <Grid item xs={12}>
            <TextInput
              label={t("title")}
              placeholder={t("full_stack_developer")}
              name="title"
              regProp={{ ...register("title") }}
              error={errors.title?.message}
              required={true}
            />
          </Grid>
          <Grid item lg={6} xs={12}>
            <TextInput
              label={t("firstName")}
              placeholder={"John"}
              name="firstName"
              regProp={{ ...register("firstName") }}
              error={errors.firstName?.message}
              required={true}
            />
          </Grid>
          <Grid item lg={6} xs={12}>
            <TextInput
              label={t("lastName")}
              placeholder={"Doe"}
              name="lastName"
              regProp={{ ...register("lastName") }}
              error={errors.lastName?.message}
              required={true}
            />
          </Grid>

          <Grid item lg={6} xs={12}>
            <span className="text-sm font-medium text-slate-700 flex">
              {t("dateOfBirth")}
              <p className="text-sm text-red-500 ml-1">*</p>
            </span>

            <DateSelector
              selectedDate={
                watch("dateOfBirth") ? new Date(watch("dateOfBirth")) : null
              }
              onChange={(date) => {
                if (date) {
                  setValue("dateOfBirth", date);
                }
              }}
              maxDate={new Date()}
              placeholder="Select Date Of Birth"
            />
            <p className="text-sm text-red-500">
              {errors.dateOfBirth?.message}
            </p>
          </Grid>

          {/* <Grid item lg={6} xs={12}>
            <SelectOption
              label={messages[locale].country}
              id={messages[locale].country}
              regProp={{ ...register("country") }}
              options={countries}
              error={errors.country?.message}
              placeholder={"Select country"}
            />
          </Grid>

          <Grid item lg={6} xs={12}>
            <TextInput
              label={messages[locale].city}
              placeholder={"New York"}
              name="city"
              regProp={{ ...register("city") }}
              error={errors.city?.message}
              required={true}
            />
          </Grid> */}

          <Grid item lg={6} xs={12}>
            {/* <SelectOption
              label={messages[locale].timeZone}
              id={messages[locale].timeZone}
              regProp={{ ...register("timezone") }}
              options={utcConstant}
              error={errors.timezone?.message}
              placeholder={"Select timezone"}
            /> */}
            <span className="text-sm font-medium text-slate-700 mb-2 flex">
              {t("timeZone")}
              <p className="text-sm text-red-500 ml-1">*</p>
            </span>
            <ReactSelect
              placeholder={t("select_timezone")}
              value={
                watch("timezone") &&
                timezoneData.find((t) => t.value === watch("timezone"))
              }
              onChange={(e) => setValue("timezone", e.value)}
              options={timezoneData}
              hideSelectedOptions={false}
              styles={{
                control: (styles) => ({
                  ...styles,
                  boxShadow: "none",
                  fontSize: "14px",
                }),
                option: (provided, state) => ({
                  ...provided,
                  backgroundColor: state.isSelected
                    ? "#003D501A"
                    : provided.backgroundColor,
                  color: state.isSelected ? "#003D50" : provided.color,
                }),
              }}
            />
            <p className="text-sm text-red-500">{errors.timezone?.message}</p>
          </Grid>

          <Grid item lg={6} xs={12}>
            <TextInput
              // label={messages[locale].salaryExpectancy}
              label={t("annual_salary_expectation")}
              placeholder={"$50000"}
              name="minSalaryExpectancy"
              regProp={{ ...register("minSalaryExpectancy") }}
              error={errors.minSalaryExpectancy?.message}
              required={true}
              type={"number"}
            />
          </Grid>

          <Grid item lg={6} xs={12}>
            <SelectOption
              label={t("currency")}
              id={t("currency")}
              regProp={{ ...register("currency") }}
              options={["USD", "EUR", "GBP"]}
              error={errors.currency?.message}
              placeholder={"Select currency"}
              required={true}
            />
          </Grid>
          <Grid item xs={6}>
            <span className="text-sm font-medium text-slate-700 flex">
              {t("location")}
              <p className="text-sm text-red-500 ml-1">*</p>
            </span>

            <Location
              onChange={(e) => setValue("location", e)}
              value={watch("location")}
            />
          </Grid>
          <Grid item xs={6}>
            <span className="text-sm font-medium text-slate-700 flex">
              {t("resume")}
            </span>
            <div className="mt-1">
              {/* <ResumeTab onSave={handleSave} userInfo={props.userInfo} /> */}
              <ResumeSection
                fileName={fileName}
                resumeFile={resumeFile}
                fileType={fileType}
                handleDownload={handleDownload}
                handleFileChange={handleFileChange}
                fileRef={fileRef}
              />
            </div>
          </Grid>
          <Grid item xs={12}>
            <span className="text-sm font-medium text-slate-700 mb-2 flex">
              {t("aboutMe")}
            </span>
            <RichText
              placeholder={t("about_me_content")}
              value={watch("aboutMe")}
              handleChange={(e) => setValue("aboutMe", e)}
            />
            {/* <TextField
              id="about-me"
              placeholder="I'm a very skilled React JS developer with 3 years of experience in the industry ..... "
              multiline
              rows={5}
              className={classes.textField}
              {...register("aboutMe")}
              margin="normal"
              InputProps={{
                borderRadius: "25px",
                disableOutline: true,
                disableUnderline: true,
              }}
            /> */}
            <p className="text-sm text-red-500">{errors.aboutMe?.message}</p>
          </Grid>
          <Grid item xs={12}>
            <Typography
              className={classes.heading}
              style={{ padding: "0px", fontWeight: 700 }}
            >
              {" "}
              {t("socials")}
            </Typography>
            <Grid container style={{ marginTop: "8px" }} spacing={4}>
              <Grid item lg={6} xs={12}>
                <TextInput
                  label={"LinkedIn"}
                  placeholder={"www.linkedin.com/in/johndoe"}
                  name="linkedInUrl"
                  regProp={{ ...register("linkedInUrl") }}
                  error={errors.linkedInUrl?.message}
                />
              </Grid>

              <Grid item lg={6} xs={12}>
                <TextInput
                  label={t("portfolio")}
                  placeholder={"www.portfolio.com/johndoe"}
                  name="portfolioUrl"
                  regProp={{ ...register("portfolioUrl") }}
                  error={errors.portfolioUrl?.message}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <div className="mt-4 flex items-center gap-4">
        <SecondaryBtn
          text={t("revert_changes")}
          onClick={() => setDefaultValues(props?.userInfo)}
          disabled={!(isFormChanged() || !!resumeFile)}
        />
        <PrimaryBtn
          text={t("save_changes")}
          loading={loading}
          type="submit"
          disabled={!(isFormChanged() || !!resumeFile)}
        />
      </div>
    </form>
  );
};

export default GeneralTab;
