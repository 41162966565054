import React from "react";
import { Dialog, Slide, DialogContent } from "@material-ui/core";
import { IoMdClose } from "react-icons/io";

export default function DialogModal({
  children,
  handleClose,
  size,
  title,
  open,
  isDelete,
}) {
  return (
    <Dialog
      TransitionComponent={Slide}
      TransitionProps={{
        direction: "up",
        timeout: {
          enter: 300,
          exit: 300,
        },
      }}
      spacing={2}
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      fullWidth
      maxWidth={size ? size : "lg"}
      PaperProps={{
        style: isDelete ? { border: "2px solid red" } : {},
      }}
    >
      <DialogContent className="my-2">
        <div className="flex justify-between px-2 my-4 items-center">
          <h1 className="text-2xl font-semibold">{title}</h1>
          <div
            className="bg-gray-200 rounded-sm flex items-center justify-center w-9 h-9 cursor-pointer"
            onClick={handleClose}
          >
            <IoMdClose />
          </div>
        </div>
        {children}
      </DialogContent>
    </Dialog>
  );
}
