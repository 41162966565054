import React, { useState, useEffect, useContext, useRef } from "react";
import { COLLECTIONS, firestore, storage } from "../firebase/config";
import {
  collection,
  query,
  orderBy,
  where,
  limit,
  getDocs,
  updateDoc,
} from "firebase/firestore";
import {
  uploadBytesResumable,
  ref,
  getDownloadURL,
  getMetadata,
} from "firebase/storage";
import useCandidateStore from "../store/candidate";

export default function useResumeUpload(userInfo) {
  const [resumeFile, setResumeFile] = useState(null);
  const [resumeUrl, setResumeUrl] = useState(
    userInfo?.resumeUrl ? userInfo?.resumeUrl : null
  );
  const [fileName, setFileName] = useState("");
  const [fileType, setFileType] = useState("");
  const [lastUpdated, setLastUpdated] = useState("");
  const [metaData, setMetaData] = useState(null);
  const fileRef = useRef(null);
  const { candidate } = useCandidateStore();
  const [loading, setLoading] = useState(false);

  const fetchFileDetails = async () => {
    try {
      // Fetch file metadata from Firestore
      const storageRef = ref(storage, resumeUrl);
      const metadataSnapshot = await getMetadata(storageRef);

      setMetaData(metadataSnapshot);
      setFileName(
        candidate?.firstName ? candidate?.firstName + "'s Resume" : "Resume"
      );
      setFileType(metadataSnapshot.contentType);
      setLastUpdated(metadataSnapshot.updated);
    } catch (error) {
      console.error("Error fetching file:", error);
    }
  };

  useEffect(() => {
    if (resumeUrl) {
      fetchFileDetails();
    }
  }, [resumeUrl, candidate]);

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    setResumeFile(file);
    console.log(file);
    setResumeUrl(URL.createObjectURL(file));
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      if (userInfo.onboarding) {
        userInfo.setParentFile(file);
      }
      setResumeFile(file);
      setFileName(file.name);
      setFileType(file.type);
      setLastUpdated(file.lastModified);
    }
  };

  const handleUpload = async () => {
    if (!resumeFile) return;
    setLoading(true);

    try {
      const dbRef = collection(firestore, COLLECTIONS.CANDIDATES);
      const q = query(
        dbRef,
        orderBy("postedOn"),
        where("email", "==", userInfo.email),
        limit(1)
      );
      const req = await getDocs(q);
      const userDocRef = req.docs[0].ref;

      const storageRef = ref(storage, `/files/resumes/${userInfo.uid}`);
      const uploadTask = uploadBytesResumable(storageRef, resumeFile);

      await uploadTask.on(
        "state_changed",
        (snapshot) => {},
        (err) => {},
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((url) => {
            setResumeUrl(url);
            updateDoc(userDocRef, {
              resumeUrl: url,
            })
              .then(() => {
                console.log("uploaded");
                setResumeFile(null);
              })
              .catch((err) => {});
          });
        }
      );
    } catch (err) {
      console.log("🚀 ~ handleUpload ~ err:", err);
    } finally {
      setLoading(false);
    }
  };
  const handleDownload = () => {
    const link = document.createElement("a");
    link.href = resumeUrl;
    link.target = "_blank";
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleRevert = () => {
    setResumeFile(null);
    if (metaData) {
      setFileName(
        candidate?.firstName ? candidate?.displayName + "'s Resume" : "Resume"
      );
      setFileType(metaData.contentType);
      setLastUpdated(metaData.updated);
    } else {
      setFileName("");
      setFileType("");
      setLastUpdated("");
    }
  };

  return {
    handleRevert,
    handleFileChange,
    handleFileUpload,
    handleDownload,
    handleUpload,
    resumeFile,
    setResumeFile,
    resumeUrl,
    setResumeUrl,
    fileName,
    setFileName,
    fileType,
    setFileType,
    lastUpdated,
    setLastUpdated,
    metaData,
    setMetaData,
    fileRef,
  };
}
