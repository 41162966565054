import { useLocation, useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { auth } from "../firebase/config";
import { applyActionCode } from "firebase/auth";

const EmailVerify = () => {
  const location = useLocation();
  const [verificationStatus, setVerificationStatus] = useState("");
  const navigate = useNavigate();
  useEffect(() => {
    const verifyEmail = async () => {
      const params = new URLSearchParams(location.search);
      const oobCode = params.get("oobCode");

      try {
        // Verify the email address using the oobCode
        await applyActionCode(auth, oobCode);
        // Email verification successful
        setVerificationStatus("success");
      } catch (error) {
        // Email verification failed
        console.error("Error verifying email:", error);
        setVerificationStatus("error");
      }
    };

    verifyEmail();
  }, [location.search]);

  useEffect(() => {
    if (verificationStatus) {
      setTimeout(() => {
        navigate("/jobs", { state: { verified: true } });
      }, 2000);
    }
  }, [verificationStatus]);

  return (
    <div>
      {verificationStatus === "success" && (
        <div>
          <h1>Email Verification Successful!</h1>
          <p>Your email address has been successfully verified.</p>
        </div>
      )}
      {verificationStatus === "error" && (
        <div>
          <h1>Email Verification Failed</h1>
          <p>
            There was an error verifying your email address. Please try again.
          </p>
        </div>
      )}
      {verificationStatus !== "success" && verificationStatus !== "error" && (
        <div>
          <h1>Verifying Email...</h1>
          <p>Please wait while we verify your email address.</p>
        </div>
      )}
    </div>
  );
};

export default EmailVerify;
