import React from "react";

export default function Progress({ step }) {
  return (
    <div className="w-full max-w-lg mx-auto px-4 sm:px-0">
      <ul aria-label="Steps" className="flex items-center">
        {[1, 2, 3, 4].map((item, idx) => (
          <>
            <li
              aria-current={step == idx + 1 ? "step" : false}
              className="relative flex-1 last:flex-none flex items-center"
            >
              <div
                className={`w-8 h-8 rounded-full border-2 flex-none flex items-center justify-center ${
                  step > idx + 1
                    ? "bg-primary-400 border-primary-400"
                    : "" || step == idx + 1
                    ? "border-primary-400"
                    : ""
                }`}
              >
                <span
                  className={`flex justify-center items-center rounded-full ${
                    step === item ? "text-primary-400" : "text-[#e5e7eb]"
                  } ${step > item ? "hidden" : ""}`}
                >
                  {item}
                </span>
                {step > idx + 1 ? (
                  <svg
                    className="w-3.5 h-3.5 text-white lg:w-4 lg:h-4 "
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 16 12"
                  >
                    <path
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M1 5.917 5.724 10.5 15 1.5"
                    />
                  </svg>
                ) : (
                  ""
                )}
              </div>
              <hr
                className={`w-full border ${
                  idx + 1 == 4
                    ? "hidden"
                    : "" || step > idx + 1
                    ? "border-primary-400"
                    : ""
                }`}
              />
              <h1
                className={`absolute top-full ${
                  item === 1
                    ? "-left-3"
                    : item === 2
                    ? "-left-[12px]"
                    : item === 3
                    ? "-left-[8px]"
                    : "-left-[16px]"
                } w-max`}
                style={{ width: "max-content" }}
              >
                {item === 1
                  ? "Account"
                  : item === 2
                  ? "Resume"
                  : item === 3
                  ? "Review"
                  : "Get Hired"}
              </h1>
            </li>
          </>
        ))}
      </ul>
    </div>
  );
}
