import React, { useState } from "react";
import { useJsApiLoader } from "@react-google-maps/api";
import PlacesAutocomplete from "react-places-autocomplete";
import { IoLocationSharp } from "react-icons/io5";
import { CircularProgress } from "@material-ui/core";
export default function Location({ onChange, value, disabled }) {
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyB_ytAFSsnRMJW9ojdsVA6j4CRCiZWzFWE",
    libraries: ["places"],
  });

  const handleSelect = (address) => {
    onChange(address);
  };

  return !isLoaded ? (
    "Loading..."
  ) : (
    <PlacesAutocomplete
      value={value}
      onChange={(e) => onChange(e)}
      onSelect={handleSelect}
      searchOptions={{
        types: ["(cities)"],
      }}
    >
      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
        <div style={{ width: "100%" }}>
          <input
            {...getInputProps({
              placeholder: "Search location",
              className:
                "text-sm w-full bg-white border border-borderGrey-400 rounded-lg mt-2 text-base flex self-start box-border px-2 py-1.5 placeholder:text-[#b5b7be] hover:rounded-t-lg shadow-none focus:outline-none",
            })}
          />
          <div
            className="autocomplete-dropdown-container"
            style={{
              borderLeft: "1px solid #b3b5b6",
              borderRight: "1px solid #b3b5b6",
              borderBottom:
                suggestions?.length > 0 ? "1px solid #b3b5b6" : "none",
            }}
          >
            {loading && (
              <div className="flex justify-center">
                <CircularProgress
                  color="primary"
                  style={{ width: "16px", height: "16px", margin: "4px" }}
                />
              </div>
            )}
            {suggestions.map((suggestion, i) => {
              const className = suggestion.active
                ? "suggestion-item--active"
                : "suggestion-item";
              // inline style for demonstration purpose
              const style = suggestion.active
                ? { backgroundColor: "#fafafa", cursor: "pointer" }
                : { backgroundColor: "#ffffff", cursor: "pointer" };
              return (
                <div
                  {...getSuggestionItemProps(suggestion, {
                    style,
                  })}
                  key={i}
                  className="text-left py-2 px-1"
                >
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <IoLocationSharp className="w-3 h-3 text-primary-400" />
                    <span
                      style={{
                        marginLeft: "10px",
                        color: "#363B53",
                      }}
                    >
                      {suggestion.description}
                    </span>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </PlacesAutocomplete>
  );
}

export function MultiLocation({ onChange, values, disabled }) {
  console.log("🚀 ~ Location ~ values:", values);
  const [value, setValue] = useState("");

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyB_ytAFSsnRMJW9ojdsVA6j4CRCiZWzFWE",
    libraries: ["places"],
  });

  // const handleSelect = (address) => {
  //   onChange(address);
  // };

  const handleSelect = (address) => {
    if (!values) {
      onChange([address]);
      return;
    }
    if (!values?.some((loc) => loc === address)) {
      onChange([...values, address]);
    }
    setValue("");
  };

  const handleRemove = (locationToRemove) => {
    const result = values?.filter((loc) => loc !== locationToRemove);
    onChange(result);
  };

  const handleChange = (e) => {
    setValue(e);
  };
  return !isLoaded ? (
    "Loading..."
  ) : (
    <>
      <PlacesAutocomplete
        value={value}
        // onChange={(e) => onChange(e)}

        onChange={handleChange}
        onSelect={handleSelect}
        searchOptions={{
          types: ["(regions)"],
        }}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <div
            style={{ width: "100%", position: "relative" }}
            className={`${disabled && " opacity-60 bg-customGrey-100"}`}
          >
            <div className="flex-wrap items-center text-sm w-full min-h-9 bg-white border border-borderGrey-400 rounded-lg mt-2 flex self-start box-border px-2 py-1 placeholder:text-[#b5b7be] hover:rounded-t-lg shadow-none focus:outline-none ">
              {values?.map((location) => (
                <div
                  key={location}
                  className="mr-2 bg-[#EA672F1A] border border-secondary-400 rounded-md px-2 py-1 text-secondary-400 font-semibold"
                >
                  <span style={{ marginRight: "8px" }}>{location}</span>
                  <button
                    onClick={() => handleRemove(location)}
                    className="text-secondary-400 bg-transparent cursor-pointer border-none font-bold hover:bg-[rgb(234 103 47 / 38%)]"
                  >
                    ×
                  </button>
                </div>
              ))}
              <input
                {...getInputProps({
                  placeholder: "Search location",
                  disabled: disabled,
                  className:
                    "flex-1 border-none outline-none disabled:bg-transparent",
                })}
              />
            </div>

            <div
              className="autocomplete-dropdown-container absolute w-full"
              style={{
                borderLeft: "1px solid #b3b5b6",
                borderRight: "1px solid #b3b5b6",
                borderBottom:
                  suggestions?.length > 0 ? "1px solid #b3b5b6" : "none",
              }}
            >
              {loading && (
                <div className="flex justify-center">
                  <CircularProgress
                    color="primary"
                    style={{ width: "16px", height: "16px", margin: "4px" }}
                  />
                </div>
              )}
              {suggestions.map((suggestion, i) => {
                const className = suggestion.active
                  ? "suggestion-item--active"
                  : "suggestion-item";
                // inline style for demonstration purpose
                const style = suggestion.active
                  ? { backgroundColor: "#fafafa", cursor: "pointer" }
                  : { backgroundColor: "#ffffff", cursor: "pointer" };
                return (
                  <div
                    {...getSuggestionItemProps(suggestion, {
                      style,
                    })}
                    key={i}
                    className="text-left py-2 px-1"
                  >
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <IoLocationSharp className="w-3 h-3 text-primary-400" />
                      <span
                        style={{
                          marginLeft: "10px",
                          color: "#363B53",
                        }}
                      >
                        {suggestion.description}
                      </span>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </PlacesAutocomplete>
    </>
  );
}
