import axios from "axios";

export const findSkillInsight = async (skill, candidate) => {
  let resumeText = "About";
  resumeText = resumeText + "\n" + JSON.stringify(candidate?.aboutMe);
  resumeText = resumeText + "\n" + "Employment";
  if (candidate?.employment) {
    const employmentDetails = candidate.employment.map((job) => {
      return {
        duties: job.duties,
        chipSkills: job.chipSkills,
      };
    });

    resumeText += "\n" + JSON.stringify(employmentDetails);
  }
  resumeText = resumeText + "\n" + "Education";
  if (candidate?.education) {
    const educationDetails = candidate.education.map((job) => {
      return {
        duties: job.duties,
        chipSkills: job.chipSkills,
      };
    });

    resumeText += "\n" + JSON.stringify(educationDetails);
  }

  const apiKey = "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";
  const url = `https://api.openai.com/v1/chat/completions`;
  // const prompt = `Based on the following resume details, extract and highlight the key experiences and achievements related to the skill ${skill}. Provide skill insights in a bullet-point format.\n\nResume:\n${resumeText}\n\nSkill Insights:`;
  const prompt = `For the following resume details, we want insights into the experience of the person relating ONLY to the skill ${skill}. Extract the key details and provide skill insights in a bullet-point format if they exist, do not mention irrelevant insights.\n\nResume:\n${resumeText}\n\nSkill Insights:`;
  const messages = [
    {
      role: "user",
      content: prompt,
    },
  ];
  const data = {
    messages: messages,
    model: "gpt-3.5-turbo",
    temperature: 0.7,
    max_tokens: 200,
  };
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${apiKey}`,
  };

  try {
    const response = await axios.post(url, data, { headers });

    return response.data.choices[0].message.content.trim();
  } catch (error) {
    console.log("🚀 ~ parseDataUsingOpenAI ~ error:", error);
  }
};

export const findInsights = async (skill, rating, candidate) => {
  let resumeText = "About";
  resumeText = resumeText + "\n" + JSON.stringify(candidate?.aboutMe);
  resumeText = resumeText + "\n" + "Employment";
  if (candidate?.employment) {
    const employmentDetails = candidate.employment.map((job) => {
      return {
        duties: job.duties,
        chipSkills: job.chipSkills,
      };
    });

    resumeText += "\n" + JSON.stringify(employmentDetails);
  }
  resumeText = resumeText + "\n" + "Education";
  if (candidate?.education) {
    const educationDetails = candidate.education.map((job) => {
      return {
        duties: job.duties,
        chipSkills: job.chipSkills,
      };
    });

    resumeText += "\n" + JSON.stringify(educationDetails);
  }

  const apiKey = "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";
  const url = `https://api.openai.com/v1/chat/completions`;
  // const prompt = `Based on the following skill details, provide a one-liner insight on the candidate's proficiency in ${skill}.\n\Rating:\n${rating}\n\nOne-liner Insight:`;
  const prompt = `For the following resume details, provide a 1-2 liner insight into the person's proficiency in ${skill} taking into account their ${rating} and relevant details only regarding the skill: ${skill}: \n\n Resume Detail:\n ${resumeText}`;
  const messages = [
    {
      role: "user",
      content: prompt,
    },
  ];
  const data = {
    messages: messages,
    model: "gpt-3.5-turbo",
    temperature: 0.7,
    max_tokens: 200,
  };
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${apiKey}`,
  };

  try {
    const response = await axios.post(url, data, { headers });

    return response.data.choices[0].message.content.trim();
  } catch (error) {
    console.log("🚀 ~ parseDataUsingOpenAI ~ error:", error);
  }
};
