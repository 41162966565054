import { getAuth } from "firebase/auth";
import {
  collection,
  getDocs,
  getFirestore,
  limit,
  onSnapshot,
  orderBy,
  query,
  where,
} from "firebase/firestore";
import { COLLECTIONS, firestore } from "../firebase/config";
import { stripePriceIds } from "./constants";

export const getPremiumStatus = async (app) => {
  const auth = getAuth(app);
  const userId = auth.currentUser?.uid;
  if (!userId) throw new Error("User not logged in");

  const dbRefCompany = collection(firestore, COLLECTIONS.COMPANIES);
  const qC = query(
    dbRefCompany,
    orderBy("postedOn"),
    where("email", "==", auth.currentUser?.email),
    limit(1)
  );
  const reqC = await getDocs(qC);

  if (reqC.docs.length > 0) {
    const data = reqC.docs[0].data();
    if (data?.subscription?.status) {
      return "earlybird";
    } else {
      return null;
    }
  } else {
    return null;
  }

  // const subscriptionsRef = collection(
  //   firestore,
  //   "customers",
  //   userId,
  //   "subscriptions"
  // );
  // const q = query(
  //   subscriptionsRef,
  //   where("status", "not in", ["trialing", "active"])
  // );

  // return new Promise((resolve, reject) => {
  //   const unsubscribe = onSnapshot(
  //     q,
  //     (snapshot) => {
  //       // In this implementation we only expect one active or trialing subscription to exist.
  //       console.log("Subscription snapshot", snapshot.docs.length);
  //       const priceId = snapshot.docs[0]?.data()?.items[0]?.price?.id;
  //       if (snapshot.docs.length === 0) {
  //         console.log("No active or trialing subscriptions found");
  //         resolve(null);
  //       } else {
  //         console.log("Active or trialing subscription found");
  //         if (priceId === stripePriceIds[1]) {
  //           resolve("earlybird");
  //         } else if (priceId === stripePriceIds[2]) {
  //           resolve("lifetime");
  //         } else {
  //           resolve("regular");
  //         }
  //       }
  //       unsubscribe();
  //     },
  //     reject
  //   );
  // });
};
