import React from "react";
import { Avatar, Button, Grid, makeStyles } from "@material-ui/core";
import { CloudArrowUpIcon } from "@heroicons/react/16/solid";
import { MdOutlineFileUpload } from "react-icons/md";
import { ReactComponent as EditIcon } from "../../Assets/white-pencil.svg";

const useStyles = makeStyles((theme) => ({
  whiteButton: {
    backgroundColor: "transparent",
    textTransform: "unset",
    color: "#003D50",
    padding: "12px 24px",
    disableAnimation: true,
    fontSize: "16px",
    fontWeight: "700",
    width: "250px",
    marginTop: "16px",
    border: "1px solid #003D50",
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px", // Font size for mobile (screens smaller than or equal to small breakpoint)
      padding: "8px 16px",
    },
  },
}));
export default function ProfileImage({
  src,
  handleDefaultImageClick,
  handleImageInput,
  fileInput,
}) {
  const classes = useStyles();
  return (
    <>
      <Grid
        container
        item
        justifyContent="center" // Center items horizontally
        alignItems="flex-start" // Center items vertically
        direction="column" // Align items vertically
        spacing={2} // Add spacing between items
      >
        <Grid item>
          <div className="relative group rounded-full">
            <Avatar
              src={src}
              alt="Select profile"
              style={{
                width: "250px",
                height: "250px", // Set width to 100%
                border: "1px solid #003D50",
              }}
            />
            <div
              className="absolute z-50 bottom-6 right-6 p-1.5 bg-primary-400 rounded-full cursor-pointer"
              onClick={() => fileInput.current.click()}
            >
              {src ? (
                <EditIcon className="text-white" />
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5 text-white"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M12 4v16m8-8H4"
                  />
                </svg>
              )}
            </div>

            <div
              className="absolute rounded-full flex flex-col items-center h-full inset-0 justify-center w-full cursor-pointer bg-black bg-opacity-40 opacity-0 group-hover:opacity-100 transition-opacity"
              onClick={() => fileInput.current.click()}
            >
              <MdOutlineFileUpload className="text-white stroke w-6 h-6" />
              <div className="flex items-center gap-2">
                <h3 className="text-sm font-medium text-white">Upload</h3>
              </div>
            </div>
          </div>
        </Grid>
        {/* <Grid item>
          <Button
            className={classes.whiteButton}
            onClick={handleDefaultImageClick}
            startIcon={<CloudArrowUpIcon color="#003D50" />}
          >
            Upload Profile Image
          </Button>
        </Grid> */}
      </Grid>
      <input
        type="file"
        accept="image/*"
        onChange={(e) => handleImageInput(e.target.files[0])}
        ref={fileInput}
        style={{ display: "none" }}
      />
    </>
  );
}
