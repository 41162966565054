import ReactGA from "react-ga4";
const initializeGA = () => {
  ReactGA.initialize("G-5C0VZMT0NT");
};

const trackEvent = (category, action, label = "") => {
  ReactGA.event({
    category: category,
    action: action,
    label: label,
  });
};

export { initializeGA, trackEvent };
