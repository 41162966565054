import React from "react";
import { ReactComponent as ProgressSvg } from "../../Assets/progress.svg";
import { FaArrowRightLong } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
export default function Progress({ value, userInfo }) {
  const { t } = useTranslation();
  // const stepsLeft = {
  //   20: 4,
  //   40: 3,
  //   60: 2,
  //   80: 1,
  //   100: 0,
  // };
  const navigate = useNavigate();
  const totalSteps = 4;
  let completedSteps = 0;

  if (
    userInfo?.title &&
    userInfo?.firstName &&
    userInfo?.lastName &&
    userInfo?.dateOfBirth &&
    // userInfo?.country &&
    // userInfo?.city &&
    userInfo?.location &&
    userInfo?.timezone &&
    userInfo?.currency &&
    userInfo?.minSalaryExpectancy &&
    userInfo?.aboutMe &&
    userInfo?.resumeUrl
  ) {
    completedSteps++; // Increment completed steps if all conditions are met
  }
  // if (userInfo?.resumeUrl) {
  //   completedSteps++; // Increment completed steps if resumeUrl is present
  // }

  if (userInfo?.skills?.length > 0) {
    completedSteps++; // Increment completed steps if skills are present
  }

  if (userInfo?.employment?.length > 0) {
    completedSteps++; // Increment completed steps if employment history is present
  }

  if (userInfo?.education?.length > 0) {
    completedSteps++; // Increment completed steps if education history is present
  }

  // Calculate progress percentage
  const progress = (completedSteps / totalSteps) * 100;

  const stepsLeft = (100 - progress) / (100 / totalSteps);

  return (
    <>
      <div className="w-full rounded-lg mb-4 border border-gray-200">
        <div className="flex justify-between px-6 items-center font-semibold py-6">
          <div className="flex items-center gap-1">
            <ProgressSvg />
            <p>{t("complete_profile_message")}</p>
          </div>
          <div
            className={`flex items-center gap-4 ${
              stepsLeft <= 2 ? "text-[#2CBA99]" : "text-[#E22A34]"
            }`}
          >
            <p>
              {stepsLeft} {t("steps_left_message")}
            </p>
            <FaArrowRightLong
              className="cursor-pointer"
              onClick={() => navigate("/profile")}
            />
          </div>
        </div>
        <div
          className="bg-gray-400 h-1.5 rounded-b rounded-br rounded-bl relative"
          style={{ width: "100%" }}
        >
          <div
            className={`absolute top-0 left-0 ${
              stepsLeft <= 2 ? "bg-[#2CBA99]" : "bg-[#E22A34]"
            } h-1.5 rounded-b transition-all duration-500 ease-in-out`}
            style={{ width: `${progress}%` }}
          ></div>
        </div>
      </div>
    </>
  );
}
