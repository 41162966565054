import React, { useState, useEffect, useContext, useRef } from "react";
import {
  Grid,
  Button,
  Typography,
  makeStyles,
  CircularProgress,
} from "@material-ui/core";
import { CloudUpload as CloudUploadIcon } from "@material-ui/icons";
import { LuDownload } from "react-icons/lu";
import { COLLECTIONS, firestore, storage } from "../../firebase/config";
import {
  collection,
  query,
  orderBy,
  where,
  limit,
  getDocs,
  updateDoc,
} from "firebase/firestore";
import {
  uploadBytesResumable,
  ref,
  getDownloadURL,
  getMetadata,
} from "firebase/storage";
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import moment from "moment";
import PrimaryBtn, { IconButton, SecondaryBtn } from "../Global/Buttons";
import { UserContext } from "../../context/UserContext";
import { MdOutlineFileUpload } from "react-icons/md";
import { ReactComponent as FileIcon } from "../../Assets/file.svg";
import { useTranslation } from "react-i18next";
const useStyles = makeStyles((theme) => ({
  wrapper: {
    border: "2px dashed #D1D1D1",
    padding: "32px",
    height: "580px",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "8px",
  },

  whiteButton: {
    backgroundColor: "transparent",
    textTransform: "unset",
    color: "#003D50",
    padding: "12px 24px",
    disableAnimation: true,
    fontSize: "16px",
    fontWeight: "700",
    width: "250px",
    marginTop: "16px",
    border: "1px solid #003D50",
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px", // Font size for mobile (screens smaller than or equal to small breakpoint)
      padding: "8px 16px",
    },
  },
}));
const ResumeTab = (props) => {
  const classes = useStyles();
  const [resumeFile, setResumeFile] = useState(null);
  const [iframeHeight, setIframeHeight] = useState("600px");
  const [loading, setLoading] = useState(false);
  const [resumeUrl, setResumeUrl] = useState(
    props.userInfo.resumeUrl ? props.userInfo.resumeUrl : null
  );
  const [fileName, setFileName] = useState("");
  const [fileType, setFileType] = useState("");
  const [lastUpdated, setLastUpdated] = useState("");
  const [metaData, setMetaData] = useState(null);
  const { user } = useContext(UserContext);
  const fileRef = useRef(null);
  const { t } = useTranslation();

  const fetchFileDetails = async () => {
    try {
      // Fetch file metadata from Firestore
      const storageRef = ref(storage, resumeUrl);
      const metadataSnapshot = await getMetadata(storageRef);

      setMetaData(metadataSnapshot);
      setFileName(
        user?.displayName ? user?.displayName + "'s Resume" : "Resume"
      );
      setFileType(metadataSnapshot.contentType);
      setLastUpdated(metadataSnapshot.updated);
    } catch (error) {
      console.error("Error fetching file:", error);
    }
  };

  useEffect(() => {
    if (resumeUrl) {
      fetchFileDetails();
    }
  }, [resumeUrl, user]);

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    setResumeFile(file);
    console.log(file);
    setResumeUrl(URL.createObjectURL(file));
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      if (props.onboarding) {
        props.setParentFile(file);
      }
      setResumeFile(file);
      setFileName(file.name);
      setFileType(file.type);
      setLastUpdated(file.lastModified);
    }
  };

  useEffect(() => {
    const calculateIframeHeight = () => {
      const availableHeight = window.innerHeight - 280; // Adjust as needed
      setIframeHeight(`${availableHeight}px`);
    };

    calculateIframeHeight();
    window.addEventListener("resize", calculateIframeHeight);

    return () => {
      window.removeEventListener("resize", calculateIframeHeight);
    };
  }, []);

  const handleUpload = async () => {
    if (!resumeFile) return;
    setLoading(true);

    try {
      const dbRef = collection(firestore, COLLECTIONS.CANDIDATES);
      const q = query(
        dbRef,
        orderBy("postedOn"),
        where("email", "==", props.userInfo.email),
        limit(1)
      );
      const req = await getDocs(q);
      const userDocRef = req.docs[0].ref;

      const storageRef = ref(storage, `/files/resumes/${props.userInfo.uid}`);
      const uploadTask = uploadBytesResumable(storageRef, resumeFile);

      await uploadTask.on(
        "state_changed",
        (snapshot) => {},
        (err) => {},
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((url) => {
            setResumeUrl(url);
            updateDoc(userDocRef, {
              resumeUrl: url,
            })
              .then(() => {
                console.log("uploaded");
                setResumeFile(null);
              })
              .catch((err) => {});
          });
        }
      );
    } catch (err) {
      console.log("🚀 ~ handleUpload ~ err:", err);
    } finally {
      setLoading(false);
    }
  };
  const handleDownload = () => {
    const link = document.createElement("a");
    link.href = resumeUrl;
    link.target = "_blank";
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleRevert = () => {
    setResumeFile(null);
    if (metaData) {
      setFileName(user?.displayName + "'s Resume");
      setFileType(metaData.contentType);
      setLastUpdated(metaData.updated);
    } else {
      setFileName("");
      setFileType("");
      setLastUpdated("");
    }
  };

  // const docs = [{ uri: resumeUrl }];
  const type = fileType?.split("/")[1] === "pdf" ? "pdf" : "doc";
  return !props.onboarding ? (
    <div className="flex justify-between item">
      <div className="flex gap-1">
        <FileIcon />
        <div className="flex gap-1">
          <h2 className="text-sm font-bold">
            {fileName ? `${fileName}` : t("no_resume_uploaded")}
          </h2>
          {!resumeFile && (
            <div className="cursor-pointer" onClick={handleDownload}>
              <LuDownload />
            </div>
          )}
        </div>
      </div>
      <input
        type="file"
        ref={fileRef}
        accept=".pdf,.doc,.docx"
        onChange={handleFileChange}
        style={{ display: "none" }}
      />
      <IconButton
        text={fileName ? t("upload_new") : t("upload_resume")}
        icon={<MdOutlineFileUpload />}
        onClick={() => fileRef.current.click()}
      />
    </div>
  ) : (
    <Grid container style={{ marginTop: "32px" }}>
      {/* <DocViewer
        documents={docs}
        pluginRenderers={DocViewerRenderers}
        style={{ height: "1000px" }}
      /> */}

      {/* {resumeUrl && (
        <Grid container xs={5} justifyContent="flex-start">
          {resumeFile?.name?.endsWith(".pdf") ? (
            <embed src={resumeUrl} width="800" height="600" />
          ) : (
            <iframe
              src={resumeUrl ? resumeUrl : URL.createObjectURL(resumeFile)}
              title="Resume Preview"
              width="95%"
              height={iframeHeight}
            />
          )}
        </Grid>
      )} */}

      <Grid container xs={5} justifyContent="flex-start">
        {fileName && (
          <div className="w-full px-4">
            <div className="bg-white rounded-lg border border-borderGrey-400 h-16 flex items-center overflow-clip">
              <div
                className={`p-4 ${
                  type === "pdf"
                    ? "bg-[#cb112d] border border-[#cb112d]"
                    : "bg-blue-800 border border-blue-800"
                } text-white text-lg h-full`}
              >
                {type?.toUpperCase()}
              </div>
              <div className="p-4 flex-1">
                {fileName}
                <p className="text-xs text-gray-500">
                  {t("last_updated")}:{" "}
                  {moment(lastUpdated).format("DD/MM/YYYY")}
                </p>
              </div>
              {!resumeFile && (
                <div className="p-4 cursor-pointer" onClick={handleDownload}>
                  <LuDownload />
                </div>
              )}
            </div>
          </div>
        )}
      </Grid>

      <Grid
        container
        xs={7}
        className={classes.wrapper}
        justifyContent="flex-end"
        width={resumeFile ? "50%" : "100%"}
      >
        <input
          type="file"
          id="resume-upload"
          accept=".pdf,.doc,.docx"
          // onChange={handleFileUpload}
          onChange={handleFileChange}
          style={{ display: "none" }}
        />
        <label htmlFor="resume-upload">
          <Button
            variant="contained"
            component="span"
            className={classes.whiteButton}
          >
            <CloudUploadIcon
              fontSize="large"
              color="#003D50"
              style={{ marginRight: "20px" }}
            />
            {t("upload_resume")}
          </Button>
        </label>
      </Grid>
      {!props.onboarding && (
        <div className="mt-4 flex gap-4">
          <SecondaryBtn
            text={t("revert_changes")}
            disabled={!!!resumeFile}
            onClick={handleRevert}
          />
          <PrimaryBtn
            text={t("save_changes")}
            loading={loading}
            onClick={handleUpload}
            disabled={!!!resumeFile}
          />
        </div>
      )}
    </Grid>
  );
};

export default ResumeTab;
