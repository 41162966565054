import React, { useContext, useEffect, useState } from "react";
import { MdEmail } from "react-icons/md";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { sendEmailVerification } from "firebase/auth";
import { PiClockFill } from "react-icons/pi";
import { IoMdClose } from "react-icons/io";

import { RxCross1 } from "react-icons/rx";
import { FaCircleCheck } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import useCompanyStore from "../store/company";
import { UserContext } from "../context/UserContext";
import { useTranslation } from "react-i18next";

export default function EarlyBirdHeader() {
  const { company } = useCompanyStore();
  const { premiumPlan } = useContext(UserContext);
  const [isBannerVisible, setIsBannerVisible] = useState(false);
  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    const bannerState = localStorage.getItem("bannerClosed");
    if (!bannerState) {
      setIsBannerVisible(true);
    }
  }, []);

  const closeBanner = () => {
    setIsBannerVisible(false);
    localStorage.setItem("bannerClosed", "true");
  };

  if (!isBannerVisible) {
    return null;
  }

  return (
    <>
      <ToastContainer autoClose={3000} />
      {company &&
        // && company?.earlyBird
        !!!premiumPlan && (
          <div className="flex items-center gap-1 py-6 px-6 bg-secondary-400 text-white justify-center relative">
            <div className="flex items-center gap-1">
              <PiClockFill className="text-lg" />
              <p
                className="text-sm italic underline font-medium cursor-pointer"
                onClick={() => navigate("/earlybird")}
              >
                {t("early_bird_offer")}
              </p>
            </div>
            <div className="absolute right-4 ">
              <buttton
                className="bg-white rounded-md text-secondary-400 flex items-center justify-center w-9 h-9 cursor-pointer"
                onClick={closeBanner}
              >
                <IoMdClose />
              </buttton>
            </div>
          </div>
        )}
    </>
  );
}
