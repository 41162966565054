import {
  addDoc,
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  serverTimestamp,
  where,
} from "firebase/firestore";
import { COLLECTIONS, firestore } from "../firebase/config";
import { Link } from "react-router-dom";

export const getMultiJobsWithCompany = async (documentSnapshots) => {
  const companyCache = {};
  const tempJobs = await Promise.all(
    documentSnapshots.docs.map(async (job) => {
      let companyData = null;
      const companyRef = job.data().companyId;

      if (companyRef) {
        if (companyCache[companyRef]) {
          companyData = companyCache[companyRef];
        } else {
          const docRef = doc(firestore, COLLECTIONS.COMPANIES, companyRef);
          const docSnap = await getDoc(docRef);
          if (docSnap.exists()) {
            companyData = docSnap.data();
          }
        }
      }
      return {
        ...job.data(),
        id: job.id,
        company: companyData,
      };
    })
  );
  return tempJobs;
};

export const getSingleJobWithCompany = async (jobId) => {
  const jobRef = await doc(firestore, COLLECTIONS.JOBS, jobId);
  const jobSnap = await getDoc(jobRef);
  if (jobSnap.exists()) {
    let companyData = null;
    const companyRef = jobSnap.data().companyId;
    const docRef = doc(firestore, COLLECTIONS.COMPANIES, companyRef);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      companyData = docSnap.data();
    }
    return {
      ...jobSnap.data(),
      id: jobSnap.id,
      company: companyData,
    };
  }
};

export const checkLockedStatus = async (ids) => {
  const filteredIds = [];

  for (const id of ids) {
    const docRef = doc(firestore, COLLECTIONS.APPLICATIONS, id);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      const data = docSnap.data();
      if (data.isLocked !== false) {
        filteredIds.push(id);
      }
    } else {
      console.log(`Document with ID ${id} does not exist`);
    }
  }

  return filteredIds;
};

export const sendUnlockNotification = async (
  jobId,
  applicationId,
  candidateEmail,
  title
) => {
  try {
    // if title found
    const message = title
      ? `Your application for ${title} has been unlocked`
      : `Your application has been unlocked`;

    // Add the notification for the company
    await addDoc(collection(firestore, "notifications"), {
      candidateEmail,
      message,
      timestamp: serverTimestamp(),
      read: false,
      type: "unlockCandidate",
      application: applicationId,
      job: jobId,
    });
  } catch (error) {
    console.error("Error sending notification: ", error);
  }
};

export const fetchJobsInBatches = async (jobIds) => {
  const BATCH_SIZE = 10;
  const jobsCollectionRef = collection(firestore, COLLECTIONS.JOBS);
  let allJobs = [];

  // Split jobIds into chunks of 10
  for (let i = 0; i < jobIds.length; i += BATCH_SIZE) {
    const batchIds = jobIds.slice(i, i + BATCH_SIZE);
    const jobsSnapshot = await getDocs(
      query(jobsCollectionRef, where("__name__", "in", batchIds))
    );

    allJobs.push(jobsSnapshot);
  }

  return allJobs;
};

export const fetchSavedJobsInBatches = async (savedJobIds) => {
  const BATCH_SIZE = 10;
  const jobsCollectionRef = collection(firestore, COLLECTIONS.JOBS);
  let allJobs = [];

  // Split jobIds into chunks of 10
  for (let i = 0; i < savedJobIds.length; i += BATCH_SIZE) {
    const batchIds = savedJobIds.slice(i, i + BATCH_SIZE);
    const jobsSnapshot = await getDocs(
      query(
        jobsCollectionRef,
        where("__name__", "in", savedJobIds),
        where("is_active", "==", true)
      )
    );

    allJobs.push(jobsSnapshot);
  }

  return allJobs;
};

export function findMismatches(job, candidate) {
  const mismatches = [];
  if (!job || !candidate) return mismatches;
  // Check location mismatch
  if (
    !job.locations.includes(candidate.location) &&
    !job.locations.includes("Worldwide")
  ) {
    const msg = (
      <span>
        {job?.company ?? "This company"} is not currently hiring from your
        current location due to time zones and other constraints. You can still
        apply for the job but this information will be shown in your job
        application. If you think this is a mistake{" "}
        <Link to="/profile" className="text-secondary-400 text-medium">
          please update your profile
        </Link>
      </span>
    );

    mismatches.push(msg);
  }

  // Check timezone mismatch
  // if (job.timezone !== candidate.timezone) {
  //   mismatches.push(
  //     "Your timezone may not align with the job's required timezone."
  //   );
  // }

  // Check salary expectation mismatch
  // if (candidate.minSalaryExpectancy > job.maxSalary) {
  //   mismatches.push(
  //     "Your salary expectations exceed the maximum salary offered for this job."
  //   );
  // }

  // Check language mismatch
  const languageMismatch =
    job.language && !candidate?.languages?.includes(job.language);
  if (languageMismatch) {
    const msg = (
      <span>
        {job?.company ?? "This company"} is looking for candidates who can
        communicate effectively in ${job?.language}. You can still apply for the
        job but this information will be shown in your job application.` If you
        think this is a mistake{" "}
        <Link to="/profile" className="text-secondary-400 text-medium">
          please update your profile
        </Link>
      </span>
    );

    mismatches.push(msg);
    // mismatches.push("You may not meet the language requirements for this job.");
  }

  return mismatches;
}

export function findMismatchesCompany(job, candidate) {
  const mismatches = [];
  if (!job || !candidate) return mismatches;
  // Check location mismatch
  if (
    !job.locations.includes(candidate.location) &&
    !job.locations.includes("Worldwide")
  ) {
    mismatches.push(
      "The Candidate resides outside of the regions you’re hiring from for this job."
    );
  }

  // Check timezone mismatch
  // if (job.timezone !== candidate.timezone) {
  //   mismatches.push(
  //     "This candidate's timezone may not align with the job's required timezone."
  //   );
  // }

  // Check salary expectation mismatch
  // if (candidate.minSalaryExpectancy > job.maxSalary) {
  //   mismatches.push(
  //     "This candidate's expectations exceed the maximum salary offered for this job."
  //   );
  // }

  // Check language mismatch
  const languageMismatch =
    job.language && !candidate.languages?.includes(job.language);
  if (languageMismatch) {
    mismatches.push(
      "The Candidate doesn’t match the language requirement of this job."
    );
  }

  return mismatches;
}
