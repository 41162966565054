const skillList = [
  { name: "Accessibility", category: "Technical Skills" },
  { name: "Active listening", category: "Leadership" },
  { name: "Adaptability", category: "Leadership" },
  { name: "Adobe Creative Suite", category: "Technical Skills" },
  { name: "Affiliate Marketing", category: "Marketing" },
  { name: "Agile Methodologies", category: "Technical Skills" },
  { name: "Amazon Web Services (AWS)", category: "Technical Skills" },
  { name: "Android Development", category: "Technical Skills" },
  { name: "Angular", category: "Technical Skills" },
  { name: "Ant Design", category: "Technical Skills" },
  { name: "Apache", category: "Technical Skills" },
  { name: "Apollo Client", category: "Technical Skills" },
  { name: "Appium", category: "Technical Skills" },
  { name: "ASP.NET", category: "Technical Skills" },
  { name: "Async", category: "Technical Skills" },
  { name: "AWS CloudFormation", category: "Technical Skills" },
  { name: "Azure Resource Manager", category: "Technical Skills" },
  { name: "Bitbucket", category: "Technical Skills" },
  { name: "Bootstrap", category: "Technical Skills" },
  { name: "C++", category: "Technical Skills" },
  { name: "C#", category: "Technical Skills" },
  { name: "Caddy", category: "Technical Skills" },
  { name: "Chai", category: "Technical Skills" },
  { name: "CircleCI", category: "Technical Skills" },
  { name: "Communication", category: "Communication" },
  { name: "Collaboration", category: "Communication" },
  { name: "Conflict resolution", category: "Leadership" },
  { name: "Content Marketing", category: "Marketing" },
  { name: "Copywriting", category: "Marketing" },
  { name: "Coaching and mentoring", category: "Leadership" },
  { name: "Customer Experience Management", category: "Marketing" },
  { name: "Customer Relationship Management (CRM)", category: "Marketing" },
  { name: "Decision-making", category: "Leadership" },
  { name: "Digital Marketing", category: "Marketing" },
  { name: "Django", category: "Technical Skills" },
  { name: "Docker", category: "Technical Skills" },
  { name: "Emotion", category: "Technical Skills" },
  { name: "Email Marketing", category: "Marketing" },
  { name: "Event Planning and Management", category: "Marketing" },
  { name: "Express.js", category: "Technical Skills" },
  { name: "Figma", category: "Technical Skills" },
  { name: "Firebase", category: "Technical Skills" },
  { name: "Flask", category: "Technical Skills" },
  { name: "Git", category: "Technical Skills" },
  { name: "GitHub", category: "Technical Skills" },
  { name: "GitLab", category: "Technical Skills" },
  { name: "Gatsby.js", category: "Technical Skills" },
  { name: "Go", category: "Technical Skills" },
  { name: "Google Cloud Deployment Manager", category: "Technical Skills" },
  { name: "Google Cloud Platform", category: "Technical Skills" },
  { name: "Google Workspace (formerly G Suite)", category: "Technical Skills" },
  { name: "GraphQL", category: "Technical Skills" },
  { name: "HAProxy", category: "Technical Skills" },
  { name: "HTML", category: "Technical Skills" },
  { name: "Influencer Marketing", category: "Marketing" },
  { name: "InVision", category: "Technical Skills" },
  { name: "Interpersonal skills", category: "Communication" },
  { name: "iOS Development", category: "Technical Skills" },
  { name: "Java", category: "Technical Skills" },
  { name: "JavaScript", category: "Technical Skills" },
  { name: "Jenkins", category: "Technical Skills" },
  { name: "Jest", category: "Technical Skills" },
  { name: "Kafka", category: "Technical Skills" },
  { name: "Kanban", category: "Technical Skills" },
  { name: "Kotlin", category: "Technical Skills" },
  { name: "Laravel", category: "Technical Skills" },
  { name: "Leadership", category: "Leadership" },
  { name: "Lean Methodology", category: "Technical Skills" },
  { name: "LESS", category: "Technical Skills" },
  { name: "Magento", category: "Technical Skills" },
  { name: "Market Research", category: "Marketing" },
  { name: "Marketing Analytics", category: "Marketing" },
  { name: "Marketing Automation", category: "Marketing" },
  { name: "Marketing Strategy", category: "Marketing" },
  { name: "Material UI", category: "Technical Skills" },
  { name: "Microservices", category: "Technical Skills" },
  { name: "Microsoft Azure", category: "Technical Skills" },
  { name: "Microsoft Office Suite", category: "Technical Skills" },
  { name: "Microsoft SQL Server", category: "Technical Skills" },
  { name: "Microsoft Teams", category: "Technical Skills" },
  { name: "MobX", category: "Technical Skills" },
  { name: "MongoDB", category: "Technical Skills" },
  { name: "MySQL", category: "Technical Skills" },
  { name: "Next.js", category: "Technical Skills" },
  { name: "Node.js", category: "Technical Skills" },
  { name: "Nginx", category: "Technical Skills" },
  { name: "Objective-C", category: "Technical Skills" },
  { name: "Oracle", category: "Technical Skills" },
  { name: "Pay-Per-Click (PPC) Advertising", category: "Marketing" },
  { name: "Perl", category: "Technical Skills" },
  { name: "PHP", category: "Technical Skills" },
  { name: "PostgreSQL", category: "Technical Skills" },
  { name: "Progressive Web Apps (PWA)", category: "Technical Skills" },
  { name: "Python", category: "Technical Skills" },
  { name: "RabbitMQ", category: "Technical Skills" },
  { name: "React.js", category: "Technical Skills" },
  { name: "React Router", category: "Technical Skills" },
  { name: "Redis", category: "Technical Skills" },
  { name: "Redux", category: "Technical Skills" },
  { name: "Relationship building", category: "Leadership" },
  { name: "Resilience", category: "Leadership" },
  { name: "Responsive Design", category: "Technical Skills" },
  { name: "REST APIs", category: "Technical Skills" },
  { name: "Ruby", category: "Technical Skills" },
  { name: "Ruby on Rails", category: "Technical Skills" },
  { name: "Rust", category: "Technical Skills" },
  { name: "Sass", category: "Technical Skills" },
  { name: "SCSS", category: "Technical Skills" },
  { name: "Search Engine Optimization (SEO)", category: "Marketing" },
  { name: "Serverless Architecture", category: "Technical Skills" },
  { name: "Shopify", category: "Technical Skills" },
  { name: "Shell Scripting", category: "Technical Skills" },
  { name: "Slack", category: "Technical Skills" },
  { name: "Social Media Management", category: "Marketing" },
  { name: "Spring Boot", category: "Technical Skills" },
  { name: "Squarespace", category: "Technical Skills" },
  { name: "Styled Components", category: "Technical Skills" },
  { name: "Symfony", category: "Technical Skills" },
  { name: "Swift", category: "Technical Skills" },
  { name: "SwiftUI", category: "Technical Skills" },
  { name: "Terraform", category: "Technical Skills" },
  { name: "Trello", category: "Technical Skills" },
  { name: "Travis CI", category: "Technical Skills" },
  { name: "TypeScript", category: "Technical Skills" },
  { name: "Vue.js", category: "Technical Skills" },
  { name: "Vue Router", category: "Technical Skills" },
  { name: "Vuex", category: "Technical Skills" },
  { name: "WebSockets", category: "Technical Skills" },
  { name: "Webflow", category: "Technical Skills" },
  { name: "Weebly", category: "Technical Skills" },
  { name: "Wix", category: "Technical Skills" },
  { name: "WordPress", category: "Technical Skills" },
  { name: "Waterfall Methodology", category: "Technical Skills" },
  { name: "Zeplin", category: "Technical Skills" },
  { name: "Zoom", category: "Technical Skills" },
];

export default skillList;
